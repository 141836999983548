import React from 'react';
import { DateRangePicker } from 'rsuite';
import { useDispatch } from 'react-redux';
import { setDateRange } from 'app/redux/actions/CommonActions';
import moment from 'moment';

const styles = { width: 260, display: 'block', marginBottom: 10 };

const DateRange = () => {
  const [date, setDate] = React.useState([]);
  const dispatch = useDispatch();
  // const { dateRange } = useSelector((state) => state.common);

  React.useEffect(() => {
    // resetDate();
    setDate([
      new Date(moment().startOf('month').format('YYYY-MM-DD')),
      new Date()
    ]);
  }, []);

  const resetDate = () => {
    setDate([new Date(), new Date()]);

    // handleDateRange(date);
  };

  const handleDateRange = (daterange) => {
    // console.log('RR:', daterange);
    const date = {
      start: moment(daterange[0]).format('YYYY-MM-DD'),
      end: moment(daterange[1]).format('YYYY-MM-DD')
    };

    setDate([
      new Date(moment(daterange[0]).format('YYYY-MM-DD')),
      new Date(moment(daterange[1]).format('YYYY-MM-DD'))
    ]);

    dispatch(setDateRange(date));
  };

  return (
    <DateRangePicker
      size="lg"
      placeholder="Select Date"
      style={styles}
      onChange={handleDateRange}
      value={date}
      onClean={resetDate}
    />
  );
};

export default DateRange;
