import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import { getApi } from 'app/services/api';

export const GET_LIVE_ORDERS = 'GET_LIVE_ORDERS';
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const GET_ORDERS_DETAILS = 'GET_ORDERS_DETAILS';

// export const UPDATE_CART_AMOUNT = "UPDATE_CART_AMOUNT";

export const getLiveOrders = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/orders/live`)
    .then((res) => {
      // console.log('ORDERS ACTION:', res.data);
      dispatch({
        type: GET_LIVE_ORDERS,
        payload: res.data.data
      });
      return res.data.data;
    })
    .catch((err) => {
      // console.log('LIVE ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getOrders =
  (filter = '') =>
  async (dispatch, getState) => {
    // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
    //   'jwt_token'
    // );
    const api = getApi(getState);
    return await api
      .get(`${process.env.REACT_APP_API_URL}/api/orders?${filter}`)
      .then((res) => {
        // console.log('ORDERS ACTION:', res.data);
        dispatch({
          type: GET_ORDERS_LIST,
          payload: res.data
        });
      })
      .catch((err) => {
        // console.log('ERROR:', err.response);

        showErrorMessage(dispatch, err);
      });
  };

export const clearOrderDetails = () => async (dispatch) => {
  dispatch({
    type: GET_ORDERS_DETAILS,
    payload: {}
  });
};

export const clearLiveOrders = () => async (dispatch) => {
  dispatch({
    type: GET_LIVE_ORDERS,
    payload: []
  });
};

export const clearOrders = () => async (dispatch) => {
  dispatch({
    type: GET_ORDERS_LIST,
    payload: []
  });
};

export const getOrderDetails = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/orders/detail/` + id)
    .then((res) => {
      // console.log('ORDERS DETAILS:', res.data.order_detail);
      dispatch({
        type: GET_ORDERS_DETAILS,
        payload: res.data.order_detail
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const changeOrderStatus = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/orders/change-status`, data)
    .then((res) => {
      // console.log('ORDERS STATUS CHANGED:', res.data);
      dispatch(
        enqueueSnackbar({
          message: 'Order status updated',
          options: {
            variant: 'success'
          }
        })
      );
      if ([5, 9, 10].some((id) => id === data.status_id)) {
        dispatch({
          type: GET_ORDERS_DETAILS,
          payload: {}
        });
      }
      return res.data;
    })
    .catch((err) => {
      console.log('ERROR:', err, err.response);

      showErrorMessage(dispatch, err);
    });
};

export const cancelOrder = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  if (!data.reason_id) {
    return dispatch(
      enqueueSnackbar({
        message: 'You must select a reason for cancelling the order',
        options: {
          variant: 'error'
        }
      })
    );
  }
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/orders/cancel`, data)
    .then((res) => {
      // console.log('ORDERS CANCELLED:', res.data);
      dispatch(
        enqueueSnackbar({
          message: 'Order Cancelled',
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: GET_ORDERS_DETAILS,
        payload: {}
      });

      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};
