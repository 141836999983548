import React from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";

const placeHolder = "/assets/images/product-placeholder.png";

const TableCard = () => {
  const { dashboardData } = useSelector((state) => state.common);

  React.useEffect(() => {
    // console.log("DATA::", dashboardData, typeof dashboardData);
  }, [dashboardData]);

  if (!dashboardData.hasOwnProperty("top_selling")) {
    return <CircularProgress />;
  }

  return (
    <Card elevation={3} className="pt-5 mb-6 menu">
      <div className="card-title px-6 mb-3">top selling items</div>
      <div className="overflow-auto">
        <Table className="menu__table">
          <TableHead>
            <TableRow>
              <TableCell className="px-1">
                Name
              </TableCell>
              <TableCell className="px-0">
                Orders
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardData.top_selling.map((product, index) => (
              <TableRow key={index}>
                <TableCell className="px-0 capitalize" align="left">
                  <div className="flex items-center">
                    {product.image ? (
                      <img
                        className="img"
                        src={product.image.thumb}
                        alt="user"
                      />
                    ) : (
                      <img className="img" src={placeHolder} alt="user" />
                    )}
                    <p className="m-0 ml-8">{product.name}</p>
                  </div>
                </TableCell>
                <TableCell className="px-0 capitalize" align="left">
                  {product.total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};

export default TableCard;
