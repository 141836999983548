import React from "react";
import { Breadcrumb } from "matx";
import { Card, Box, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { getPaymentLinkDetails, paymentLinkSave } from "app/redux/actions/OutletActions";
import PaymentLinkForm from "./shared/PaymentLinkForm";

const PaymentLinkEdit = () => {
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(null);
  const { paymentLinkDetails } = useSelector((state) => state.paymentlink);
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    // console.log("DETAILS:", paymentlinkDetails);
    if (paymentLinkDetails && paymentLinkDetails.id) {
      setLoading(false);
    }
  }, [paymentLinkDetails]);

  React.useEffect(() => {
    dispatch(getPaymentLinkDetails(id));
  }, [dispatch, id]);

  const submitPaymentLink = async (data) => {
    const paymentlink = await dispatch(paymentLinkSave(data));
    // console.log("MM:", paymentlink);
    if (paymentlink.paymentlink && paymentlink.paymentlink.id) {
      setRedirect(`/payment-links/list`);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loading) return <CircularProgress />;

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: "PaymentLinks", path: "/paymentlinks/list" },
            { name: "Edit" },
          ]}
        />
      </div>
      <Box maxWidth={600}>
        <Card className="px-6 py-6">
          <PaymentLinkForm
            onSave={(data) => submitPaymentLink(data)}
            initialData={paymentLinkDetails}
          />
        </Card>
      </Box>
    </div>
  );
};
export default PaymentLinkEdit;
