import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Switch,
  Box,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { positionChange, statusChange } from 'app/redux/actions/CommonActions';
import SearchBar from 'app/components/SearchBar';
import DraggableList from 'react-draggable-list';
import { getCategoriesList } from 'app/redux/actions/MenuActions';
import { Link } from 'react-router-dom';

const CategoriesTable = ({ onEdit }) => {
  const { categoriesList } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [filteredCategories, setFilteredCategories] = React.useState([]);
  const [draggable, setDraggable] = React.useState(true);

  React.useEffect(() => {
    setFilteredCategories(categoriesList);
  }, [categoriesList]);

  const changeStatus = async (e) => {
    const data = {
      module: 'category',
      ref_id: e.target.value,
      value: e.target.checked ? 1 : 0
    };
    const changed = await dispatch(statusChange(data));

    if (changed) {
      dispatch(getCategoriesList());
    }
  };

  const changePosition = async (id, oldPosition, newPosition) => {
    const data = {
      module: 'category',
      ref_id: id,
      value: newPosition - oldPosition
    };
    const changed = await dispatch(positionChange(data));
    if (changed) {
      // console.log('UPDATED:', changed);
      setDraggable(true);
    }
  };

  const searchCategories = (text) => {
    // console.log('TXT', text)
    setDraggable(text.length === 0);
    setFilteredCategories(
      categoriesList.filter((el) => el.name.en && el.name.en.toLowerCase().includes(text))
    );
  };

  const CategoryItem = React.forwardRef(
    ({ item, itemSelected, dragHandleProps }, ref) => (
      <Box
        // key={index}
        className="elevation-z1 flex items-center justify-between py-2"
        // borderBottom={1}
        borderColor="grey.200"
      >
        <div className="flex items-center justify-start">
          {draggable ? (
            <IconButton {...dragHandleProps}>
              <Icon color="primary">drag_indicator</Icon>
            </IconButton>
          ) : (
            <IconButton>
              <Icon color="disabled">drag_indicator</Icon>
            </IconButton>
          )}
          <p className="m-0 ml-8">{item.name.en}</p>
        </div>
        <div className="px-0" align="right">
          <Button
            component={Link}
            to={`/menus/categories/items/${item.id}`}
            // variant="contained"
            // color="primary"
            endIcon={<Icon color="primary">chevron_right</Icon>}
          >
            Edit Menu Order
          </Button>
          <IconButton onClick={() => onEdit(item)}>
            <Icon color="primary">edit</Icon>
          </IconButton>
          <Switch
            value={item.id}
            checked={item.publish}
            onChange={changeStatus}
            color="primary"
            size="small"
            inputProps={{ 'aria-label': 'Change Status' }}
          />
        </div>
      </Box>
    )
  );

  const onListChange = (newList, movedItem, oldIndex, newIndex) => {
    setDraggable(false);
    setFilteredCategories(newList);
    changePosition(movedItem.id, oldIndex, newIndex);
    // console.log('MOVED:', newList, movedItem, oldIndex, newIndex);
  };

  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="flex items-center justify-between py-3">
        <div className="card-title mb-3">Menu Categories</div>
        <SearchBar onSearch={searchCategories} />
      </div>

      <DraggableList
        itemKey="id"
        template={CategoryItem}
        list={filteredCategories}
        onMoveEnd={(newList, movedItem, oldIndex, newIndex) =>
          onListChange(newList, movedItem, oldIndex, newIndex)
        }
        // onMoveEnd={(newList) => onListChange(newList)}
        // {/* container={()=>useContainer ? this._container.current! : document.body} */}
      />
    </Card>
  );
};

export default CategoriesTable;
