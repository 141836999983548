import React from 'react';
import { Card, Icon, IconButton, Box, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { positionChange } from 'app/redux/actions/CommonActions';
import {
  unLinkCategoryMenu,
  getCategoriesItems
} from 'app/redux/actions/MenuActions';
import SearchBar from 'app/components/SearchBar';
import DraggableList from 'react-draggable-list';
import { useParams } from 'react-router-dom';

const CategoriesItemsTable = ({ onEdit }) => {
  const { categoriesItemsList } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [filteredCategories, setFilteredCategories] = React.useState([]);
  const [draggable, setDraggable] = React.useState(true);

  const { id: categoryId } = useParams();

  React.useEffect(() => {
    setFilteredCategories(categoriesItemsList);
  }, [categoriesItemsList]);

  const changePosition = async (id, oldPosition, newPosition) => {
    const data = {
      module: 'menu',
      ref_id: id,
      value: newPosition - oldPosition
    };
    const changed = await dispatch(positionChange(data));
    if (changed) {
      // console.log('UPDATED:', changed);
      setDraggable(true);
    }
  };

  const searchCategories = (text) => {
    // console.log('TXT', text)
    setDraggable(text.length == 0);
    setFilteredCategories(
      categoriesItemsList.filter((el) =>
      el.name.en && el.name.en.toLowerCase().includes(text)
      )
    );
  };

  const unLinkMenu = async (id) => {
    const assigned = await dispatch(
      unLinkCategoryMenu({ category_id: categoryId, menu_id: id })
    );

    if (assigned) dispatch(getCategoriesItems(id));
  };

  const CategoryItem = React.forwardRef(
    ({ item, itemSelected, dragHandleProps }, ref) => (
      <Box
        // key={index}
        className="elevation-z1 flex items-center justify-between py-2"
        // borderBottom={1}
        borderColor="grey.200"
      >
        {/* <div
        className="dragHandle"
        {...dragHandleProps}
        style={{ width: 50, height: 50, backgroundColor: 'red' }}
      /> */}
        <div className="flex items-center justify-start">
          {draggable ? (
            <IconButton {...dragHandleProps}>
              <Icon color="primary">drag_indicator</Icon>
            </IconButton>
          ) : (
            <IconButton>
              <Icon color="disabled">drag_indicator</Icon>
            </IconButton>
          )}
          <p className="m-0 ml-8">
            {item.name.en} ({item.price})
          </p>
        </div>
        <div className="px-0" align="right">
          <Button
            // variant="contained"
            // color="primary"
            onClick={() => unLinkMenu(item.id)}
            endIcon={<Icon color="primary">link_off</Icon>}
          >
            Un-assign Menu
          </Button>
        </div>
      </Box>
    )
  );

  const onListChange = (newList, movedItem, oldIndex, newIndex) => {
    setDraggable(false);
    setFilteredCategories(newList);
    changePosition(movedItem.cat_rel_id, oldIndex, newIndex);
    // console.log('MOVED:', newList, movedItem, oldIndex, newIndex);
  };

  return (
    <Card className="px-6 pt-2 pb-4" style={{ zIndex: 0 }}>
      <div className="flex items-center justify-between py-3">
        <div className="card-title mb-3">Menu Items</div>
        <SearchBar onSearch={searchCategories} />
      </div>

      <DraggableList
        itemKey="cat_rel_id"
        template={CategoryItem}
        list={filteredCategories}
        onMoveEnd={(newList, movedItem, oldIndex, newIndex) =>
          onListChange(newList, movedItem, oldIndex, newIndex)
        }
        // onMoveEnd={(newList) => onListChange(newList)}
        // {/* container={()=>useContainer ? this._container.current! : document.body} */}
      />
    </Card>
  );
};

export default CategoriesItemsTable;
