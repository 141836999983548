import React from 'react';
import ZoneAdd from './ZoneAdd';
import ZoneEdit from './ZoneEdit';
import Zones from './Zones';

const zoneRoutes = [
  {
    path: '/zones/list',
    component: Zones
  },
  {
    path: '/zones/add',
    // component: React.lazy(() => import('./ZoneAdd'))
    component: ZoneAdd
  },
  {
    path: '/zones/edit/:id',
    // component: React.lazy(() => import('./ZoneEdit'))
    component: ZoneEdit
  }
];

export default zoneRoutes;
