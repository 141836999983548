import React, { Fragment } from 'react';
import { CircularProgress } from '@material-ui/core';

import moment from 'moment';
import { useSelector } from 'react-redux';

const PrintOrder = ({ order }) => {
  const { user } = useSelector((state) => state.auth);
  React.useEffect(() => {
    // if (order.id) window.print();
  }, [order]);

  if (!order.id) return <CircularProgress />;

  return (
    <div
      id="print-area"
      style={{ fontFamily: 'Arial, sans-serif', fontSize: 13 }}
    >
      <table className="w-full" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td className="text-center" style={{ textAlign: 'center' }}>
              <h2
                className="text-center"
                style={{ textAlign: 'center', margin: 10, padding: 0 }}
              >
                {user.restaurant.name.en}
                {/* {' '}
                {
                  JSON.parse(localStorage.getItem('auth_user')).restaurant
                    .name_en
                }{' '} */}
              </h2>
              {/* <h3 className="text-center"> {JSON.parse(localStorage.getItem('auth_user')).restaurant.name_en} </h3> */}
              <h4
                className="text-center"
                style={{ textAlign: 'center', margin: 10, padding: 0 }}
              >
                {' '}
                {order.outlet_name}{' '}
              </h4>
              <h1
                className="text-center"
                style={{ textAlign: 'center', margin: 10, padding: 0 }}
              >
                {' '}
                #{order.order_no}{' '}
              </h1>
              <h5
                className="text-center"
                style={{ textAlign: 'center', margin: 10, padding: 0 }}
              >
                Ordered at :{' '}
                {moment(order.ordered_at).format('DD/MM/YY [at] hh:mm A')}{' '}
              </h5>

              <h2
                className="text-center border"
                style={{ textAlign: 'center', margin: 10, padding: 10 }}
              >
                Delivery on : <br />
                {order.delivery_at
                  ? moment(order.delivery_at).format('DD/MM/YY [at] hh:mm A')
                  : 'ASAP'}
              </h2>
              <h5
                className="text-center"
                style={{ textAlign: 'center', margin: 10, padding: 0 }}
              >
                {' '}
                {order.payment_method}{' '}
              </h5>
              {/* <h5 className="text-center"> {order.ordering_service} </h5> */}
              <hr />
            </td>
          </tr>
          <tr>
            <td className="mb-3">
              <strong>Name: </strong>
              <span>{order.customer.name}</span>
            </td>
          </tr>
          <tr>
            <td className="mb-3">
              <strong>Phone number: </strong>
              <span> {order.customer.mobile} </span>
            </td>
          </tr>
          <tr className="mb-3">
            <td className="mb-3">
              {order.ordering_service.toLowerCase() === 'pickup' ? (
                <>
                  <strong>Pickup from: </strong>
                  <span>{order.address.name.en}</span>
                </>
              ) : (
                <>
                  <strong>Delivering to: </strong>
                  <span>
                    {order.address.address_type}, {order.address.address},{' '}
                    {order.address.area}, {order.address.emirate}
                  </span>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      {order.note ? (
        <div
          className="pt-3 pb-4"
          style={{ paddingTop: 20, paddingBottom: 30 }}
        >
          <div
            className="py-2 border-radius-4 bg-secondary text-white"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <p>
              <strong>Notes</strong>
            </p>
            <p>{order.note}</p>
          </div>
          <hr />
        </div>
      ) : null}
      <table className="pt-3 pb-4 w-full" style={{ width: '100%' }}>
        <tbody>
          {order.items.length
            ? order.items.map((item, index) => (
                <Fragment key={`${item.id}`}>
                  <tr>
                    <td style={{ paddingTop: 20 }}>
                      {item.quantity}x {item.menu_name}
                    </td>
                    <td
                      className="col-3 text-right"
                      style={{ textAlign: 'right', paddingTop: 20 }}
                    >
                      {item.total}
                    </td>
                  </tr>
                  {item.attributes.length
                    ? item.attributes.map((attribute, index) => (
                        <tr
                          key={`${attribute.id}`}
                          className="mb-3"
                          style={{ marginBottom: 20, fontSize: 12 }}
                        >
                          <td className="text-left"> {attribute.attribute}</td>
                          <td className="col pl-3"></td>
                        </tr>
                      ))
                    : null}

                    {item.note ?
                          <tr
                          >
                            <td colSpan={2} className="col pl-0">
                              Note: {item.note}
                            </td>
                          </tr>
                      : null}
                      {/* <hr /> */}
                </Fragment>
              ))
            : null}
        </tbody>
      </table>
      <hr />
      <table
        className="mb-10 pb-10 w-full"
        style={{ width: '100%', marginBottom: 50 }}
      >
        <tbody>
          {order.discount && order.discount.has_discount ? (
            <>
              <tr>
                <td className="">Original Subtotal:</td>
                <td className="text-right" style={{ textAlign: 'right' }}>
                  <span>{order.actual_sub_total}</span>
                </td>
              </tr>
              <tr>
                <td className="">Discount ({order.discount.code}):</td>
                <td className="text-right" style={{ textAlign: 'right' }}>
                  <span>-{order.discount.amount || 0}</span>
                </td>
              </tr>
            </>
          ) : null}
          <tr>
            <td className="">Subtotal:</td>
            <td className="text-right" style={{ textAlign: 'right' }}>
              <span>
                {Number(order.tax) && order.tax_type == 'Excl'
                  ? order.total_without_tax
                  : order.total_with_tax}
              </span>
            </td>
          </tr>
          {Number(order.delivery_fee) ? (
            <tr>
              <td className="">Delivery Fee:</td>
              <td className="text-right" style={{ textAlign: 'right' }}>
                <span> {order.delivery_fee || 0}</span>
              </td>
            </tr>
          ) : null}
          {Number(order.tax) && order.tax_type == 'Excl' ? (
            <tr>
              <td className="">VAT:</td>
              <td className="text-right" style={{ textAlign: 'right' }}>
                <span> {order.tax}</span>
              </td>
            </tr>
          ) : null}
          {Number(order.redeemed_amount)> 0 ? (
            <tr>
              <td className="">Loyalty Saving:</td>
              <td className="text-right" style={{ textAlign: 'right' }}>
                <span> {order.redeemed_amount}</span>
              </td>
            </tr>
          ) : null}

          <tr style={{ fontSize: 16 }}>
            <td className="">
              <strong>Total:</strong>
            </td>
            <td className="text-right" style={{ textAlign: 'right' }}>
              <strong>
                <span>{order.amount_to_pay}</span>
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
      <hr style={{ marginTop: 80 }}></hr>
    </div>
  );
};

export default PrintOrder;
