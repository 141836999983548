import React from 'react';
import { Breadcrumb } from 'matx';
import { Card } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
// import { Redirect } from 'react-router-dom';

import OutletForm from './shared/OutletForm';

const OutletAdd = () => {
  // const [redirect, setRedirect] = React.useState(null);
  // const dispatch = useDispatch();
  // const submitOutlet = async (data) => {
  //   const outlet = await dispatch(outletSave(data));
  //   console.log("MM:", outlet);
  //   if (outlet && outlet.outlet && outlet.outlet.id) {
  //     setRedirect(`/outlets/edit/${outlet.outlet.id}`);
  //   }
  // };

  // if (redirect) {
  //   return <Redirect to={redirect} />;
  // }

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Outlets', path: '/outlets/list' },
            { name: 'Add' }
          ]}
        />
      </div>
      <Card className="px-6 py-6">
        <OutletForm
        // onSave={(data) => submitOutlet(data)}
        />
      </Card>
    </div>
  );
};
export default OutletAdd;
