/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Grid,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
  Icon
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import PropTypes from "prop-types";
import moment from 'moment';

import {
  updateOutletHours,
  getLocationsList
} from 'app/redux/actions/OutletActions';
import IOSSwitch from 'app/components/IOSSwitch';
const OutletFormHours = ({
  initialData,
  deliveryHours,
  service = 'delivery'
}) => {
  const [hours, setHours] = React.useState([]);
  const [deliveryHoursLocal, setDeliveryHoursLocal] = React.useState([]);
  const dispatch = useDispatch();
  const itemsRef = React.useRef([]);

  React.useEffect(() => {
    dispatch(getLocationsList());
    let items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format('HH:mm'));
      items.push(moment({ hour: index, minute: 30 }).format('HH:mm'));
    });
    items.push('23:59');
    setHours(items);
  }, []);

  React.useEffect(() => {
    // console.log('HOURS:', deliveryHours);
    if (deliveryHours.length) {
      itemsRef.current = itemsRef.current.slice(0, deliveryHours.length);
    }
    setDeliveryHoursLocal(deliveryHours);
    return () => {};
  }, [deliveryHours]);

  const updateHours = (i, e, index = 0) => {
    const data = { outlet_id: initialData.id, service: service };
    // console.log(
    //   'Switch',
    //   itemsRef.current[i].querySelectorAll('input[type=checkbox]')[0].checked
    // );

    data.day = itemsRef.current[i].querySelectorAll(
      'input[type=checkbox]'
    )[0].value;

    data.action = itemsRef.current[i].querySelectorAll(
      'input[type=checkbox]'
    )[0].checked
      ? 'add'
      : 'delete';

    data.hours = [];

    // console.log('EL:', i, itemsRef.current[i].getElementsByTagName('input'));
    Array.from(itemsRef.current[i].getElementsByClassName('hours-block')).map(
      (el, j) => {
        const hours = {};
        hours.start = el.querySelectorAll('input[name=start]')[0].value;
        hours.end = el.querySelectorAll('input[name=end]')[0].value;

        if (j === index && e && e.target && e.target.name === 'start') {
          hours.start = e.target.value;
        }
        if (j === index && e && e.target && e.target.name === 'end') {
          hours.end = e.target.value;
        }

        data.hours.push(hours);
        return true;
      }
    );

    // console.log('DATA::', data);
    dispatch(updateOutletHours(data));
  };

  const addHours = (el) => {
    const deliveryHoursTemp = deliveryHoursLocal.map((del) => {
      if (el.day === del.day) {
        del.time.push({
          end: '00:00',
          start: '00:00'
        });
      }
      return del;
    });

    // console.log(deliveryHoursTemp, el);
    setDeliveryHoursLocal(deliveryHoursTemp);

    // setDe
  };

  const clearHours = (el, i, j) => {
    const deliveryHoursTemp = deliveryHoursLocal.map((del) => {
      if (el.day === del.day) {
        del.time = del.time
          .slice(0, i)
          .concat(del.time.slice(i + 1, del.time.length));
      }
      return del;
    });

    // console.log(deliveryHoursTemp, el);
    setDeliveryHoursLocal(deliveryHoursTemp);
    updateHours(j);
    // setDe
  };

  return (
    <div className="my-6" id="delivery-hours">
      <h5 component="legend" className="capitalize">
        {service} Hours
      </h5>
      {deliveryHoursLocal &&
        deliveryHoursLocal.length &&
        deliveryHoursLocal.map((hour, i) => (
          <Grid
            container
            key={`${hour.day}-${hour.start}`}
            className="mb-4"
            ref={(el) => (itemsRef.current[i] = el)}
          >
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                className="mb-4"
                // labelPlacement="start"
                control={
                  <IOSSwitch
                    value={hour.day}
                    defaultChecked={hour.time ? true : false}
                    onChange={(e) => updateHours(i)}
                    name="status"
                  />
                }
                label={`${hour.day}`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {hours.length &&
                (hour.time && hour.time.length ? (
                  hour.time.map((time, index) => (
                    <div
                      key={`${index}`}
                      className="mb-2 hours-block flex items-center"
                    >
                      <FormControl
                        variant="outlined"
                        className={`mr-3`}
                        style={{ minWidth: 100 }}
                      >
                        <InputLabel
                          id={`delivery-hours-label-${hour.day}-${index}-start`}
                        >
                          Start
                        </InputLabel>
                        <Select
                          labelId={`delivery-hours-label-${hour.day}-${index}-start`}
                          id={`delivery-hours-${hour.day}-${index}-start`}
                          defaultValue={time.start}
                          onChange={(e) => updateHours(i, e, index)}
                          label="Start"
                          size="small"
                          name="start"
                        >
                          {hours.length ? (
                            hours.map((h) => (
                              <MenuItem key={`${h}`} value={`${h}`}>
                                {`${h}`}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ minWidth: 100 }}>
                        <InputLabel
                          id={`delivery-hours-label-${hour.day}-${index}-end`}
                        >
                          End
                        </InputLabel>
                        <Select
                          labelId={`delivery-hours-label-${hour.day}-${index}-end`}
                          id={`delivery-hours-${hour.day}-${index}-end`}
                          defaultValue={time.end}
                          onChange={(e) => updateHours(i, e, index)}
                          label="End"
                          size="small"
                          name="end"
                        >
                          {hours.length ? (
                            hours.map((h) => (
                              <MenuItem key={`${h}`} value={`${h}`}>
                                {`${h}`}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" style={{ minWidth: 100 }}>
                        {index === 0 ? (
                          <Button
                            aria-label="Add"
                            className="text-small"
                            size="small"
                            onClick={() => addHours(hour)}
                          >
                            <Icon className="mr-1" size="small">
                              add_circle
                            </Icon>
                            <span className="">Add Hours</span>
                          </Button>
                        ) : (
                          <Button
                            aria-label="Add"
                            className="text-small text-left justify-start d-none none hidden"
                            size="small"
                            onClick={() => clearHours(hour, index, i)}
                          >
                            <Icon className="mr-1" size="small">
                              cancel
                            </Icon>
                            Clear
                          </Button>
                        )}
                      </FormControl>
                    </div>
                  ))
                ) : (
                  <div className="mb-2 hours-block">
                    <FormControl
                      variant="outlined"
                      className={`mr-3`}
                      style={{ minWidth: 100 }}
                    >
                      <InputLabel
                        id={`delivery-hours-label-${hour.day}-0-start`}
                      >
                        Start
                      </InputLabel>
                      <Select
                        labelId={`delivery-hours-label-${hour.day}-0-start`}
                        id={`delivery-hours-${hour.day}-0-start`}
                        defaultValue="00:00"
                        onChange={(e) => updateHours(i, e)}
                        label="Start"
                        size="small"
                        name="start"
                      >
                        {hours.length ? (
                          hours.map((h) => (
                            <MenuItem key={`${h}`} value={`${h}`}>
                              {`${h}`}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ minWidth: 100 }}>
                      <InputLabel id={`delivery-hours-label-${hour.day}-0-end`}>
                        End
                      </InputLabel>
                      <Select
                        labelId={`delivery-hours-label-${hour.day}-0-end`}
                        id={`delivery-hours-${hour.day}-0-end`}
                        defaultValue="23:59"
                        onChange={(e) => updateHours(i, e)}
                        label="End"
                        size="small"
                        name="end"
                      >
                        {hours.length ? (
                          hours.map((h) => (
                            <MenuItem key={`${h}`} value={`${h}`}>
                              {`${h}`}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                ))}
            </Grid>
          </Grid>
        ))}
    </div>
  );
};
export default OutletFormHours;
