import React from 'react';
import {
  Grid,
  Card,
  Icon,
  IconButton,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import numbro from 'numbro';
import { Link as RouterLink } from 'react-router-dom';

const styles = (theme) => ({
  icon: {
    fontSize: '44px',
    opacity: 0.6,
    color: theme.palette.primary.main
  }
});

const StatCards = ({ classes }) => {
  const { dashboardData } = useSelector((state) => state.common);

  React.useEffect(() => {
    // console.log('DATA::', dashboardData, typeof dashboardData);
  }, [dashboardData]);

  if (!dashboardData.hasOwnProperty('total_orders')) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={3} className="mb-3">
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>assignment</Icon>
            <div className="ml-3">
              <small className="text-muted">Total Orders</small>
              <h2 className="m-0 mt-1 text-primary font-medium">
                {numbro(dashboardData.total_orders).format({
                  thousandSeparated: true
                })}
              </h2>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton component={RouterLink} to="/orders/list">
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>payment</Icon>
            <div className="ml-3">
              <small className="text-muted">Total Revenue</small>
              <h2 className="m-0 mt-1 text-primary font-medium">
                {numbro(dashboardData.revenues.gross_revenue).format({
                  thousandSeparated: true,
                  mantissa: 2
                })}
              </h2>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton>
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>group</Icon>
            <div className="ml-3">
              <small className="text-muted">Total Customers</small>
              <h2 className="m-0 mt-1 text-primary font-medium">
                {numbro(dashboardData.customers.total).format({
                  thousandSeparated: true
                })}
              </h2>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton>
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>shopping_cart</Icon>
            <div className="ml-3">
              <small className="text-muted">Avg. Basket Value</small>
              <h2 className="m-0 mt-1 text-primary font-medium">
                {Number(dashboardData.total_orders) > 0
                  ? numbro(
                      Number(dashboardData.revenues.gross_revenue) /
                        Number(dashboardData.total_orders)
                    ).format({
                      thousandSeparated: true,
                      mantissa: 0
                    })
                  : 0}
              </h2>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton>
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>menu_book</Icon>
            <div className="ml-3">
              <small className="text-muted">Tax Collected</small>
              <h2 className="m-0 mt-1 text-primary font-medium">
                {numbro(dashboardData.revenues.tax).format({
                  thousandSeparated: true,
                  mantissa: 2
                })}
              </h2>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton>
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>delivery_dining</Icon>
            <div className="ml-3">
              <small className="text-muted">Delivery Fee</small>
              <h2 className="m-0 mt-1 text-primary font-medium">
                {numbro(dashboardData.revenues.delivery_fee).format({
                  thousandSeparated: true,
                  mantissa: 2
                })}
              </h2>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton>
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(StatCards);
