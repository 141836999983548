import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  // headers: {
  //   "Content-type": "application/json"
  // }
});

export const setHeader = (API, getState) => {
  if (getState().auth && getState().auth.token)
  API.defaults.headers.common['Authorization'] = getState().auth.token;

  // console.log('API TOKENNNNN', getState().auth)
  
  if (getState().auth.activeRestaurant && getState().auth.activeRestaurant.id) {
    // console.log('ACTIVE YES', getState().auth.activeRestaurant)
    API.defaults.headers.common['restaurant-id'] = getState().auth.activeRestaurant.id;
  }
  return API;
}

export const getApi = (getState, form) => {
  if (getState().auth && getState().auth.token)
  api.defaults.headers.common['Authorization'] = getState().auth.token;

  // console.log('API TOKENNNNN', getState().auth)
  
  if (getState().auth.activeRestaurant && getState().auth.activeRestaurant.id) {
    // console.log('ACTIVE YES', getState().auth.activeRestaurant)
    api.defaults.headers.common['restaurant-id'] = getState().auth.activeRestaurant.id;
  }
  
  if (form) {
    // console.log('IISS FFOORRMM')
    // api.defaults.headers.common['content-type'] = 'multipart/form-data';
    api.defaults.headers.common['contentType'] = 'multipart/form-data';
  }
  else {
    // api.defaults.headers.common['content-type'] = 'application/json';
    api.defaults.headers.common['contentType'] = 'application/json';
  }
  return api;
}

export default api;