import React from 'react';
import { Redirect } from 'react-router-dom';

import dashboardRoutes from './views/dashboard/DashboardRoutes';
import sessionRoutes from './views/sessions/SessionRoutes';

import ordersRoutes from './views/orders/OrdersRoutes';
import menuRoutes from './views/menus/MenuRoutes';
import outletRoutes from './views/outlets/OutletRoutes';
import userRoutes from './views/users/UserRoutes';
import customerRoutes from './views/customers/CustomerRoutes';
import zoneRoutes from './views/zones/ZoneRoutes';
import marketingRoutes from './views/marketing/MarketingRoutes';
import discountRoutes from './views/discount/DiscountRoutes';
import loyaltyRoutes from './views/loyalty/LoyaltyRoutes';
import paymentLinkRoutes from './views/payment-links/PaymentLinkRoutes';

const redirectRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...ordersRoutes,
  ...menuRoutes,
  ...outletRoutes,
  ...userRoutes,
  ...customerRoutes,
  ...zoneRoutes,
  ...marketingRoutes,
  ...discountRoutes,
  ...loyaltyRoutes,
  ...paymentLinkRoutes,
  ...redirectRoute,
  ...errorRoute
];

export default routes;
