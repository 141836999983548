import React from "react";

const statuses = {
  placed: "New",
  accepted: "In Kitchen",
  ready: "Ready",
  completed: "Completed",
  cancelled: "Cancelled",
  'in-route': "Out For Delivery",
};

const OrderStatus = ({ status }) => {
  return <span>{statuses[status] || "New"}</span>;
};

export default OrderStatus;
