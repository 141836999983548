import React from "react";
import { authRoles } from "../../auth/authRoles";
import Dashboard from "./Dashboard";

const dashboardRoutes = [
  {
    path: "/dashboard",
    // component: React.lazy(() => import("./Dashboard")),
    component: Dashboard,
    auth: authRoles.user,
  },
];

export default dashboardRoutes;
