/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { Icon, Button, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  getLoyaltyEarned,
  getLoyaltyRedeemed
} from 'app/redux/actions/LoyaltyActions';

import LoyaltyEarnedTable from './shared/LoyaltyEarnedTable';
import LoyaltyRedeemedTable from './shared/LoyaltyRedeemedTable';
import { Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7'
    }
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: '#2e1534'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // className="py-4"
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const Loyalty = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (
      user &&
      user.restaurant &&
      user.restaurant &&
      user.restaurant.settings &&
      user.restaurant.settings.has_loyalty
    ) {
      dispatch(getLoyaltyEarned());
      dispatch(getLoyaltyRedeemed());
    }
  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Loyalty', path: '/loyalty/list' },
            { name: 'List' }
          ]}
        />
        <div className="">
          <Button
            component={RouterLink}
            to="/loyalty/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      {user &&
      user.restaurant &&
      user.restaurant &&
      user.restaurant.settings &&
      user.restaurant.settings.has_loyalty ? (
        <div className={classes.demo2}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab label="Earned Transactions" />
            <StyledTab label="Redeemed Transactions" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <LoyaltyEarnedTable />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LoyaltyRedeemedTable />
          </TabPanel>
          {/* <Typography className={classes.padding} /> */}
        </div>
      ) : (
        <Card className="px-6 pt-2 pb-4">
          <div className="card-title mb-3">Loyalty Program</div>
          <div className="overflow-auto">
            <div>
              You have not subscribed to Loyalty Program yet. Please contact{' '}
              {process.env.REACT_APP_APP} Sales Person
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default Loyalty;
