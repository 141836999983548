/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  outletQuickUpdate,
} from 'app/redux/actions/OutletActions';
import IOSSwitch from 'app/components/IOSSwitch';
import OutletFormHours from './OutletFormHours';
import OutletFormDeliveryAreas from './OutletFormDeliveryAreas';

const OutletFormDelivery = ({ initialData }) => {
  const [lastValue, setLastValue] = React.useState(null);
  const dispatch = useDispatch();
  const { deliveryHours } = useSelector((state) => state.outlet);

  const [form, setForm] = React.useState({
    has_delivery: false,
    pay_online: false,
    cash_on_delivery: false,
    card_on_delivery: false,
    delivery_fee: 0,
    delivery_time: 0,
    delivery_min_order: 0
  });

  React.useEffect(() => {
    if (initialData) {
      setForm({
        id: initialData.id,
        has_delivery: initialData.has_delivery,
        pay_online: initialData.pay_online,
        cash_on_delivery: initialData.cash_on_delivery,
        card_on_delivery: initialData.card_on_delivery,
        delivery_fee: initialData.delivery_fee || 0,
        delivery_time: initialData.delivery_time || 0,
        delivery_min_order: initialData.delivery_min_order || 0
      });
    }
  }, [initialData]);

  const quickUpdate = (el) => {
    if (el.target.value !== lastValue) {
      const data = { outlet_id: initialData.id, key: el.target.name };
      if (el.target.type === 'checkbox') {
        // console.log('IS CHECKBOX');
        data.value = el.target.checked;
      } else {
        data.value = el.target.value;
      }
      setForm({ ...form, [el.target.name]: data.value });
      dispatch(outletQuickUpdate(data));
    }
  };

  const onChange = (el) => {
    setForm({ ...form, [el.target.name]: el.target.value });
  };

  return (
    <div>
      <h5 component="legend">Delivery Details</h5>
      <div>
        <FormControlLabel
          className="mb-4"
          control={
            <IOSSwitch
              checked={form.has_delivery ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="has_delivery"
            />
          }
          label="Delivery Available"
        />
      </div>
      <div>
        <FormControlLabel
          className="mb-4"
          control={
            <IOSSwitch
              checked={form.pay_online ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="pay_online"
            />
          }
          label="Pay Online"
        />
      </div>
      <div>
        <FormControlLabel
          className="mb-4"
          control={
            <IOSSwitch
              checked={form.cash_on_delivery ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="cash_on_delivery"
            />
          }
          label="Cash on Delivery"
        />
      </div>
      <div>
        <FormControlLabel
          className="mb-4"
          control={
            <IOSSwitch
              checked={form.card_on_delivery ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="card_on_delivery"
            />
          }
          label="Card on Delivery"
        />
      </div>
      <FormGroup>
        <div className="flex items-center justify-start mb-4">
          <TextField
            className="mr-4"
            label="Delivery Fee"
            variant="outlined"
            // onFocus={(e) => console.log(e.target.value)}
            onFocus={(e) => setLastValue(e.target.value)}
            onBlur={(e) => quickUpdate(e)}
            onChange={onChange}
            type="text"
            name="delivery_fee"
            value={form.delivery_fee}
          />
          <TextField
            className="mr-4"
            label="Delivery Time"
            variant="outlined"
            onFocus={(e) => setLastValue(e.target.value)}
            onBlur={(e) => quickUpdate(e)}
            onChange={onChange}
            type="text"
            name="delivery_time"
            value={form.delivery_time}
          />
          <TextField
            className="mr-4"
            label="Minimum Order"
            variant="outlined"
            onFocus={(e) => setLastValue(e.target.value)}
            onBlur={(e) => quickUpdate(e)}
            onChange={onChange}
            type="text"
            name="delivery_min_order"
            value={form.delivery_min_order}
          />
        </div>
      </FormGroup>
      <OutletFormDeliveryAreas initialData={initialData} />
      <OutletFormHours service="delivery" initialData={initialData} deliveryHours={deliveryHours} />
    </div>
  );
};
export default OutletFormDelivery;
