import {
  GET_MENU_LIST,
  GET_MENU_DETAILS,
  ADD_MENU,
  EDIT_MENU,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_ITEMS_LIST,
  ADD_CATEGORY,
  GET_ATTRIBUTES_LIST,
  SAVE_ATTRIBUTE_GROUP,
  GET_MENU_OUTLETS
} from '../actions/MenuActions';

const initialState = {
  menuList: [],
  menuDetails: {},
  categoriesList: [],
  categoriesItemsList: [],
  attributesList: [],
  menuOutlets: [],
};

const MenuReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_MENU_LIST: {
      // console.log('MENU LIST REDUCER: ', action);
      return {
        ...state,
        menuList: [...action.payload]
      };
    }
    case GET_MENU_DETAILS: {
      // console.log('MENU LIST REDUCER: ', action);
      return {
        ...state,
        menuDetails: action.payload
      };
    }
    case ADD_MENU: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        menuList: [...state.menuList, action.payload]
      };
    }
    case EDIT_MENU: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        menuList: [...state.menuList, action.payload]
      };
    }
    case GET_CATEGORIES_LIST: {
      // console.log('CATEGORIES REDUCER: ', action);
      return {
        ...state,
        categoriesList: [...action.payload]
      };
    }
    case GET_CATEGORIES_ITEMS_LIST: {
      // console.log('CATEGORIES REDUCER: ', action);
      return {
        ...state,
        categoriesItemsList: [...action.payload]
      };
    }
    case GET_ATTRIBUTES_LIST: {
      // console.log('CATEGORIES REDUCER: ', action);
      return {
        ...state,
        attributesList: [...action.payload]
      };
    }
    case ADD_CATEGORY: {
      // console.log('CATEGORIES REDUCER: ', action);
      return {
        ...state,
        categoriesList: [...state.categoriesList, action.payload]
      };
    }
    case SAVE_ATTRIBUTE_GROUP: {
      // console.log('CATEGORIES REDUCER: ', action);
      return {
        ...state
        // categoriesList: [...state.categoriesList, action.payload],
      };
    }
    case GET_MENU_OUTLETS: {
      // console.log('CATEGORIES REDUCER: ', action);
      return {
        ...state,
        menuOutlets: [...action.payload],
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default MenuReducer;
