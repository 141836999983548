import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import { getApi } from 'app/services/api';

export const GET_MENU_LIST = 'GET_MENU_LIST';
export const GET_MENU_DETAILS = 'GET_MENU_DETAILS';
export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const GET_CATEGORIES_ITEMS_LIST = 'GET_CATEGORIES_ITEMS_LIST';
export const GET_ATTRIBUTES_LIST = 'GET_ATTRIBUTES_LIST';
export const GET_MODIFIER_GROUP_LIST = 'GET_MODIFIERS_GROUP_LIST';
export const ADD_MENU = 'ADD_MENU';
export const EDIT_MENU = 'EDIT_MENU';
export const DELETE_MENU = 'DELETE_MENU';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const LINK_ATTRIBUTE_GROUP = 'LINK_ATTRIBUTE_GROUP';
export const SAVE_ATTRIBUTE_GROUP = 'SAVE_ATTRIBUTE_GROUP';
export const DELETE_ATTRIBUTE_GROUP = 'DELETE_ATTRIBUTE_GROUP';
export const SAVE_ATTRIBUTE = 'SAVE_ATTRIBUTE';
export const LINK_MENU_OUTLET = 'LINK_MENU_OUTLET';
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const GET_MENU_OUTLETS = 'GET_MENU_OUTLETS';

// export const UPDATE_CART_AMOUNT = "UPDATE_CART_AMOUNT";

export const getMenuList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/menus?rows=1000`)
    .then((res) => {
      // console.log('MENUS ACTION:', res.data);
      dispatch({
        type: GET_MENU_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getMenuDetails = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_MENU_DETAILS,
    payload: {}
  });

    // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
    //   'jwt_token'
    // );
    const api = getApi(getState);
    return await api
    .get(`${process.env.REACT_APP_API_URL}/api/menus/detail/${id}`)
    .then((res) => {
      // console.log('MENUS ACTION:', res.data);
      dispatch({
        type: GET_MENU_DETAILS,
        payload: res.data.item
      });

      return res.data.item;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'Something went wrong';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'error'
          }
        })
      );
    });
};

export const deleteMenu = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/menus/delete/${id}`)
    .then((res) => {
      // console.log('CATS ACTION:', res.data);
      dispatch(
        enqueueSnackbar({
          message: 'Menu deleted',
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: DELETE_MENU,
        payload: res.data
      });

      return res.data;

      // dispatch(getMenuList());
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getCategoriesList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/categories?rows=1000`)
    .then((res) => {
      // console.log('CATS ACTION:', res.data);
      dispatch({
        type: GET_CATEGORIES_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getCategoriesItems = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(
      `${process.env.REACT_APP_API_URL}/api/categories/${id}/items?rows=1000`
    )
    .then((res) => {
      // console.log('CATS ACTION:', res.data);
      dispatch({
        type: GET_CATEGORIES_ITEMS_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const linkCategoryMenu = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/categories/assign-items`, data)
    .then((res) => {
      // console.log('OUTLET UPDATED:', res.data);

      const message = `Menu assigned to category`;

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      // dispatch({
      //   type: EDIT_OUTLET,
      //   payload: res.data.outlet,
      // });

      return true;
    })
    .catch((err) => {
      // console.log("ERROR:", err, err.response);

      showErrorMessage(dispatch, err);
    });
};

export const unLinkCategoryMenu = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/categories/unassign-items`,
      data
    )
    .then((res) => {
      // console.log('OUTLET UPDATED:', res.data);

      const message = `Menu un-assigned from category`;

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      // dispatch({
      //   type: EDIT_OUTLET,
      //   payload: res.data.outlet,
      // });

      return true;
    })
    .catch((err) => {
      // console.log("ERROR:", err, err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getAttributesList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/attributes/items?rows=1000`)
    .then((res) => {
      // console.log('CATS ACTION:', res.data);
      dispatch({
        type: GET_ATTRIBUTES_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const deleteAttributeGroup = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/attributes/groups/delete/${id}`)
    .then((res) => {
      // console.log('CATS ACTION:', res.data);
      dispatch(
        enqueueSnackbar({
          message: 'Modifier Group deleted',
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: DELETE_ATTRIBUTE_GROUP,
        payload: res.data.data
      });

      dispatch(getAttributesList());
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const deleteAttribute = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/attributes/items/delete/${id}`)
    .then((res) => {
      // console.log('CATS ACTION:', res.data);
      dispatch(
        enqueueSnackbar({
          message: 'Modifier deleted',
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: DELETE_ATTRIBUTE,
        payload: res.data.data
      });

      dispatch(getAttributesList());
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const menuSave = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState, true);
  
  // const headers = {
  //   Authorization: getState().auth.token,
  //   'content-type': 'multipart/form-data',
  //   contentType: 'multipart/form-data'
  // };

  // if (getState().auth.activeRestaurant && getState().auth.activeRestaurant.id) {
  //   headers['restaurant-id'] = getState().auth.activeRestaurant.id;
  // }

//   console.log('DATA', data.entries(), JSON.stringify(data));
//   for(var pair of data.entries()) {
//     console.log(pair[0]+ ', '+ pair[1]);
//  }
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/menus/save`, data
    // , headers
    )
    .then((res) => {
      const message = data.id ? 'Menu updated' : 'Menu added';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        dispatch({
          type: EDIT_MENU,
          payload: res.data.item
        });
      } else {
        dispatch({
          type: ADD_MENU,
          payload: res.data.item
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const categorySave = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState, true);
  const headers = {
    Authorization: localStorage.getItem('jwt_token'),
    'content-type': 'multipart/form-data',
    contentType: 'multipart/form-data'
  };
  // console.log('DATA', JSON.stringify(data));
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/categories/save`, data)
    .then((res) => {
      const message = data.id ? 'Category updated' : 'Category added';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        dispatch({
          type: EDIT_CATEGORY,
          payload: res.data.catrgory
        });
      } else {
        dispatch({
          type: ADD_CATEGORY,
          payload: res.data.catrgory
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const linkAttributeGroup = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/attributes/groups/link`,
      data
    )
    .then((res) => {
      const message = 'Modifier added to group';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: LINK_ATTRIBUTE_GROUP,
        payload: res.data.item
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const linkMenuOutlet = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/menus/outlet/link`,
      data
    )
    .then((res) => {
      // console.log('CATEGORY ADDED:', res.data);

      const message = 'Menu added to outlet';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: LINK_MENU_OUTLET,
        payload: res.data.item
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};


export const getMenuOutlets = (menuId) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/menus/outlet/relations/${menuId}`)
    .then((res) => {
      // console.log("OUTLET ACTION:", res.data);
      dispatch({
        type: GET_MENU_OUTLETS,
        payload: res.data
      });
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);
      showErrorMessage(dispatch, err);
    });
};

export const updateMenuOutletPricing = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/menus/outlet/pricing`,
      data
    )
    .then((res) => {
      // console.log('CATEGORY ADDED:', res.data);

      const message = 'Menu outlet price updated';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: GET_MENU_OUTLETS,
        payload: res.data
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const saveAttributeGroup = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/attributes/groups/save`,
      data,
      // headers
    )
    .then((res) => {
      // console.log('CATEGORY ADDED:', res.data);
      const message = data.id
        ? 'Modifier Group updated'
        : 'Modifier Group added';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: SAVE_ATTRIBUTE_GROUP,
        payload: res.data.item
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response.data);

      showErrorMessage(dispatch, err);
    });
};

export const saveAttribute = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/attributes/items/save`,
      data,
      // headers
    )
    .then((res) => {
      // console.log('CATEGORY ADDED:', res.data);
      const message = data.id ? 'Modifier updated' : 'Modifier added';
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: SAVE_ATTRIBUTE,
        payload: res.data.item
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};
