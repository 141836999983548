import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Chip
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
// import { statusChange } from 'app/redux/actions/CommonActions';

const PaymentLinksTable = () => {
  const dispatch = useDispatch();
  const { paymentLinkList } = useSelector((state) => state.outlet);

  const changeStatus = (e) => {
    // const data = {
    //   module: 'payment-link',
    //   ref_id: e.target.value,
    //   value: e.target.checked ? 1 : 0
    // };
    // dispatch(statusChange(data));
  };

  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="card-title mb-3">Payment Links</div>
      <div className="overflow-auto">
        {paymentLinkList && paymentLinkList.length ? (
          <Table className="menu__table">
            <TableHead>
              <TableRow>
                <TableCell className="px-1">
                  Name
                </TableCell>
                <TableCell className="px-1">
                  Mobile
                </TableCell>
                <TableCell className="px-1">
                  Amount
                </TableCell>
                <TableCell className="px-1">
                  Outlet
                </TableCell>
                <TableCell className="px-1">
                  Status
                </TableCell>
                <TableCell className="px-1">
                  Note
                </TableCell>
                <TableCell className="px-1">
                  Created Date
                </TableCell>
                {/* <TableCell className="px-0">
                  Action
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentLinkList.map((paymentLink, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="px-0 capitalize"
                   
                    align="left"
                  >
                    <div className="flex items-center">
                      {/* {paymentlink.image && paymentlink.image.thumb ? (
                        <img
                          className="img"
                          src={paymentlink.image.thumb}
                          alt="paymentlink"
                        />
                      ) : null} */}
                      <p className="m-0 ml-8">
                        {paymentLink.name}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {paymentLink.mobile}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {paymentLink.amount}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {paymentLink.outlet_name}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {paymentLink.paid ?   <Chip label="Paid" color="success" size='small' className='bg-green text-white' /> : <Chip label="Not Paid" size='small' />}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {paymentLink.note}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {new Date(paymentLink.created_at).toLocaleString('en-UK')}
                  </TableCell>
                  {/* <TableCell className="px-0">
                    <IconButton
                      component={RouterLink}
                      to={`/payment-links/edit/${paymentLink.id}`}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No payment links found...</div>
        )}
      </div>
    </Card>
  );
};

export default PaymentLinksTable;
