import React from 'react';
import Categories from './category/Categories';
import CategoriesItems from './category/CategoriesItems';
import MenuAdd from './menu/MenuAdd';
import MenuDetails from './menu/MenuDetails';
import MenuEdit from './menu/MenuEdit';
import Menus from './menu/Menus';
import Modifiers from './modifier/Modifiers';

const menuRoutes = [
  {
    path: '/menus/list',
    // component: React.lazy(() => import("./menu/Menus")),
    component: Menus
  },
  {
    path: '/menus/details/:id',
    // component: React.lazy(() => import("./menu/MenuDetails")),
    component: MenuDetails
  },
  {
    path: '/menus/add',
    // component: React.lazy(() => import("./menu/MenuAdd")),
    component: MenuAdd
  },
  {
    path: '/menus/edit/:id',
    // component: React.lazy(() => import("./menu/MenuEdit")),
    component: MenuEdit
  },
  {
    path: '/menus/categories/items/:id',
    // component: React.lazy(() => import("./category/Categories")),
    component: CategoriesItems
  },
  {
    path: '/menus/categories',
    // component: React.lazy(() => import("./category/Categories")),
    component: Categories
  },
  // {
  //   path: "/menus/categories/add",
  //   // component: React.lazy(() => import("./category/CategoryAdd")),
  //   component: CategoryAdd,
  // },
  // {
  //   path: "/menus/categories/edit/:id",
  //   component: React.lazy(() => import("./category/CategoryEdit")),
  // },
  // {
  //   path: "/menus/modifiers/add",
  //   component: React.lazy(() => import("./modifier/ModifierAdd")),
  // },
  {
    path: '/menus/modifiers',
    // component: React.lazy(() => import("./modifier/Modifiers")),
    component: Modifiers
  }
];

export default menuRoutes;
