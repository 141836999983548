/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { Icon, Button, Card } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { getAttributesList, GET_ATTRIBUTES_LIST } from 'app/redux/actions/MenuActions';
import ModifiersTable from '../shared/ModifiersTable';
import ModifierForm from '../shared/ModifierForm';
// import { showSuccessSnackbar } from "app/redux/actions/UIActions";

const Modifiers = (props) => {
  const dispatch = useDispatch();
  const [showForm, toggleForm] = React.useState(false);
  const [editForm, setEditForm] = React.useState({});

  React.useEffect(() => {
    dispatch({
      type: GET_ATTRIBUTES_LIST,
      payload: []
    });
    
    dispatch(getAttributesList());
  }, []);

  const editModifier = (data) => {
    setEditForm(data);
    toggleForm(true);
  };

  const modifierSaved = (toggle = false) => {
    // console.log('SAVEDDD');
    dispatch(getAttributesList());
    toggleForm(false);
  };

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Menus', path: '/menu/list' },
            { name: 'Modifiers' }
          ]}
        />
        <div className="">
          <Button
            onClick={() => editModifier({})}
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      {showForm ? (
        <Card className="p-6 my-6">
          <div className="mb-2 flex items-center justify-between">
            <div className="card-title mb-1">Add Modifier</div>
            {/* <IconButton aria-label="Cancel" onClick={() => editModifier({})}>
              <Close fontSize="default" />
            </IconButton> */}
          </div>
          <ModifierForm
            closeForm={() => modifierSaved()}
            initialData={editForm}
          />
        </Card>
      ) : null}
      <ModifiersTable onEdit={(data) => editModifier(data)} />
    </div>
  );
};

export default Modifiers;
