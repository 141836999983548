import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import { getApi } from 'app/services/api';

export const GET_OUTLET_LIST = 'GET_OUTLET_LIST';
export const GET_OUTLET_DETAILS = 'GET_OUTLET_DETAILS';
export const ADD_OUTLET = 'ADD_OUTLET';
export const EDIT_OUTLET = 'EDIT_OUTLET';
export const GET_OUTLET_DELIVERY_HOURS = 'GET_OUTLET_DELIVERY_HOURS';
export const GET_OUTLET_PICKUP_HOURS = 'GET_OUTLET_PICKUP_HOURS';
export const GET_LOCATIONS_LIST = 'GET_LOCATIONS_LIST';

export const GET_PAYMENT_LINK_LIST = 'GET_PAYMENT_LINK_LIST';
export const GET_PAYMENT_LINK_DETAILS = 'GET_PAYMENT_LINK_DETAILS';
export const ADD_PAYMENT_LINK = 'ADD_PAYMENT_LINK';

// export const UPDATE_CART_AMOUNT = "UPDATE_CART_AMOUNT";

export const getOutletsList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/outlets?rows=1000`)
    .then((res) => {
      // console.log("OUTLET ACTION:", res.data);
      dispatch({
        type: GET_OUTLET_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);
      showErrorMessage(dispatch, err);
    });
};

export const getOutletDetails = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/outlets/detail/${id}`)
    .then((res) => {
      // console.log("OUTLET DETAILS:", res.data);
      dispatch({
        type: GET_OUTLET_DETAILS,
        payload: res.data.outlet
      });
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getOutletDeliveryHours = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(
      `${process.env.REACT_APP_API_URL}/api/outlets/service-hours/delivery/${id}`
    )
    .then((res) => {
      // console.log("OUTLET DETAILS:", res.data);
      dispatch({
        type: GET_OUTLET_DELIVERY_HOURS,
        payload: res.data.hours
      });
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getOutletPickupHours = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(
      `${process.env.REACT_APP_API_URL}/api/outlets/service-hours/pickup/${id}`
    )
    .then((res) => {
      // console.log("OUTLET DETAILS:", res.data);
      dispatch({
        type: GET_OUTLET_PICKUP_HOURS,
        payload: res.data.hours
      });
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getLocationsList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/locations`)
    .then((res) => {
      // console.log("OUTLET DETAILS:", res.data);
      dispatch({
        type: GET_LOCATIONS_LIST,
        payload: res.data.data
      });
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);
      // dispatch(
      //   enqueueSnackbar({
      //     message: "Something went wrong",
      //     options: {
      //       variant: "error",
      //     },
      //   })
      // );
    });
};

export const outletSave = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  console.log('FORM DATA:')
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/outlets/save`, data)
    .then((res) => {
      const message = data.get('id')
        ? 'Outlet details updated'
        : 'Outlet added';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        dispatch({
          type: EDIT_OUTLET,
          payload: res.data.outlet
        });
      } else {
        dispatch({
          type: ADD_OUTLET,
          payload: res.data.outlet
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};

export const outletQuickUpdate = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/outlets/quick-update`, data)
    .then((res) => {
      // console.log("OUTLET UPDATED:", res.data);

      const message = 'Outlet details updated';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: EDIT_OUTLET,
        payload: res.data.outlet
      });
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};

export const updateOutletHours = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(
      `${process.env.REACT_APP_API_URL}/api/outlets/service-hours/update`,
      data
    )
    .then((res) => {
      // console.log("OUTLET UPDATED:", res.data);

      const message = `Outlet ${data.service} hours updated`;

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type:
          data.service == 'pickup'
            ? GET_OUTLET_PICKUP_HOURS
            : GET_OUTLET_DELIVERY_HOURS,
        payload: res.data.hours
      });

      // dispatch({
      //   type: EDIT_OUTLET,
      //   payload: res.data.outlet,
      // });
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};

export const linkOutletAreas = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/locations/outlet/link`, data)
    .then((res) => {
      // console.log('OUTLET UPDATED:', res.data);

      const message = `Outlet delivery areas updated`;

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      // dispatch({
      //   type: EDIT_OUTLET,
      //   payload: res.data.outlet,
      // });
    })
    .catch((err) => {
      // console.log("ERROR:", err, err.response);

      showErrorMessage(dispatch, err);
    });
};


export const getPaymentLinkList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/outlets/payment?rows=1000`)
    .then((res) => {
      // console.log("OUTLET ACTION:", res.data);
      dispatch({
        type: GET_PAYMENT_LINK_LIST,
        payload: res.data
      });
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);
      showErrorMessage(dispatch, err);
    });
};

export const getPaymentLinkDetails = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/outlets/payment-link/detail/${id}`)
    .then((res) => {
      // console.log("OUTLET DETAILS:", res.data);
      dispatch({
        type: GET_PAYMENT_LINK_DETAILS,
        payload: res.data
      });
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};


export const paymentLinkSave = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/outlets/payment/create`, data)
    .then((res) => {
      dispatch(
        enqueueSnackbar({
          message: 'Payment Link sent successfully.',
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        // dispatch({
        //   type: EDIT_OUTLET,
        //   payload: res.data.outlet
        // });
      } else {
        dispatch({
          type: ADD_PAYMENT_LINK,
          payload: res.data
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log("ERROR:", err.response);

      showErrorMessage(dispatch, err);
    });
};