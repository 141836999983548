import {
  GET_DISCOUNT_LIST,
  GET_DISCOUNT_DETAILS,
  ADD_DISCOUNT,
  EDIT_DISCOUNT,
  GET_DISCOUNT_PICKUP_HOURS,
  GET_DISCOUNT_DELIVERY_HOURS,
  GET_DISCOUNT_PARAMETERS
} from '../actions/DiscountActions';

const initialState = {
  discountsList: [],
  discountDetails: {},
  deliveryHours: [],
  pickupHours: [],
  parameters: {}
};

const DiscountReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DISCOUNT_LIST: {
      // console.log('DISCOUNT REDUCER: ', action);
      return {
        ...state,
        discountsList: [...action.payload]
      };
    }
    case GET_DISCOUNT_DETAILS: {
      // console.log('ORDER REDUCER: ', action.payload);
      return {
        ...state,
        discountDetails: action.payload
      };
    }
    case ADD_DISCOUNT: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        discountsList: [...state.discountsList, action.payload],
        discountDetails: action.payload
      };
    }
    case EDIT_DISCOUNT: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        discountsList: [...state.discountsList, action.payload],
        discountDetails: action.payload
      };
    }
    case GET_DISCOUNT_PICKUP_HOURS: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        pickupHours: action.payload
      };
    }
    case GET_DISCOUNT_DELIVERY_HOURS: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        deliveryHours: action.payload
      };
    }
    case GET_DISCOUNT_PARAMETERS: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        parameters: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default DiscountReducer;
