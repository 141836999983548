import React, { Fragment } from "react";
import { Grid, Card } from "@material-ui/core";

import DoughnutChart from "../charts/echarts/Doughnut";

import ModifiedAreaChart from "./shared/ModifiedAreaChart";
import StatCards from "./shared/StatCards";
import TableCard from "./shared/RecentOrders";
import { withStyles } from "@material-ui/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardData,
  getDashboardChart,
} from "app/redux/actions/CommonActions";
import "rsuite/dist/styles/rsuite-default.css";
import DateRange from "app/components/DateRange";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { dashboardChart, dateRange } = useSelector((state) => state.common);
  // const [open, setOpen] = React.useState(false);
  // const [dateRange, setDateRange] = React.useState({});

  // const state = {
  //   open: false,
  //   dateRange: {},
  // };

  React.useEffect(() => {
    // console.log("USE EFFECT");
    dispatch(getDashboardData());
    dispatch(getDashboardChart());
  }, [dispatch, dateRange]);

  // let { theme } = this.props;

  return (
    <Fragment>
      <div className="pb-24 pt-7 px-8 bg-primary">
        <div className="card-title capitalize text-white mb-4 text-white-secondary flex items-center">
          {/* <p onClick={() => toggle()}>Last 12 months sales</p> */}
          <DateRange />
        </div>
        {dashboardChart.line_chart ? (
          <ModifiedAreaChart
            height="280px"
            option={{
              series: [
                {
                  data: dashboardChart.line_chart.values,
                  type: "line",
                },
              ],
              xAxis: {
                data: dashboardChart.line_chart.days,
              },
            }}
          ></ModifiedAreaChart>
        ) : null}
      </div>

      <div className="analytics m-sm-30 mt--18">
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <StatCards />

            {/* Top Selling Products */}
            <TableCard />

            {/* <StatCards2 /> */}

            {/* <h4 className="card-title text-muted mb-4">Ongoing Projects</h4>
              <RowCards /> */}
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card className="px-6 py-4 mb-6">
              <div className="card-title">Order Sources</div>
              <div className="card-subtitle">Last 30 days</div>
              <DoughnutChart
                height="400px"
                color={[
                  props.theme.palette.primary.dark,
                  props.theme.palette.primary.main,
                  props.theme.palette.primary.light,
                ]}
                data={dashboardChart.doughnut_chart}
              />
            </Card>

            {/* <UpgradeCard /> */}

            {/* <Campaigns /> */}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default withStyles({}, { withTheme: true })(Dashboard);
