import React from 'react';
import { Breadcrumb } from 'matx';
import { Card, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import UserForm from './shared/UserForm';
import { userSave } from 'app/redux/actions/UserActions';

const UserAdd = () => {
  const [redirect, setRedirect] = React.useState(null);
  const dispatch = useDispatch();
  const submitUser = async (data) => {
    const user = await dispatch(userSave(data));
    // console.log('MM:', user);
    if (user && user.user && user.user.id) {
      setRedirect(`/users/list`);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Users', path: '/users/list' },
            { name: 'Add' }
          ]}
        />
      </div>
      <Box maxWidth={600}>
        <Card className="px-6 py-6">
          <UserForm onSave={(data) => submitUser(data)} />
        </Card>
      </Box>
    </div>
  );
};
export default UserAdd;
