import React from 'react';
import {
  Grid,
  CardContent,
  Chip,
  Container,
  Button,
  Box,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import moment from 'moment';
import OrderStatus from 'app/components/OrderStatus';
import OrderStatusButton from 'app/components/OrderStatusButton';
import { ArrowBack } from '@material-ui/icons';
import PrintOrder from './PrintOrder';

const OrderDetails = ({ order, toggleDetails, hideActions = false }) => {
  // let printEl = React.useRef();

  const printOrder = () => {
    // var divContents = printEl.current.innerHTML;
    // var printWindow = window.open('', '', 'height=200,width=400');
    // printWindow.document.write('<html><head><title>Print DIV Content</title>');
    // printWindow.document.write('</head><body >');
    // printWindow.document.write(divContents);
    // printWindow.document.write('</body></html>');
    // printWindow.document.close();
    // printWindow.print();

    var content = document.getElementById('print-content');
    var doc = document.getElementById('ifmcontentstoprint');
    // doc.src = '/orders/print/' + order.id;
    var pri = doc.contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();

    // window.print();
  };

  if (!order.id) return <CircularProgress />;

  return (
    <Box className="h-full w-full flex" position="relative">
      <CardContent className="h-full w-full flex flex-column">
        <div className="order-details-close mb-4">
          <Button
            onClick={() => toggleDetails()}
            className="w-full"
            color="secondary"
            variant="contained"
            startIcon={<ArrowBack />}
          >
            Back to Orders List
          </Button>
        </div>
        <Box mb={3} minHeight={55}>
          <Divider className="mb-2" />
          <Grid
            container
            alignItems="center"
            className="orderCard__header px-3"
          >
            <Grid item xs={4}>
              <strong>#{order.order_no}</strong>
            </Grid>
            <Grid item xs={4}>
              <strong>
                {/* {order.ordered_at} : */}
                {moment(order.ordered_at).fromNow()}
              </strong>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Chip
                  color="primary"
                  size="small"
                  className={`bg-${order.status}`}
                  label={<OrderStatus status={order.status} />}
                />
                {/* <Button href={`/orders/print/${order.id}`} target="_blank">
                  <PrintIcon />
                </Button> */}
                <Button onClick={() => printOrder()}>
                  <PrintIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider className="mt-2" />
        </Box>
        <Box
          className="overflow-auto h-full"
          // id="print-area"
        >
          <div className="px-4">
            <Grid container>
              {order.pickup_customer_note && (
                <Grid item xs={12} className="mb-3">
                  <div className="text-muted f-size-12">Pickup Message</div>
                  <div className="">
                    <div
                      className={`p-2 block border-radius-4 bg-green text-white text-center ${
                        order.status !== 'completed' ? 'blink' : ''
                      }`}
                    >
                      {order.pickup_customer_note}
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12">
                  {order.ordering_service} at
                </div>
                <div className="">
                  <span>
                    {' '}
                    {order.delivery_at ? (
                      <div className="p-2 inline-block border-radius-4 bg-error text-white">
                        {moment(order.delivery_at).format('DD/MM/YYYY hh:mm A')}
                      </div>
                    ) : (
                      <strong>ASAP</strong>
                    )}{' '}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12">Outlet</div>
                <strong className="f-size-12"> {order.outlet_name} </strong>
              </Grid>
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12"> Order type </div>
                <strong className="f-size-12">
                  {' '}
                  {order.ordering_service}{' '}
                </strong>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12"> Customer </div>
                <strong className="f-size-12">
                  <span className="block">{order.customer.name}</span>
                  <span className="text-green">
                    Ordered {order.customer_total_order} time{' '}
                  </span>
                </strong>
              </Grid>
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12"> Phone number </div>
                <strong className="f-size-12"> {order.customer.mobile} </strong>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12"> Payment </div>
                <strong className="f-size-12"> {order.payment_method} </strong>
              </Grid>
              <Grid item xs={6} className="mb-3">
                <div className="text-muted f-size-12"> Channel </div>
                <strong className="f-size-12">
                  <div className=""> {order.channel} </div>
                </strong>
              </Grid>
            </Grid>
            <Grid container className="clearfix mb-3">
              <Grid item xs={6} className="mb-3">
                {order.ordering_service.toLowerCase() === 'pickup' ? (
                  <>
                    <div className="text-muted f-size-12">Pickup from</div>
                    <strong className="f-size-12">
                      {order.address.name.en}, {order.address.address.en}
                    </strong>
                  </>
                ) : (
                  <>
                    <div className="text-muted f-size-12">Delivering to</div>
                    {order.address.lat && order.address.lng ? (
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${order.address.lat},${order.address.lng}`}
                      target="_blank"
                    >
                      <strong className="f-size-12">
                        {[
                          order.address.address_type,
                          order.address.address_1,
                          order.address.address,
                          order.address.area,
                          order.address.emirate
                        ]
                          .filter(Boolean)
                          .join(',')}
                      </strong>
                    </a>
                    ) : (
                      <strong className="f-size-12">
                        {[
                          order.address.address_type,
                          order.address.address_1,
                          order.address.address,
                          order.address.area,
                          order.address.emirate
                        ]
                          .filter(Boolean)
                          .join(',')}
                      </strong>
                    )}
                    <div className="">
                      {/* <Button
                        // variant="text"
                        component={Link}
                        size="small"
                        fullWidth
                        color="primary"
                        className=""
                        href="https://www.google.com/maps/search/?api=1&query=25.306293300,55.458943800"
                        target="_blank"
                        // onClick={() => setOpenComplete(true)}
                      >
                        Get Direction
                      </Button> */}
                      {''}
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className="mt-2"
                        target="_blank"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://www.google.com/maps/search/?api=1&query=${order.address.lat},${order.address.lng}`
                          );
                        }}
                      >
                        Copy Location
                      </Button>
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          {order.note ? (
            <div className="pt-3 pb-4 px-4">
              <div className="px-4 py-2 border-radius-4 bg-secondary text-white">
                <p>
                  <strong>Notes</strong>
                </p>
                <p>{order.note}</p>
              </div>
            </div>
          ) : null}
          <div className="pt-3 pb-4">
            {order.items.length
              ? order.items.map((item, index) => (
                  <Container key={`${item.id}`}>
                    <Grid container className="mb-3">
                      <Grid item xs={1} className="text-center">
                        {item.quantity}x
                      </Grid>
                      <Grid item xs className="col pl-0">
                        {item.menu_name}
                      </Grid>
                      <Grid item xs={3} className="col-3 text-right">
                        {item.total}
                      </Grid>
                    </Grid>

                    {item.attributes.length
                      ? item.attributes.map((attribute, index) => (
                          <Grid
                            key={`${attribute.id}`}
                            container
                            className="mb-3"
                          >
                            <Grid item xs={1} className="text-center">
                              {' '}
                            </Grid>
                            <Grid item xs className="col pl-0">
                              {attribute.attribute}
                            </Grid>
                          </Grid>
                        ))
                      : null}

                    {item.note ? (
                      <Grid container className="mb-3">
                        <Grid item xs className="col pl-0">
                          Note: {item.note}
                        </Grid>
                      </Grid>
                    ) : null}
                    <hr />
                  </Container>
                ))
              : null}
          </div>
          <div className="text-right mb-10 pb-10">
            <Container>
              {order.discount && order.discount.has_discount ? (
                <>
                  <div className="mb-2">
                    Original Subtotal:{' '}
                    <span className="">{order.actual_sub_total}</span>
                  </div>
                  <div className="mb-2">
                    Discount ({order.discount.code}):{' '}
                    <span className=""> -{order.discount.amount || 0}</span>
                  </div>
                </>
              ) : null}
              <div className="mb-2">
                Subtotal:{' '}
                <span className="">
                  {Number(order.tax) && order.tax_type == 'Excl'
                    ? order.total_without_tax
                    : order.total_with_tax}
                </span>
              </div>
              {Number(order.delivery_fee) ? (
                <div className="mb-2">
                  Delivery Fee:{' '}
                  <span className=""> {order.delivery_fee || 0}</span>
                </div>
              ) : null}
              {Number(order.tax) && order.tax_type == 'Excl' ? (
                <div className="mb-2">
                  VAT: <span className=""> {order.tax}</span>
                </div>
              ) : null}
              {order.redeemed_amount ? (
                <div className="mb-2">
                  Loyalty Saving:{' '}
                  <span className=""> -{order.redeemed_amount}</span>
                </div>
              ) : null}
              <h5 className="m-0">
                Total:{' '}
                <span className="text-primary ">{order.amount_to_pay}</span>
              </h5>
            </Container>
          </div>
        </Box>
        {order.status !== 'completed' && !hideActions && (
          <Box
            className="orderCard__actions"
            // position={"fixed"}
            bottom={0}
            width={1}
            left={0}
            // padding={2}
          >
            <OrderStatusButton order={order} />
          </Box>
        )}
      </CardContent>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: 0, width: 0, position: 'absolute' }}
      ></iframe>
      <div id="print-content">
        <PrintOrder order={order} />
      </div>
    </Box>
  );
};

export default OrderDetails;
