import React, { Component } from 'react';
import { Card, Grid, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { loginWithEmailAndPassword } from 'app/redux/actions/LoginActions';

const styles = (theme) => ({
  wrapper: {
    position: 'relative'
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

class SignIn extends Component {
  state = {
    username: '',
    password: ''
  };
  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = (event) => {
    const user = this.props.loginWithEmailAndPassword({ ...this.state });
    // console.log('LOGIN SUCCESS', user);
  };
  render() {
    let { username, password } = this.state;
    let { classes } = this.props;
    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="px-8 position-relative">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center items-center h-full">
                  {process.env.REACT_APP_APP.toLowerCase() === 'zoyabean' ? (
                    <img
                      src="/assets/images/logo-zoyabean.png"
                      alt="Zoyabean"
                    />
                  ) : (
                    <img src="/assets/images/logo.png" alt="Eatroot" />
                  )}
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Email/Mobile"
                      onChange={this.handleChange}
                      type="text"
                      name="username"
                      value={username}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <TextValidator
                      className="mb-3 w-full"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    {/* <FormControlLabel
                      className="mb-3"
                      name="agreement"
                      onChange={this.handleChange}
                      control={<Checkbox checked />}
                      label="I have read and agree to the terms of service."
                    /> */}
                    <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          // disabled={this.props.login.loading}
                          type="submit"
                        >
                          Sign In
                        </Button>
                        {this.props.login.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                    <Button
                      className="text-primary"
                      onClick={() =>
                        this.props.history.push('/session/forgot-password')
                      }
                    >
                      Forgot password?
                    </Button>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
          {/* <p className="text-white text-center position-bottom x-center">Powered by Iotics Middle East</p> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, { loginWithEmailAndPassword })(SignIn))
);
