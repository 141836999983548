import jwtAuthService from "../../services/jwtAuthService";
import { setToken, setUserData } from "./AuthActions";
import history from "history.js";
import { enqueueSnackbar } from "./UIActions";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

export function loginWithEmailAndPassword({ username, password }) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    // console.log('TRYING TO LOGIN');
    jwtAuthService
      .loginWithEmailAndPassword(username, password)
      .then((user) => {
        // console.log("ACTION:", user);
        if (user) {
          dispatch(setUserData(user.user));
          dispatch(setToken(user.token));

          history.push({
            pathname: "/",
          });

          return dispatch({
            type: LOGIN_SUCCESS,
          });
        } else {
          // console.log('LOGIN FAILED');
          dispatch(
            enqueueSnackbar({
              message: "Login failed. Invalid credentials.",
              options: {
                variant: "error",
              },
            })
          );
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed",
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}
