import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// import { useSnackbar } from 'notistack';
// import { removeSnackbar } from 'app/redux/actions/UIActions';
import Pusher from 'pusher-js';
import { getLiveOrders, getOrderDetails } from 'app/redux/actions/OrderActions';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import history from 'history.js';

// const useAudio = (url) => {
//   const [audio] = React.useState(new Audio(url));
//   const [playing, setPlaying] = React.useState(false);

//   const toggle = () => setPlaying(!playing);
//   const play = () => setPlaying(true);
//   const stop = () => setPlaying(false);

//   React.useEffect(() => {
//     audio.muted = true;
//   }, []);

//   React.useEffect(() => {
//     if (playing) {
//       audio.muted = false;
//       audio.play();
//     } else {
//       audio.pause();
//     }
//   }, [playing, audio]);

//   React.useEffect(() => {
//     audio.addEventListener('ended', () => setPlaying(false));
//     return () => {
//       audio.removeEventListener('ended', () => setPlaying(false));
//     };
//   }, [audio]);

//   return [playing, toggle, play, stop];
// };

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: 'ap2',
  encrypted: true
});

const RingTone = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { ordersListLive } = useSelector((state) => state.order);

  const [audio] = React.useState(new Audio('/assets/media/order_ringtone.mp3'));
  const [playing, setPlaying] = React.useState(false);

  const [message, setMessage] = React.useState({});
  const [openPickup, setOpenPickup] = React.useState(false);
  const [openNewOrder, setOpenNewOrder] = React.useState(false);
  const [openInitial, setOpenInitial] = React.useState(false);
  const { user } = useSelector((state) => state.auth);

  const play = () => setPlaying(true);
  const stop = () => setPlaying(false);

  // React.useEffect(() => {
  //   audio.muted = true;
  // }, []);
  React.useEffect(() => {
    // console.log('play changed000: ', playing);
  }, [playing]);

  React.useEffect(() => {
    async function getOrders() {
      // const orders = await dispatch(getLiveOrders());
      if (user && user.id && window.location.pathname.indexOf('print') < 0) {
        // setOpenInitial(true);
        playSound();
      }
    }
    getOrders();
  }, [user]);

  React.useEffect(() => {
    // console.log(location.pathname);
  }, [location]);

  React.useEffect(() => {
    // console.log('play changed: ', playing);
    if (playing) {
      // console.log('Trying to play sound');
      playSound();
    } else {
      // console.log('Pausing');
      audio.pause();
    }
  }, [playing]);

  const playSound = () => {
    var promise = audio.play();

    if (promise !== undefined) {
      promise
        .then((_) => {
          // console.log('sound playing');
          // Autoplay started!
        })
        .catch((error) => {
          // console.log('User not interacted');
          // alert('Click ok to start playing sound');
          setOpenInitial(true);
        });
    }
  };

  React.useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  React.useEffect(() => {
    // console.log('LOADING LIVE ORDERS');
    const loadOrders = setInterval(() => {
      // console.log('GET LIVE');
      if (user && user.id) {
        setPlaying(false);
        dispatch(getLiveOrders());
      } else {
        // console.log('clear');
        clearInterval(loadOrders);
        // window.location.reload(true);
      }
    }, 15000);
    return () => {
      // console.log('STOPPING AUDIO');
      clearInterval(loadOrders);
      stop();
    };
  }, [user]);



  // React.useEffect(() => {
  //   // console.log('LOADING LIVE ORDERS');
  //   const loadOrders = setInterval(async () => {
  //     // console.log('GET LIVE');
  //     if (user && user.id) {
  //       // console.log('GET LIVE ORDERS');
  //       setPlaying(false);
  //       await dispatch(getLiveOrders());
  //     } else {
  //       // console.log('clear');
  //       clearInterval(loadOrders);
  //       // window.location.reload(true);
  //     }
  //   }, 10000);
  //   return () => {
  //     // console.log('STOPPING AUDIO');
  //     clearInterval(loadOrders);
  //     stop();
  //   };
  // }, [user]);
  // React.useEffect(() => {
  //   console.log('USER:::', user);
  // }, [user]);

  // React.useEffect(() => {
  //   async function getOrders() {
  //     console.log('INITIAL:');
  //     if (user && user.id) {
  //       const orders = await dispatch(getLiveOrders());
  //       if (orders.length) setOpenInitial(true);
  //       else setOpenInitial(true);
  //     }
  //   }
  //   // alert('Ok');
  //   // setOpenInitial(true);
  //   getOrders();
  // }, [user]);

  React.useEffect(() => {
    // console.log('PLAYING:', playing);
    if (user && user.id) checkNewOrder();
  }, [ordersListLive, playing, user]);

  React.useEffect(() => {
    // console.log('PUSHER LOADED:', process.env.REACT_APP_PUSHER_APP_KEY);
    // const user = JSON.parse(localStorage.getItem('auth_user'));
    if (user && user.restaurant) {
      const channel = pusher.subscribe('eatroot');
      channel.bind(user.restaurant.slug + '-new-order', (data) => {
        // console.log('PUSHER::', data);
        if (
          user.outlets &&
          user.outlets.some((el) => {
            // console.log(
            //   'EL:',
            //   Number(el.id),
            //   Number(data.outletId),
            //   Number(el.id) === Number(data.outletId)
            // );
            return Number(el.id) === Number(data.outletId);
          })
        ) {
          setOpenNewOrder(true);
          setMessage(data);
          dispatch(getLiveOrders());
        }
      });

      channel.bind(user.restaurant.slug + '-order-status-change', (data) => {
        // console.log('PUSHER::', data);
        if (
          user.outlets &&
          user.outlets.some((el) => {
            // console.log(
            //   'EL:',
            //   Number(el.id),
            //   Number(data.outletId),
            //   Number(el.id) === Number(data.outletId)
            // );
            return Number(el.id) === Number(data.outletId);
          })
        ) {
          // setOpenNewOrder(true);
          // setMessage(data);
          dispatch(getLiveOrders());
        }
      });

      channel.bind(user.restaurant.slug + '-customer-arrived', (data) => {
        // console.log('PUSHER::', data);
        if (
          user.outlets &&
          user.outlets.some((el) => {
            // console.log(
            //   'EL:',
            //   Number(el.id),
            //   Number(data.outletId),
            //   Number(el.id) === Number(data.outletId)
            // );
            return Number(el.id) === Number(data.outletId);
          })
        ) {
          setMessage(data);
          setOpenPickup(true);
          play();
          dispatch(getLiveOrders());
        }
      });
    }
  }, []);

  // React.useEffect(() => {
  //   // console.log('MESSAGE::', message);
  //   // play();
  // }, [message]);

  const goToOrder = () => {
    setOpenPickup(false);
    setOpenNewOrder(false);
    setOpenInitial(false);
    if (message.orderId) {
      dispatch(getOrderDetails(message.orderId));
    }
    history.push('/orders/live');
  };

  const checkNewOrder = () => {
    // console.log('CHEKING NEW ORDER');
    // stop();
    setPlaying(false);
    if (
      ordersListLive &&
      ordersListLive.length &&
      ordersListLive.some((item) => item.status == 'placed')
    ) {
      // console.log('THERE IS NEW ORDER');
      setPlaying(false);
      // setOpenNewOrder(true);
      play();
      // checkNewOrder();
    }
  };

  // if (!message || !message.orderNo) return null;

  return (
    <div>
      <Dialog
        open={openInitial}
        // onClose={() => setOpenInitial(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Permission</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please click <strong>Confirm</strong> button to play sound when new
            orders arrive.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInitial(false)} color="secondary">
            Confirm
          </Button>
          {/* {ordersListLive.length ? (
            <Button onClick={() => goToOrder()} color="primary" autoFocus>
              Goto Live Orders
            </Button>
          ) : null} */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNewOrder}
        onClose={() => setOpenNewOrder(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'New Order'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have a new order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewOrder(false)} color="secondary">
            Close
          </Button>
          <Button onClick={() => goToOrder()} color="primary" autoFocus>
            Goto Order Details
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPickup}
        onClose={() => setOpenPickup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Customer Arrived'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>{message.customerName}</strong> has arrived to collect the
            order #{message.orderNo}. <br />
            You may call the customer on {message.customerMobile}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPickup(false)} color="secondary">
            Close
          </Button>
          <Button onClick={() => goToOrder()} color="primary" autoFocus>
            Goto Order Details
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RingTone;
