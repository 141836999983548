/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button, Icon, Grid } from '@material-ui/core';
import Select2 from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { linkZoneAreas, getLocationsList } from 'app/redux/actions/ZoneActions';
import makeAnimated from 'react-select/animated';
import { MenuItem } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { getOutletsList } from 'app/redux/actions/OutletActions';

const animatedComponents = makeAnimated();

const LoyaltyForm = ({ onSave, initialData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { outletsList } = useSelector((state) => state.outlet);
  const formRef = React.useRef();
  const [form, setForm] = React.useState({
    customer_name: '',
    customer_mobile: '',
    purchase_ref: '',
    // purchaseType: 3,
    purchase_amount: 0,
    outlet_id: ''
  });

  React.useEffect(() => {
    // console.log('FORM 0::', form);
    dispatch(getOutletsList());

    if (
      user.role != 'admin' &&
      user.outlets &&
      user.outlets[0] & user.outlets[0].id
    ) {
      setForm({ ...form, outlet_id: user.outlets[0].id });
    }

    return () => {
      setForm({});
      // console.log('FORM 1::', form);
    };
  }, []);

  // React.useEffect(() => {
  //   console.log('FORM::', form);
  // }, [form]);

  React.useEffect(() => {
    if (initialData) {
      setForm({
        id: initialData.id,
        customer_name: initialData.customer_name,
        customer_mobile: initialData.customer_mobile,
        purchase_ref: initialData.purchase_ref,
        // purchaseType: 3,
        purchase_amount: initialData.purchase_amount
      });
    }
    return () => {
      setForm({});
      // ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [initialData]);

  const handleSubmit = () => {
    onSave(form);
  };

  const handleChange = (event) => {
    // event.persist();
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h5 component="legend" className="mb-4">
              Add new walk in Loyalty Transaction
            </h5>
            {user && user.role == 'admin' && (
              <FormControl
                variant="outlined"
                className={`w-full mb-4 `}
                style={{ minWidth: 100 }}
              >
                <InputLabel id="outlet-label">Outlet</InputLabel>
                <Select
                  labelId="outlet-label"
                  id="outlet"
                  value={form.outlet_id}
                  onChange={handleChange}
                  name="outlet_id"
                  label="Outlet"
                  size="small"
                  validators={['required']}
                >
                  {outletsList && outletsList.length ? (
                    outletsList.map((outlet) => (
                      <MenuItem key={outlet.id} value={outlet.id}>
                        <span>{outlet.name_en}</span>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={0}>
                      <span>Outlet not added</span>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
            <TextValidator
              className="mb-4 w-full"
              label="Customer Name"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="customer_name"
              value={form.customer_name || ''}
              validators={['required']}
              errorMessages={['Customer name is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Customer Mobile"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="customer_mobile"
              value={form.customer_mobile || ''}
              validators={['required']}
              errorMessages={['Customer Mobile is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Order Reference"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="purchase_ref"
              value={form.purchase_ref || ''}
              validators={['required']}
              errorMessages={['Order Reference is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Purchase Amount"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="purchase_amount"
              value={form.purchase_amount || ''}
              validators={['required']}
              errorMessages={['Purchase Amount is required']}
            />
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="my-3"
          size="large"
        >
          <Icon>send</Icon>
          <span className="pl-2 capitalize">Save</span>
        </Button>
      </ValidatorForm>
    </div>
  );
};
export default LoyaltyForm;
