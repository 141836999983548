import {
  GET_LIVE_ORDERS,
  GET_ORDERS_LIST,
  GET_ORDERS_DETAILS
} from '../actions/OrderActions';

const initialState = {
  ordersListLive: [],
  ordersList: [],
  ordersPagination: {},
  orderDetails: {}
};

const OrderReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LIVE_ORDERS: {
      // console.log('ORDER REDUCER: ', action);
      return {
        ...state,
        ordersListLive: [...action.payload]
      };
    }
    case GET_ORDERS_LIST: {
      // console.log('ORDER REDUCER: ', action);
      return {
        ...state,
        ordersList: [...action.payload.data],
        ordersPagination: action.payload.pagination
      };
    }
    case GET_ORDERS_DETAILS: {
      // console.log('ORDER REDUCER: ', action.payload);
      return {
        ...state,
        orderDetails: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default OrderReducer;
