/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
// import { useDispatch } from 'react-redux';

import MarketingTable from './shared/MarketingTable';
// import { getMarketingList } from 'app/redux/actions/UserActions';

const Marketing = (props) => {
  // const dispatch = useDispatch();
  // React.useEffect(() => {
  //   // dispatch(getMarketingList());
  // }, []);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Marketing', path: '/marketing/list' },
            { name: 'List' }
          ]}
        />
        <div className=""></div>
      </div>
      <MarketingTable />
    </div>
  );
};

export default Marketing;
