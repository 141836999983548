import React from "react";
import Marketing from "./Marketing";

const userRoutes = [
  {
    path: "/marketing/list",
    // component: React.lazy(() => import("./Marketing")),
    component: Marketing,
  },
  // {
  //   path: "/marketing/add",
  //   component: React.lazy(() => import("./UserAdd")),
  // },
  // {
  //   path: "/marketing/edit/:id",
  //   component: React.lazy(() => import("./UserEdit")),
  // },
];

export default userRoutes;
