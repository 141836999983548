/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { Icon, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getZonesList } from 'app/redux/actions/ZoneActions';

import ZoneTable from './shared/ZoneTable';

const Zones = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getZonesList());
  }, []);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Zones', path: '/zones/list' },
            { name: 'List' }
          ]}
        />
        <div className="">
          <Button
            component={RouterLink}
            to="/zones/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      <ZoneTable />
    </div>
  );
};

export default Zones;
