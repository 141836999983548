import React from 'react';
import {
  Card,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSelector, useDispatch } from 'react-redux';
import { getMenuOutlets } from 'app/redux/actions/MenuActions';
import { Link } from 'react-router-dom';
import MenuDetailsOutletItem from './MenuDetailsOutletItem';

const MenuDetailsOutlets = () => {
  const dispatch = useDispatch();
  const { menuDetails, menuOutlets } = useSelector((state) => state.menu);
  // const [selectedOutlets, setSelectedOutlets] = React.useState([]);

  React.useEffect(() => {
    // dispatch(getOutletsList());
    if (menuDetails.id) dispatch(getMenuOutlets(menuDetails.id));
    if (menuDetails.outlets) {
      // const outs = menuDetails.outlets.map((el) => Number(el.id));
      // //   console.log("OKK", outs);
      // setSelectedOutlets(outs);
    }
  }, [dispatch, menuDetails]);

  //   React.useEffect(() => {
  //     console.log("SELECTED 2 :", selectedOutlets);
  //   }, [selectedOutlets]);

  // const handleOutletSelect = (event) => {
  //   // console.log('CL', event.target.value, selectedOutlets);
  //   let locations = selectedOutlets;
  //   let check = event.target.checked;
  //   let checked = Number(event.target.value);
  //   if (check) {
  //     setSelectedOutlets([...locations, checked]);
  //     dispatch(
  //       linkMenuOutlet({
  //         outlet_id: checked,
  //         menu_id: menuDetails.id,
  //         action: 'link'
  //       })
  //     );
  //   } else {
  //     setSelectedOutlets(locations.filter((cat) => Number(cat) !== checked));
  //     dispatch(
  //       linkMenuOutlet({
  //         outlet_id: checked,
  //         menu_id: menuDetails.id,
  //         action: 'unlink'
  //       })
  //     );
  //   }
  // };

  if (!menuDetails.id) {
    return <CircularProgress />;
  }

  return (
    <Card className="px-6 pt-2 mb-4 pb-4">
      <Box className="pt-2 pb-4">
        <div className="card-title mb-3">Outlets</div>
        {menuOutlets.length ? (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="outlets-accordion"
            >
              <Typography>
                Available at {menuOutlets.length} out of{' '}
                {menuOutlets.length} outlets
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="w-full">
                {menuOutlets.map((outlet) => {

                  return <MenuDetailsOutletItem outlet={outlet} menuDetails={menuDetails} key={outlet.outlet_id} selected={outlet.linked} />
                  // <ListItem key={`${outlet.id}`}>
                  //   <Grid container spacing={1}>
                  //     <Grid item lg={3} md={3} sm={6} xs={12}>
                  //       <p>{outlet.name_en}</p>
                  //     </Grid>
                  //     <Grid item lg={3} md={3} sm={6} xs={12}>
                  //       <p><TextField variant='outlined' size='small' defaultValue="Hello world" /></p>
                  //     </Grid>
                  //     <Grid item lg={3} md={3} sm={6} xs={12}>
                  //       <p><TextField variant='outlined' size='small' defaultValue="Hello world" /></p>
                  //     </Grid>
                  //   </Grid>
                  //   <ListItemSecondaryAction>
                  //     <Switch
                  //       edge="end"
                  //       onChange={handleOutletSelect}
                  //       value={outlet.id}
                  //       checked={selectedOutlets.includes(Number(outlet.id))}
                  //       inputProps={{
                  //         'aria-labelledby': 'switch-list-label-wifi'
                  //       }}
                  //     />
                  //   </ListItemSecondaryAction>
                  // </ListItem>
                }
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        ) : (
          <div>
            <h4>No outlets added.</h4>
            <Link to="/outlets/add">Add new outlet.</Link>
          </div>
        )}
      </Box>
    </Card>
  );
};

export default MenuDetailsOutlets;
