import React from 'react';
import { Breadcrumb } from 'matx';
import { Card, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { paymentLinkSave } from 'app/redux/actions/OutletActions';
import PaymentLinkForm from './shared/PaymentLinkForm';

const PaymentLinkAdd = () => {
  const [redirect, setRedirect] = React.useState(null);
  const dispatch = useDispatch();
  const submitPaymentLink = async (data) => {
    const paymentlink = await dispatch(paymentLinkSave(data));
    // console.log('MM:', paymentlink);
    if (paymentlink && paymentlink.paymentlink && paymentlink.paymentlink.id) {
      setRedirect(`/payment-links/list`);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Payment Links', path: '/payment-links/list' },
            { name: 'Add' }
          ]}
        />
      </div>
      <Box maxWidth={600}>
        <Card className="px-6 py-6">
          <PaymentLinkForm onSave={(data) => submitPaymentLink(data)} />
        </Card>
      </Box>
    </div>
  );
};
export default PaymentLinkAdd;
