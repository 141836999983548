import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import API, {setHeader, getApi} from './../../services/api';
import { getMenuList } from './MenuActions';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_CHART = 'GET_DASHBOARD_CHART';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const GET_TIMEZONES = 'GET_TIMEZONES';

// const setHeader = (getState) => {
//   API.defaults.headers.common['Authorization'] = localStorage.getItem(
//     'jwt_token'
//   );

//   console.log('TOKENNNNN', getState().auth)
  
//   if (getState().auth.activeRestaurant && getState().auth.activeRestaurant.id) {
//     console.log('ACTIVE YES', getState().auth.activeRestaurant)
//     API.defaults.headers.common['restaurant-id'] = getState().auth.activeRestaurant.id;
//   }
// }

export const getTimeZones = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/timezones`)
    .then((res) => {
      // console.log('COMMON ACTION:', res.data);
      dispatch({
        type: GET_TIMEZONES,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getDashboardData = () => async (dispatch, getState) => {
  console.log('GETTING DASHBOARD');
  // API.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  let range = '';
  const { dateRange } = getState().common;
  if (dateRange.start && dateRange.end) {
    range = `?from=${dateRange.start}&to=${dateRange.end}`;
  }
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/dashboard${range}`)
    .then((res) => {
      // console.log('DASHBOARD DATA:', res.data);
      dispatch({
        type: GET_DASHBOARD_DATA,
        payload: res.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getDashboardChart = () => async (dispatch, getState) => {
  // console.log('GETTING DASHBOARD');
  const api = getApi(getState);
  let range = '';
  const { dateRange } = getState().common;
  if (dateRange.start && dateRange.end) {
    range = `?from=${dateRange.start}&to=${dateRange.end}`;
  }
  // console.log('RANGE:', range);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/chart/main${range}`)
    .then((res) => {
      // console.log('DASHBOARD CHART:', res.data);
      dispatch({
        type: GET_DASHBOARD_CHART,
        payload: res.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const setDateRange = (dateRange) => (dispatch) => {
  return dispatch({
    type: SET_DATE_RANGE,
    payload: dateRange
  });
};

export const statusChange = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/change-status`, data)
    .then((res) => {
      console.log('COMMON ACTION:', res.data);
      // dispatch(getMenuList());
      // dispatch({
      //   type: GET_DASHBOARD_CHART,
      //   payload: res.data,
      // });

      const moduleName =
        data.module.charAt(0).toUpperCase() + data.module.slice(1);
      const status = data.value === 1 ? ' enabled' : ' disabled';
      const variant = data.value === 1 ? 'success' : 'warning';

      const message = `${moduleName} ${status}`;
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: variant
          }
        })
      );

      return true;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const positionChange = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/change-position`, data)
    .then((res) => {
      console.log('COMMON ACTION:', res.data);
      // dispatch({
      //   type: GET_DASHBOARD_CHART,
      //   payload: res.data,
      // });

      // const moduleName =
      //   data.module.charAt(0).toUpperCase() + data.module.slice(1);
      // const status = data.value === 1 ? ' enabled' : ' disabled';
      // const variant = data.value === 1 ? 'success' : 'warning';

      const message = `Item position updated.`;
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};
