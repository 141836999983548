import React from 'react';
import { Breadcrumb } from 'matx';
import { Card, Button, Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { zoneSave } from 'app/redux/actions/ZoneActions';
import ZoneForm from './shared/ZoneForm';

const ZoneAdd = () => {
  const [redirect, setRedirect] = React.useState(null);
  const dispatch = useDispatch();
  const submitZone = async (data) => {
    const zone = await dispatch(zoneSave(data));
    console.log('MM:', zone);
    if (zone.zone && zone.zone.id) {
      setRedirect(`/zones/edit/${zone.zone.id}`);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Zones', path: '/zones/list' },
            { name: 'Add' }
          ]}
        />
        <div className="">
          <Button
            component={Link}
            to="/zones/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      <Card className="px-6 py-6">
        <ZoneForm onSave={(data) => submitZone(data)} />
      </Card>
    </div>
  );
};
export default ZoneAdd;
