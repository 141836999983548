/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { useDispatch } from 'react-redux';

import PaymentLinksTable from './shared/PaymentLinksTable';
import { getPaymentLinkList } from 'app/redux/actions/OutletActions';
import { Button, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';

const PaymentLinks = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getPaymentLinkList());
  }, []);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'PaymentLinks', path: '/payment-links/list' },
            { name: 'List' }
          ]}
        />
        
        <div className="">
          <Button
            component={Link}
            to="/payment-links/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      <PaymentLinksTable />
    </div>
  );
};

export default PaymentLinks;
