import React from "react";
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import Users from "./Users";

const userRoutes = [
  {
    path: "/users/list",
    // component: React.lazy(() => import("./Users")),
    component: Users,
  },
  {
    path: "/users/add",
    // component: React.lazy(() => import("./UserAdd")),
    component: UserAdd,
  },
  {
    path: "/users/edit/:id",
    // component: React.lazy(() => import("./UserEdit")),
    component: UserEdit,
  },
];

export default userRoutes;
