/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Card,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  //   Checkbox,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector, useDispatch } from 'react-redux';
import {deleteMenu} from 'app/redux/actions/MenuActions';
import DeleteIcon from '@material-ui/icons/Delete';
import { Redirect } from 'react-router-dom';

const MenuDelete = ({ editable }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const { menuDetails } = useSelector((state) => state.menu);

  const onDeleteMenu = async () => {
    // console.log('SEL:', id);
    const deleted = await dispatch(deleteMenu(menuDetails.id));

    if (deleted.message) {
        setRedirect('/menus/list');
    }
  };


  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (!menuDetails.id) {
    return <CircularProgress />;
  }

  return (
    <Card className="px-6 pt-2 pb-4 my-4" style={{overflow: 'visible'}}>
      <Box className="pt-2 pb-4">
        <div className="card-title flex items-center justify-center">
        <span className="mr-4">Delete Menu</span>
            <IconButton
                aria-label="Delete"
                onClick={() => setOpen(true)}
            >
                <DeleteIcon fontSize="default" />
            </IconButton>
        </div>
      </Box>
      <Dialog
        open={open}
        // onClose={setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Menu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this menu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDeleteMenu();
              setOpen(false);
            }}
            color="primary"
          >
            Yes, Delete.
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default MenuDelete;
