import React from "react";
import PaymentLinkAdd from "./PaymentLinkAdd";
import PaymentLinkEdit from "./PaymentLinkEdit";
import PaymentLinks from "./PaymentLinks";

const PaymentLinkRoutes = [
  {
    path: "/payment-links/list",
    // component: React.lazy(() => import("./PaymentLinks")),
    component: PaymentLinks,
  },
  {
    path: "/payment-links/add",
    // component: React.lazy(() => import("./PaymentLinkAdd")),
    component: PaymentLinkAdd,
  },
  {
    path: "/payment-links/edit/:id",
    // component: React.lazy(() => import("./PaymentLinkEdit")),
    component: PaymentLinkEdit,
  },
];

export default PaymentLinkRoutes;
