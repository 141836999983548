import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
// import { DropzoneArea } from "material-ui-dropzone";
import {
  categorySave,
  getCategoriesItems,
  linkCategoryMenu
} from 'app/redux/actions/MenuActions';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close } from '@material-ui/icons';
import moment from 'moment';
import Select2 from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const CategoryMenuLink = ({ categoryId, onSave, closeForm }) => {
  const [form, setForm] = React.useState({});
  const [menuItems, setMenuItems] = React.useState([]);
  const dispatch = useDispatch();

  const { menuList, categoriesItemsList } = useSelector((state) => state.menu);
  // const [image, setImage] = React.useState(null);
  const formRef = React.useRef();

  React.useEffect(() => {
    // console.log('MENUS:', menuList);
    setMenuItems(
      menuList.filter((el) => !categoriesItemsList.some((e) => e.id == el.id))
    );
  }, [menuList, categoriesItemsList]);

  const onItemsChange = (values) => {
    // console.log('VAL:', values);

    if (values && values.length) {
      const selectedItemsList = values.map((value) => value.id);
      setForm({
        category_id: categoryId,
        menu_ids: selectedItemsList
      });
    } else {
      setForm({
        outlet_id: categoryId,
        menu_ids: []
      });
    }
  };

  const submitLinkForm = async () => {
    const assigned = await dispatch(linkCategoryMenu(form));

    if (assigned) dispatch(getCategoriesItems(categoryId));
  };

  return (
    <Card
      className="p-6 my-6 relative"
      style={{ overflow: 'visible', zIndex: 999 }}
    >
      <div className="mb-sm-30 flex items-center justify-between">
        <div className="card-title mb-3">Assign Menu to Category</div>

        <div className="text-right">
          <IconButton
            aria-label="Save"
            type="submit"
            onClick={() => submitLinkForm()}
          >
            <Check fontSize="default" />
          </IconButton>
          {closeForm ? (
            <IconButton aria-label="Cancel" onClick={() => closeForm(false)}>
              <Close fontSize="default" />
            </IconButton>
          ) : null}
        </div>
      </div>
      <div>
        {menuItems && menuItems.length ? (
          <Select2
            // isDisabled={selectedAttributes !== group.id}
            className="w-full my-4"
            closeMenuOnSelect={false}
            components={animatedComponents}
            // defaultValue={initialData.areas}
            isMulti
            options={menuItems}
            getOptionLabel={(option) => `${option.name.en} - ${option.price}`}
            getOptionValue={(option) => option.id}
            onChange={onItemsChange}
            // onBlur={() => submitLinkForm()}
          />
        ) : (
          <p>All menu items are assigned to this category.</p>
        )}
      </div>
    </Card>
  );
};
export default CategoryMenuLink;
