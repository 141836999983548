/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { getLiveOrders } from '../../redux/actions/OrderActions';
import LiveOrderList from './shared/LiveOrderList';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetails from './shared/OrderDetails';

const LiveOrders = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  
  React.useEffect(() => {
    dispatch(getLiveOrders());
    // const interval = setInterval(() => {
    //   dispatch(getLiveOrders());
    // }, 10000);
    // return () => clearInterval(interval);
  }, [dispatch]);

  const { orderDetails } = useSelector((state) => state.order);

  React.useEffect(() => {
    // console.log('ORDER CHANGED:', open);
    if (!orderDetails.id && open) {
      // setOpen(false);
    }
  }, [orderDetails]);

  React.useEffect(() => {
    // console.log('ORDER LOADED:', orderDetails);
    // console.log('TOGGLE 2:', open);
  }, [open]);


  const toggleDetails = () => {
    // console.log('TOGLE:', open);
    setOpen(!open);
  };

  return (
    <div
      className="orders-live h-full"
      // id="print-area"
    >
      <div className="h-full orders-live-container">
        <div className="order-list no-print">
          <LiveOrderList toggleDetails={() => setOpen(true)} />
        </div>
        <div className={`order-details ${open ? 'open' : ''}`}>
          {orderDetails.id ? (
            <OrderDetails
              order={orderDetails}
              toggleDetails={() => setOpen(false)}
            />
          ) : (
            <div className="flex w-full h-full justify-center items-center">
              <p>No order selected</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveOrders;
