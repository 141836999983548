/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { Icon, Button, Card } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import {
  getCategoriesList,
  GET_CATEGORIES_LIST
} from 'app/redux/actions/MenuActions';

import CategoriesTable from '../shared/CategoriesTable';
import CategoryForm from '../shared/CategoryForm';

const Categories = (props) => {
  const dispatch = useDispatch();
  const [showForm, toggleForm] = React.useState(false);
  const [editForm, setEditForm] = React.useState({});

  React.useEffect(() => {
    dispatch({
      type: GET_CATEGORIES_LIST,
      payload: []
    });

    dispatch(getCategoriesList());
  }, []);

  const editCategory = (data) => {
    setEditForm(data);
    toggleForm(true);
  };

  const categorySaved = (toggle = false) => {
    dispatch(getCategoriesList());
    toggleForm(false);
  };

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Menus', path: '/menus/list' },
            { name: 'Categories' }
          ]}
        />
        <div className="">
          <Button
            onClick={() => editCategory({})}
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      {showForm ? (
        <CategoryForm
          closeForm={() => categorySaved()}
          initialData={editForm}
        />
      ) : null}
      <CategoriesTable onEdit={(data) => editCategory(data)} />
    </div>
  );
};

export default Categories;
