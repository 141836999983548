import React from 'react';
import {
  Card,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Switch,
  ListItemSecondaryAction,
  Grid,
  Input,
  TextField,
  IconButton
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSelector, useDispatch } from 'react-redux';
import {
  linkMenuOutlet,
  updateMenuOutletPricing
} from 'app/redux/actions/MenuActions';
import { getOutletsList } from 'app/redux/actions/OutletActions';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';

const MenuDetailsOutletItem = ({ menuDetails, outlet, selected }) => {
  const dispatch = useDispatch();
  // const { menuDetails } = useSelector((state) => state.menu);
  // const { outletsList } = useSelector((state) => state.outlet);
  const { user } = useSelector((state) => state.auth);
  const [selectedOutlets, setSelectedOutlets] = React.useState([]);
  const [editable, setEditable] = React.useState(false);
  const [menuPrice, setMenuPrice] = React.useState({});

  React.useEffect(() => {
    // dispatch(getOutletsList());
    if (menuDetails.outlets) {
      // const outs = menuDetails.outlets.map((el) => Number(el.id));
      //   console.log("OKK", outs);
      // setSelectedOutlets(outs);
    }

    if (menuDetails.id && outlet && outlet.outlet_id) {
      // console.log('HAS:')
      const price = {
        outlet_id: outlet.outlet_id,
        menu_id: menuDetails.id,
      };

      if (outlet.price) price.price = outlet.price;
      if (outlet.sales_price) price.sales_price = outlet.sales_price;

      setMenuPrice(price);
    }
  }, [dispatch, menuDetails, outlet]);

  // React.useEffect(() => {
  //   console.log('MENU PRICE :', menuPrice);
  // }, [menuPrice]);

  const onPriceChange = (key, value) => {
    // console.log('CHANGE:', key, value);
    const prevState = menuPrice;
    prevState[key] = value;
    // console.log('PREV:', prevState);
    setMenuPrice(prevState);
    // setMenuPrice(prevState => ({
    //   ...prevState,
    //   [key]: value
    // }));
  };

  const handleOutletSelect = (event) => {
    // console.log('CL', event.target.value, selectedOutlets);
    let locations = selectedOutlets;
    let check = event.target.checked;
    let checked = Number(event.target.value);
    if (check) {
      setSelectedOutlets([...locations, checked]);
      dispatch(
        linkMenuOutlet({
          outlet_id: checked,
          menu_id: menuDetails.id,
          action: 'link'
        })
      );
    } else {
      setSelectedOutlets(locations.filter((cat) => Number(cat) !== checked));
      dispatch(
        linkMenuOutlet({
          outlet_id: checked,
          menu_id: menuDetails.id,
          action: 'unlink'
        })
      );
    }
  };

  const handlePriceSave = (event) => {
    // console.log('CL', event.target.value, selectedOutlets);
    // let locations = selectedOutlets;
    // let check = event.target.checked;
    // let checked = Number(event.target.value);
    // if (check) {
    // setSelectedOutlets([...locations, checked]);
    // console.log('MENU PRICE2 :', menuPrice);
    dispatch(updateMenuOutletPricing(menuPrice));
    setEditable(false);
    // } else {
    //   setSelectedOutlets(locations.filter((cat) => Number(cat) !== checked));
    //   dispatch(
    //     linkMenuOutlet({
    //       outlet_id: checked,
    //       menu_id: menuDetails.id,
    //       action: 'unlink'
    //     })
    //   );
    // }
  };

  if (!menuDetails.id) {
    return <CircularProgress />;
  }

  return (
    <ListItem key={`${outlet.id}`}>
      <Grid container spacing={1}>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <p>{outlet.name.en}</p>
        </Grid>
        {editable &&
        user &&
        user.role &&
        ['admin', 'store_admin'].includes(user.role) ? (
          <>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <TextField
                variant="outlined"
                label="Custom Menu Price"
                size="small"
                defaultValue={outlet.price}
                onChange={(e) => onPriceChange('price', e.target.value)}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <TextField
                variant="outlined"
                label="Custom Menu Sale Price"
                size="small"
                defaultValue={outlet.sales_price}
                onChange={(e) => onPriceChange('sales_price', e.target.value)}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <IconButton
                aria-label="Save"
                size="small"
                onClick={(e) => handlePriceSave(e)}
                className="mr-2"
              >
                <CheckIcon fontSize="default" />
              </IconButton>
              <IconButton
                aria-label="Cancel"
                size="small"
                onClick={() => setEditable(false)}
                className="mr-2"
              >
                <CloseIcon fontSize="default" />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <p>
                Outlet Price:{' '}
                <strong>{outlet.price ? outlet.price : 'N/A'}</strong>
              </p>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <p>
                Sale Price:{' '}
                <strong>
                  {outlet.sales_price ? outlet.sales_price : 'N/A'}
                </strong>
              </p>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              {user &&
              user.role &&
              ['admin', 'store_admin'].includes(user.role) ? (
                <IconButton
                  aria-label="Save"
                  size="small"
                  onClick={() => setEditable(true)}
                  className="mr-2"
                >
                  <Edit fontSize="small" />
                </IconButton>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          onChange={handleOutletSelect}
          value={outlet.outlet_id}
          defaultChecked={outlet.active}
          // checked={selectedOutlets.includes(Number(outlet.id))}
          inputProps={{
            'aria-labelledby': 'switch-list-label-wifi'
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MenuDetailsOutletItem;
