/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ZoneFormDetails from './ZoneFormDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const ZoneForm = ({ onSave, initialData }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <ZoneFormDetails initialData={initialData} onSave={onSave} />
      </div>
    </div>
  );
};
export default ZoneForm;
