import React from "react";
import { Breadcrumb } from "matx";
import { Card } from "@material-ui/core";
// import { useDispatch } from "react-redux";
// import { Redirect } from "react-router-dom";

// import { menuSave } from "app/redux/actions/MenuActions";
import MenuForm from "../shared/MenuForm";

const MenuAdd = () => {
  // const [redirect, setRedirect] = React.useState(null);
  // const dispatch = useDispatch();
  //   const [open, setOpen] = React.useState(false);
  //   React.useEffect(() => {
  //     setMenus(props.getMenuList());
  //   }, []);
  // const submitMenu = async (data) => {
  //   const menu = await dispatch(menuSave(data));
  //   console.log("MM:", menu);
  //   if (menu && menu.item && menu.item.id) {
  //     setRedirect("/menus/list");
  //   }
  // };

  // if (redirect) {
  //   return <Redirect to={redirect} />;
  // }

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: "Menus", path: "/menus/list" },
            { name: "Add Menu" },
          ]}
        />
      </div>
      <Card className="px-6 py-6">
        <MenuForm 
        // onSave={(data) => submitMenu(data)} 
        />
      </Card>
    </div>
  );
};
export default MenuAdd;
