import React from "react";
import { Breadcrumb } from "matx";

import { getOrders } from "../../redux/actions/OrderActions";
import OrderTable from "./shared/OrderTable";
import { useDispatch } from "react-redux";

const Orders = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[{ name: "Orders", path: "/orders/list" }]}
        />
        <div className=""></div>
      </div>
      <OrderTable />
    </div>
  );
  // }
};
export default Orders;
