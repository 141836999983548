/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Select2 from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLocationsList,
  linkOutletAreas
} from 'app/redux/actions/OutletActions';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const OutletFormDeliveryAreas = ({ initialData }) => {
  const dispatch = useDispatch();
  const { locationsList } = useSelector((state) => state.outlet);
  const [editAreasForm, setEditAreasForm] = React.useState({});

  React.useEffect(() => {
    dispatch(getLocationsList());
  }, []);

  const onAreasChange = (values) => {
    // console.log('VAL:', values);

    if (values && values.length) {
      const selectedAreasList = values.map((value) => value.id);
      setEditAreasForm({
        outlet_id: initialData.id,
        area_ids: selectedAreasList
      });
    } else {
      setEditAreasForm({
        outlet_id: initialData.id,
        area_ids: []
      });
    }
  };

  const submitAreasForm = () => {
    dispatch(linkOutletAreas(editAreasForm));
  };

  return (
    <div>
      <div className="z4" style={{ zIndex: 999 }}>
        <h5>Deliverable Areas</h5>
        {locationsList && locationsList.length ? (
          <Select2
            // isDisabled={selectedAttributes !== group.id}
            className="w-full my-4"
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={initialData.areas}
            isMulti
            options={locationsList}
            getOptionLabel={(option) =>
              `${option.name_en} - ${option.parent_name}`
            }
            getOptionValue={(option) => option.id}
            onChange={onAreasChange}
            onBlur={() => submitAreasForm()}
          />
        ) : null}
      </div>
    </div>
  );
};
export default OutletFormDeliveryAreas;
