import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

// import moment from "moment";
import { useSelector } from 'react-redux';

const CustomersTable = () => {
  const { customers } = useSelector((state) => state.customer);
  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="card-title mb-3">Customers</div>
      <div className="overflow-auto">
        {customers.length ? (
          <Table className="menu__table">
            <TableHead>
              <TableRow>
                <TableCell className="px-1">
                  Name
                </TableCell>
                <TableCell className="px-1">
                  Email
                </TableCell>
                <TableCell className="px-1">
                  Mobile
                </TableCell>
                {/* <TableCell className="px-0">
                  Action
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((user, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="px-0 capitalize"
                   
                    align="left"
                  >
                    <div className="flex items-center">
                      {/* {user.image && user.image.thumb ? (
                        <img
                          className="img"
                          src={user.image.thumb}
                          alt="user"
                        />
                      ) : null} */}
                      <p className="m-0 ml-8">
                        {user.name}
                        <span className="block">{user.address}</span>
                      </p>
                    </div>
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {user.mobile}
                  </TableCell>
                  {/* <TableCell className="px-0">
                    <IconButton href={`/users/edit/${user.id}`}>
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <Switch
                      value={user.id}
                      defaultChecked={user.status === "active"}
                      onChange={changeStatus}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No users found...</div>
        )}
      </div>
    </Card>
  );
};

export default CustomersTable;
