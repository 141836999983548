import React from 'react';
import { Breadcrumb } from 'matx';
import { Icon, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getMenuDetails,
  getAttributesList
} from 'app/redux/actions/MenuActions';
import { getOutletsList } from 'app/redux/actions/OutletActions';

import MenuDetailsView from '../shared/MenuDetailsView';
import MenuDetailsOutlets from '../shared/MenuDetailsOutlets';
import MenuDetailsModifiers from '../shared/MenuDetailsModifiers';
import MenuDelete from '../shared/MenuDelete';

const MenuDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [editable, setEditable] = React.useState(false);

  React.useEffect(() => {
    dispatch(getMenuDetails(id));
    dispatch(getAttributesList());
    dispatch(getOutletsList());

    if (
      localStorage.getItem('auth_user') &&
      (JSON.parse(localStorage.getItem('auth_user')).role === 'admin' || JSON.parse(localStorage.getItem('auth_user')).role === 'store_admin')
    ) {
      setEditable(true);
    }
  }, [dispatch, id]);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Menus', path: '/menus/list' },
            { name: 'Menus' }
          ]}
        />
        <div className="">
          <Button
            href="/menus/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      <MenuDetailsView editable={editable} />
      {editable && <MenuDetailsOutlets editable={editable} />}
      <MenuDetailsModifiers editable={editable} />
      <MenuDelete editable={editable} />
    </div>
  );
};

export default MenuDetails;
