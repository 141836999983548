/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';

import { getTimeZones } from 'app/redux/actions/CommonActions';
import OutletFormDetails from './OutletFormDetails';
import OutletFormDelivery from './OutletFormDelivery';
import OutletFormPickup from './OutletFormPickup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="py-4"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

const OutletForm = ({ onSave, initialData }) => {
  const dispatch = useDispatch();
  // const [vatType, setVatType] = React.useState(1);
  // const [defaultTimeZone, setDefaultTimeZone] = React.useState(1);
  // const [form, setForm] = React.useState({
  //   name_en: '',
  //   name_ar: '',
  //   address_en: '',
  //   address_ar: '',
  //   phone_1: '',
  //   mobile: '',
  //   inclusive_tax: 1,
  //   tax_rate: 0,
  //   timezone_id: 1
  // });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    dispatch(getTimeZones());
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleImageSelect = (files) => {
  //   // event.persist();
  //   console.log('FILES', files.target.files[0]);
  //   setForm({ ...form, image: files.target.files[0] });
  // };

  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="Add Outlet"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Outlet Information" {...a11yProps(0)} />
            <Tab
              label="Delivery Details"
              {...a11yProps(1)}
              disabled={!initialData}
            />
            <Tab
              label="Pickup Details"
              {...a11yProps(2)}
              disabled={!initialData}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <OutletFormDetails initialData={initialData} onSave={onSave} />
        </TabPanel>
        {initialData && initialData.id && (
          <TabPanel value={value} index={1}>
            <OutletFormDelivery initialData={initialData} />
          </TabPanel>
        )}
        {initialData && initialData.id && (
          <TabPanel value={value} index={2}>
            <OutletFormPickup initialData={initialData} />
          </TabPanel>
        )}
      </div>
    </div>
  );
};
export default OutletForm;
