import {
  GET_OUTLET_LIST,
  GET_OUTLET_DETAILS,
  ADD_OUTLET,
  EDIT_OUTLET,
  GET_OUTLET_PICKUP_HOURS,
  GET_OUTLET_DELIVERY_HOURS,
  GET_LOCATIONS_LIST,
  GET_PAYMENT_LINK_LIST,
  GET_PAYMENT_LINK_DETAILS,
} from "../actions/OutletActions";

const initialState = {
  outletsList: [],
  outletDetails: {},
  deliveryHours: [],
  pickupHours: [],
  locationsList: [],
  paymentLinkList: [],
  paymentLinkDetails: {},
};

const OutletReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_OUTLET_LIST: {
      // console.log("OUTLET REDUCER: ", action);
      return {
        ...state,
        outletsList: [...action.payload],
      };
    }
    case GET_OUTLET_DETAILS: {
      // console.log("ORDER REDUCER: ", action.payload);
      return {
        ...state,
        outletDetails: action.payload,
      };
    }
    case ADD_OUTLET: {
      // console.log("MENU ADD REDUCER: ", action);
      return {
        ...state,
        outletsList: [...state.outletsList, action.payload],
        outletDetails: action.payload,
      };
    }
    case EDIT_OUTLET: {
      // console.log("MENU ADD REDUCER: ", action);
      const outlets = state.outletsList.filter(el => el.id !== action.payload.id)
      return {
        ...state,
        outletsList: [...outlets, action.payload],
        outletDetails: action.payload,
      };
    }
    case GET_OUTLET_PICKUP_HOURS: {
      // console.log("MENU ADD REDUCER: ", action);
      return {
        ...state,
        pickupHours: action.payload,
      };
    }
    case GET_OUTLET_DELIVERY_HOURS: {
      // console.log("MENU ADD REDUCER: ", action);
      return {
        ...state,
        deliveryHours: action.payload,
      };
    }
    case GET_LOCATIONS_LIST: {
      // console.log("MENU ADD REDUCER: ", action);
      return {
        ...state,
        locationsList: action.payload,
      };
    }
    case GET_PAYMENT_LINK_LIST: {
      // console.log("MENU ADD REDUCER: ", action);
      return {
        ...state,
        paymentLinkList: action.payload,
      };
    }
    case GET_PAYMENT_LINK_DETAILS: {
      // console.log("MENU ADD REDUCER: ", action);
      return {
        ...state,
        paymentLinkDetails: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default OutletReducer;
