import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Grid,
  FormLabel
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { statusChange } from 'app/redux/actions/CommonActions';
import { outletQuickUpdate } from 'app/redux/actions/OutletActions';

const MenuTable = () => {
  const dispatch = useDispatch();
  const { outletsList } = useSelector((state) => state.outlet);
  const { user } = useSelector((state) => state.auth);

  React.useEffect(() => {
    // console.log('OUTLETS:', outletsList, outletsList.sort((a, b) => a.id - b.id))
  }, [outletsList])
  

  const changeStatus = (e) => {
    const data = {
      module: 'outlet',
      ref_id: e.target.value,
      value: e.target.checked ? 1 : 0
    };
    dispatch(statusChange(data));
  };

  const pauseStatus = (outlet, key, value) => {
    const data = { outlet_id: outlet.id, key: key, value: value };
    dispatch(outletQuickUpdate(data));
  };

  return (
    <div>
      <div className="card-title mb-3">Outlets</div>
      <p>
        You can pause the delivery and pickup when kitchen is busy by toggling
        the respective switches.
      </p>
      {outletsList.length ? (
        <div>
          {outletsList.sort((a, b) => a.name.en && a.name.en.localeCompare(b.name.en)).map((outlet, index) => (
            <Card className="px-6 pt-2 pb-4 my-4">
              <Grid container>
                <Grid item xs={12} md={8}>
                  <div className="flex h-full items-center">
                    {outlet.image && outlet.image.thumb ? (
                      <img
                        className="img"
                        src={outlet.image.thumb}
                        alt="user"
                      />
                    ) : null}
                    <p className="m-0 ml-8">
                      {outlet.name.en}
                      <span className="block">{outlet.address.en}</span>
                    </p>
                  </div>
                </Grid>
                <Grid item container xs={12} md={4}>
                  <Grid
                    item
                    xs={3}
                    className="flex-column items-center justify-center"
                  >
                    <small>Delivery</small>
                    <div
                      className="flex flex-column justify-center"
                      style={{ height: 50 }}
                    >
                      <Switch
                        value={outlet.id}
                        defaultChecked={!outlet.delivery_paused}
                        onChange={() =>
                          pauseStatus(
                            outlet,
                            'delivery_paused',
                            !outlet.delivery_paused
                          )
                        }
                        color="primary"
                        name="checkedB"
                        size="small"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                    {/* <IconButton
                      onClick={() => pauseStatus(outlet, 'delivery_paused', !outlet.delivery_paused)}
                      style={{height: 50}}
                    >
                      <Icon color={outlet.delivery_paused ? "primary" : "disabled"}>pause</Icon>
                    </IconButton> */}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="flex-column items-center justify-center"
                  >
                    <small>Pickup</small>
                    <div
                      className="flex flex-column justify-center"
                      style={{ height: 50 }}
                    >
                      <Switch
                        value={outlet.id}
                        defaultChecked={!outlet.pickup_paused}
                        onChange={() =>
                          pauseStatus(
                            outlet,
                            'pickup_paused',
                            !outlet.pickup_paused
                          )
                        }
                        color="primary"
                        name="checkedB"
                        size="small"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                    {/* <IconButton
                      onClick={() => pauseStatus(outlet, 'pickup_paused', !outlet.pickup_paused)}
                      style={{height: 50}}
                    >
                      <Icon color={outlet.pickup_paused ? "error" : "disabled"}>pause</Icon>
                    </IconButton> */}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="flex-column items-center justify-center"
                  >
                    <small>Active</small>
                    <div
                      className="flex flex-column justify-center"
                      style={{ height: 50 }}
                    >
                      <Switch
                        value={outlet.id}
                        defaultChecked={outlet.status === 'active'}
                        onChange={changeStatus}
                        color="primary"
                        name="checkedB"
                        size="small"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                  </Grid>
                  {user &&
                  user.role &&
                  ['admin', 'store_admin'].includes(user.role) ? (
                    <Grid
                      item
                      xs={3}
                      className="flex-column items-center justify-center"
                    >
                      <small>Edit</small>
                      <IconButton
                        component={RouterLink}
                        to={`/outlets/edit/${outlet.id}`}
                        style={{ height: 50 }}
                      >
                        <Icon color="primary">edit</Icon>
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          ))}
        </div>
      ) : (
        <div>No outlets found...</div>
      )}
    </div>
  );
  // return (
  //   <Card className="px-6 pt-2 pb-4">
  //     <div className="card-title mb-3">Outlets</div>
  //     <div className="overflow-auto">
  //       {outletsList.length ? (
  //         <Table className="menu__table">
  //           <TableHead>
  //             <TableRow>
  //               <TableCell className="px-1">Name</TableCell>
  //               <TableCell className="px-0 text-right">Delivery</TableCell>
  //               <TableCell className="px-0 text-right">Pickup</TableCell>
  //               <TableCell className="px-0 text-right">Edit</TableCell>
  //               <TableCell className="px-0 text-right">Action</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {outletsList.map((outlet, index) => (
  //               <TableRow key={index}>
  //                 <TableCell className="px-0 capitalize" align="left">
  //                   <div className="flex items-center">
  //                     {outlet.image && outlet.image.thumb ? (
  //                       <img
  //                         className="img"
  //                         src={outlet.image.thumb}
  //                         alt="user"
  //                       />
  //                     ) : null}
  //                     <p className="m-0 ml-8">
  //                       {outlet.name_en}
  //                       <span className="block">{outlet.address_en}</span>
  //                     </p>
  //                   </div>
  //                 </TableCell>
  //                 <TableCell className="px-0 text-right">
  //                   <IconButton
  //                     component={RouterLink}
  //                     to={`/outlets/edit/${outlet.id}`}
  //                   >
  //                     <Icon color="primary">pause</Icon>
  //                   </IconButton>
  //                 </TableCell>
  //                 <TableCell className="px-0 text-right">
  //                   <IconButton
  //                     component={RouterLink}
  //                     to={`/outlets/edit/${outlet.id}`}
  //                   >
  //                     <Icon color="primary">pause</Icon>
  //                   </IconButton>
  //                 </TableCell>
  //                 <TableCell className="px-0 text-right">
  //                   <IconButton
  //                     component={RouterLink}
  //                     to={`/outlets/edit/${outlet.id}`}
  //                   >
  //                     <Icon color="primary">edit</Icon>
  //                   </IconButton>
  //                 </TableCell>
  //                 <TableCell className="px-0 text-right">
  //                   <Grid container>
  //                     <Grid>
  //                       <IconButton
  //                         component={RouterLink}
  //                         to={`/outlets/edit/${outlet.id}`}
  //                       >
  //                         <Icon color="primary">edit</Icon>
  //                       </IconButton>
  //                     </Grid>
  //                     <Grid
  //                       style={{ flexDirection: 'column', display: 'flex' }}
  //                     >
  //                       <IconButton
  //                         component={RouterLink}
  //                         to={`/outlets/edit/${outlet.id}`}
  //                       >
  //                         <Icon color="primary">pause</Icon>
  //                       </IconButton>
  //                     </Grid>
  //                     <Grid
  //                       style={{ flexDirection: 'column', display: 'flex' }}
  //                     >
  //                       <IconButton
  //                         component={RouterLink}
  //                         to={`/outlets/edit/${outlet.id}`}
  //                       >
  //                         <Icon color="primary">pause</Icon>
  //                       </IconButton>
  //                     </Grid>
  //                     <Grid
  //                       style={{ flexDirection: 'column', display: 'flex' }}
  //                     >
  //                       <FormLabel>Active</FormLabel>
  //                       <Switch
  //                         value={outlet.id}
  //                         defaultChecked={outlet.status === 'active'}
  //                         onChange={changeStatus}
  //                         color="primary"
  //                         name="checkedB"
  //                         size="small"
  //                         inputProps={{ 'aria-label': 'primary checkbox' }}
  //                       />
  //                     </Grid>
  //                   </Grid>
  //                 </TableCell>
  //               </TableRow>
  //             ))}
  //           </TableBody>
  //         </Table>
  //       ) : (
  //         <div>No outlets found...</div>
  //       )}
  //     </div>
  //   </Card>
  // );
};

export default MenuTable;
