import React from 'react';
import { Breadcrumb } from 'matx';
import { Card, Button, Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import LoyaltyForm from './shared/LoyaltyForm';
import { getLoyaltyEarned, loyaltySave } from 'app/redux/actions/LoyaltyActions';

const LoyaltyAdd = () => {
  const [redirect, setRedirect] = React.useState(null);
  const dispatch = useDispatch();
  const submitLoyalty = async (data) => {
    const loyalty = await dispatch(loyaltySave(data));
    // console.log('MM:', loyalty);
    if (loyalty && loyalty.id) {
      await dispatch(getLoyaltyEarned());
      setRedirect(`/loyalty/list`);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Loyalty', path: '/loyalty/list' },
            { name: 'Add' }
          ]}
        />
        <div className="">
          <Button
            component={Link}
            to="/loyalty/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      <Card className="px-6 py-6">
        <LoyaltyForm onSave={(data) => submitLoyalty(data)} />
      </Card>
    </div>
  );
};
export default LoyaltyAdd;
