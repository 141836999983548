import axios from "axios";
import localStorageService from "./localStorageService";

class JwtAuthService {
  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  // http://localhost:8001/api/login
  loginWithEmailAndPassword = (username, password) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        username,
        password,
      })
      .then(({ data }) => {
        // console.log("LOGIN USER::", data);
        // Login successful
        // Save token
        this.setSession(data.token);
        // Set user
        // data.user.role = "ADMIN";
        this.setUser(data.user);
        return data;
      })
      .catch(function (error) {
        console.log(error.response);
        return false;
      });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = () => {
    const token = localStorage.getItem("jwt_token");
    axios.defaults.headers.common["Authorization"] = token;

    // console.log("LOGING IN :", axios.defaults.headers.common);
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/user`)
      .then(({ data }) => {
        // data.user.role = "ADMIN";
        // console.log("CHECK USER:", data);
        // Login successful
        // Save token
        this.setSession(data.token);
        // Set user
        this.setUser(data.user);
        return data;
      })
      .catch(function (error) {
        console.log(error.response);
        return false;
      });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new JwtAuthService();
