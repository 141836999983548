import { GET_CUSTOMER_LIST } from "../actions/CustomerActions";

const initialState = {
  customers: [],
};

const customerReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_LIST: {
      return {
        ...state,
        customers: [...action.payload],
      };
    }
    default: {
      return state;
    }
  }
};

export default customerReducer;
