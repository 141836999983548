/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button, Icon, Grid } from '@material-ui/core';
import Select2 from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { linkZoneAreas } from 'app/redux/actions/ZoneActions';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const ZoneFormDetails = ({ onSave, initialData }) => {
  const dispatch = useDispatch();
  const { locationsList } = useSelector((state) => state.zone);
  const formRef = React.useRef();
  const [editAreasForm, setEditAreasForm] = React.useState({});
  const [form, setForm] = React.useState({
    zone_name: '',
    delivery_fee: 0,
    minimum_order: 0,
    delivery_time: 0,
    area: []
  });

  React.useEffect(() => {
    console.log('FORM 0::', form);
    return () => {
      setForm({});
      console.log('FORM 1::', form);
    };
  }, []);

  React.useEffect(() => {
    if (initialData) {
      setForm({
        id: initialData.id,
        zone_name: initialData.zone_name,
        delivery_fee: initialData.delivery_fee,
        minimum_order: initialData.minimum_order,
        delivery_time: initialData.delivery_time,
        area: initialData.area
      });

      console.log('AREA::', initialData.area);
    }
    return () => {
      setForm({});
      // ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [initialData]);

  const handleSubmit = () => {
    onSave(form);
  };

  const handleChange = (event) => {
    // event.persist();
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onAreasChange = (values) => {
    console.log('VAL:', values);

    if (values && values.length) {
      const selectedAreasList = values.map((value) => value.id);
      setEditAreasForm({
        zone_id: initialData.id,
        area_id: selectedAreasList
      });
    }
    else {
      setEditAreasForm({
        zone_id: initialData.id,
        area_id: []
      });
    }
  };

  const submitAreasForm = () => {
    dispatch(linkZoneAreas(editAreasForm));
  };

  return (
    <div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h5 component="legend">General Information</h5>
            <TextValidator
              className="mb-4 w-full"
              label="Zone Name"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="zone_name"
              value={form.zone_name || ''}
              validators={['required']}
              errorMessages={['Zone name is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Delivery Fee"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="delivery_fee"
              value={form.delivery_fee || 0}
              validators={['required']}
              errorMessages={['Delivery fee is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Minimum Order"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="minimum_order"
              value={form.minimum_order || 0}
              validators={['required']}
              errorMessages={['Minimum order is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Delivery Time"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="delivery_time"
              value={form.delivery_time || 0}
              validators={['required']}
              errorMessages={['Delivery time is required']}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div>
              <h5>Deliverable Areas</h5>
              {form.id ? (
                  <Select2
                // {/* locationsList && locationsList.length ? ( */}
                    className="w-full my-4"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={form.area}
                    isMulti
                    options={locationsList}
                    getOptionLabel={(option) =>
                      `${option.name_en} - ${option.parent_name}`
                    }
                    getOptionValue={(option) => option.id}
                    onChange={onAreasChange}
                    onBlur={() => submitAreasForm()}
                // {/* ) : null */}
                  />
              ) : (
                <div>Save Zone details first to add areas.</div>
              )}
            </div>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="my-3"
          size="large"
        >
          <Icon>send</Icon>
          <span className="pl-2 capitalize">Save</span>
        </Button>
      </ValidatorForm>
    </div>
  );
};
export default ZoneFormDetails;
