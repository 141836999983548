/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  TextField,
  FormHelperText
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from "prop-types";

import {
  outletQuickUpdate,
} from 'app/redux/actions/OutletActions';
import IOSSwitch from 'app/components/IOSSwitch';
import OutletFormHours from './OutletFormHours';

const OutletFormPickup = ({ initialData }) => {
  const [lastValue, setLastValue] = React.useState(null);
  const dispatch = useDispatch();
  const { pickupHours } = useSelector((state) => state.outlet);
  const itemsRef = React.useRef([]);
  const [form, setForm] = React.useState({
    has_pickup: false,
    pay_online: false,
    cash_on_pickup: false,
    card_on_pickup: false,
    pickup_fee: 0,
    preparation_time: 0,
    pickup_min_order: 0,
    pickup_alert: 0
  });

  React.useEffect(() => {
    if (initialData) {
      setForm({
        id: initialData.id,
        has_pickup: initialData.has_pickup,
        pay_online: initialData.pay_online,
        cash_on_pickup: initialData.cash_on_pickup,
        card_on_pickup: initialData.card_on_pickup,
        pickup_fee: initialData.pickup_fee || 0,
        preparation_time: initialData.preparation_time || 0,
        pickup_min_order: initialData.pickup_min_order || 0,
        pickup_alert: initialData.pickup_alert
      });
    }
  }, [initialData]);

  React.useEffect(() => {
    if (pickupHours.length) {
      itemsRef.current = itemsRef.current.slice(0, pickupHours.length);
    }
    return () => {};
  }, [pickupHours]);

  const quickUpdate = (el) => {
    if (el.target.value !== lastValue) {
      const data = { outlet_id: initialData.id, key: el.target.name };
      if (el.target.type === 'checkbox') {
        // console.log('IS CHECKBOX');
        data.value = el.target.checked;
      } else {
        data.value = el.target.value;
      }
      // console.log('QU:', (el.target.name, data.value));
      // setForm({ ...form, [el.target.name]: data.value });
      dispatch(outletQuickUpdate(data));
    }
  };

  const onChange = (el) => {
    setForm({ ...form, [el.target.name]: el.target.value });
  };

  return (
    <div>
      <h5 component="legend">Pickup Details</h5>
      <div className="mb-4">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={form.has_pickup ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="has_pickup"
            />
          }
          label="Pickup Available"
        />
      </div>
      <div className="mb-4">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={form.pay_online ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="pay_online"
            />
          }
          label="Pay Online"
        />
      </div>
      <div className="mb-4">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={form.cash_on_pickup ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="cash_on_pickup"
            />
          }
          label="Cash on Pickup"
        />
      </div>
      <div className="mb-4">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={form.card_on_pickup ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="card_on_pickup"
            />
          }
          label="Card on Pickup"
        />
      </div>
      <div className="mb-4">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={form.pickup_alert ? true : false}
              onChange={(e) => quickUpdate(e)}
              name="pickup_alert"
            />
          }
          label="Pickup Alert"
        />
        <FormHelperText>
          Customers will be able to notify you when they have reached your
          outlet.
        </FormHelperText>
      </div>
      <FormGroup className="mt-10">
        <div className="flex items-center justify-start mb-4">
          {/* <TextField
            className="mr-4"
            label="Pickup Fee"
            variant="outlined"
            // onFocus={(e) => console.log(e.target.value)}
            onFocus={(e) => setLastValue(e.target.value)}
            onBlur={(e) => quickUpdate(e)}
            onChange={onChange}
            type="text"
            name="pickup_fee"
            value={form.pickup_fee}
          /> */}
          <TextField
            className="mr-4"
            label="Preparation Time"
            variant="outlined"
            onFocus={(e) => setLastValue(e.target.value)}
            onBlur={(e) => quickUpdate(e)}
            onChange={onChange}
            type="text"
            name="preparation_time"
            value={form.preparation_time}
          />
          <TextField
            className="mr-4"
            label="Minimum Order"
            variant="outlined"
            onFocus={(e) => setLastValue(e.target.value)}
            onBlur={(e) => quickUpdate(e)}
            onChange={onChange}
            type="text"
            name="pickup_min_order"
            value={form.pickup_min_order}
          />
        </div>
      </FormGroup>
      <OutletFormHours service="pickup" initialData={initialData} deliveryHours={pickupHours} />
    </div>
  );
};
export default OutletFormPickup;
