import React from 'react';
import LoyaltyAdd from './LoyaltyAdd';
// import ZoneEdit from './ZoneEdit';
import Loyalty from './Loyalty';

const loyaltyRoutes = [
  {
    path: '/loyalty/list',
    component: Loyalty
  },
  {
    path: '/loyalty/add',
    // component: React.lazy(() => import('./ZoneAdd'))
    component: LoyaltyAdd
  },
  // {
  //   path: '/zones/edit/:id',
  //   // component: React.lazy(() => import('./ZoneEdit'))
  //   component: ZoneEdit
  // }
];

export default loyaltyRoutes;
