import React from "react";
import Customers from "./Customers";

const customerRoutes = [
  {
    path: "/customers/list",
    // component: React.lazy(() => import("./Customers")),
    component: Customers,
  },
];

export default customerRoutes;
