import React from 'react';
import { Breadcrumb } from 'matx';
import { Card, Button, Icon } from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  zoneSave,
  getZoneDetails,
  getLocationsList,
} from 'app/redux/actions/ZoneActions';
import ZoneForm from './shared/ZoneForm';

const ZoneEdit = () => {
  const [loading, setLoading] = React.useState(true);
  const { zoneDetails } = useSelector((state) => state.zone);
  const dispatch = useDispatch();
  const { id } = useParams();

  // React.useEffect(() => {
  //   if (zoneDetails && zoneDetails.id) {
  //     setLoading(false);
  //   }
  // }, [zoneDetails]);

  React.useEffect(() => {
    dispatch(getLocationsList());
  }, []);

  React.useEffect(() => {
    const loadZone = async () => {
      const details = await dispatch(getZoneDetails(id));
      if (details && details.id) {
        setLoading(false);
      }
    }
    loadZone();
  }, [id]);

  const submitZone = async (data) => {
    const zone = await dispatch(zoneSave(data));
    console.log('MM:', zone);
    // if (zone.zone && zone.zone.id) {
    //   setRedirect(`/zones/edit/${zone.id}`);
    // }
  };

  if (loading) return null;

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Zones', path: '/zones/list' },
            { name: 'Edit' }
          ]}
        />
        <div className="">
          <Button
            component={Link}
            to="/zones/add"
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
          >
            Add New
          </Button>
        </div>
      </div>
      <Card className="px-6 py-6">
        <ZoneForm
          onSave={(data) => submitZone(data)}
          initialData={zoneDetails}
        />
      </Card>
    </div>
  );
};
export default ZoneEdit;
