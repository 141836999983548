import '../styles/_app.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import MatxTheme from './MatxLayout/MatxTheme/MatxTheme';
import AppContext from './appContext';
import history from 'history.js';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react'


import routes from './RootRoutes';
import { store, persistor } from './redux/Store';
import Auth from './auth/Auth';
import MatxLayout from './MatxLayout/MatxLayout';
import AuthGuard from './auth/AuthGuard';
import Notifier from './components/Notifier';
import RingTone from './components/RingTone';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={'An error has occured'}>
      <AppContext.Provider value={{ routes }}>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>

          <MatxTheme>
            <SnackbarProvider
              autoHideDuration={4000}
              maxSnack={5}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <Notifier />
              <Auth>
                <Router history={history}>
                  <AuthGuard>
                    <MatxLayout />
                    <RingTone />
                  </AuthGuard>
                </Router>
              </Auth>
            </SnackbarProvider>
          </MatxTheme>
          </PersistGate>
        </Provider>
      </AppContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
