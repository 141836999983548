import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Button,
  Icon,
  FormControl,
  Select,
  MenuItem,
  Box,
  InputLabel
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import toDate from 'date-fns/toDate';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays'

import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
// import { getOutletsList } from 'app/redux/actions/OutletActions';

const PaymentLinkForm = ({ onSave, initialData }) => {
  const dispatch = useDispatch();
  // const { outletsList } = useSelector((state) => state.outlet);
  const { user } = useSelector((state) => state.auth);
  const formRef = React.useRef();
  const [form, setForm] = React.useState({
    name: '',
    mobile: '',
    amount: '',
    note: '',
    expiry_at_d: addDays(new Date(), 1),
    expiry_at: format(addDays(new Date(), 1), 'yyyy-MM-dd HH:mm'),
    outlet_id: '',
    active: 0
  });
  
  React.useEffect(() => {
    // dispatch(getOutletsList());
    
    if (initialData) {
      setForm({
        id: initialData.id,
        name: initialData.name,
        mobile: initialData.mobile,
        amount: initialData.amount,
        note: initialData.note,
        expiry_at: format(initialData.expiry_at, 'yyyy-MM-dd HH:mm'),
        outlet_id: initialData.outlet_id
      });
    }
  }, [dispatch, initialData]);

  React.useEffect(() => {
    // dispatch(getOutletsList());

    if (user.outlets && user.outlets.length && user.outlets.length === 1) {
      setForm({
        ...form,
        outlet_id: user.outlets[0].id
      });
    }
  }, [user]);

  const handleSubmit = (event) => {
    // console.log(form);
    // if (['paymentlink', 'store_admin'].includes('form.role') && form.outlet_id) {
    //   alert('Outlet is required for Store Admin and PaymentLink types');
    //   return;
    // }
    onSave(form);
  };

  const handleChange = (event) => {
    // console.log('INPUT:', event.target.name, event.target.value);
    // event.persist();
    if (event.target.name === 'active') {
      setForm({ ...form, active: event.target.checked ? 1 : 0 });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
    console.log('TEXT:', form);
  };

  const handleExpiryChange = (d) => {
    // event.persist();
    console.log('CHANGE:', d, format(d, 'yyyy-MM-dd HH:mm'));
    setForm({
      ...form,
      expiry_at_d: d,
      expiry_at: format(d, 'yyyy-MM-dd HH:mm')
    });
  };

  return (
    <div>
      <div className="card-title mb-3">Create and Send a Payment Link</div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        {/* <Grid container spacing={6}>
          <Grid item lg={5} md={6} sm={12} xs={12}> */}
        <TextValidator
          className="mb-4 w-full"
          label="Name"
          variant="outlined"
          onChange={handleChange}
          type="text"
          name="name"
          value={form.name || ''}
          validators={['required']}
          errorMessages={['Name is required']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Mobile"
          variant="outlined"
          onChange={handleChange}
          type="text"
          name="mobile"
          value={form.mobile || ''}
          validators={['required']}
          errorMessages={['Mobile is required']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Amount"
          variant="outlined"
          onChange={handleChange}
          type="text"
          name="amount"
          value={form.amount || ''}
          validators={['required']}
          errorMessages={['Amount is required']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Custom Note"
          variant="outlined"
          onChange={handleChange}
          type="text"
          name="note"
          value={form.note || ''}
          validators={['required']}
          errorMessages={['Note field is required']}
        />

        {/* {(user && user.role === 'admin') && ( */}
        <FormControl
          variant="outlined"
          className={`w-full`}
          style={{ minWidth: 100 }}
        >
          <InputLabel id="outlet-label">Outlet</InputLabel>
          <Select
            labelId="outlet-label"
            id="outlet"
            value={form.outlet_id}
            onChange={handleChange}
            name="outlet_id"
            label="Outlet"
            size="small"
          >
            {user.outlets && user.outlets.length ? (
              user.outlets.map((outlet) => (
                <MenuItem key={outlet.id} value={outlet.id}>
                  <span>{outlet.name.en}</span>
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0}>
                <span>Outlet not added</span>
              </MenuItem>
            )}
          </Select>
        </FormControl>
        {/* )} */}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl
            variant="outlined"
            className={`w-full mt-4`}
            style={{ minWidth: 100 }}
          >
            <DateTimePicker
              className="mb-4 w-full"
              label="Link Expiry at"
              inputVariant="outlined"
              value={form.expiry_at_d}
              onChange={(d) => handleExpiryChange(d)}
              format="yyyy-MM-dd HH:mm"
              name="expiry_at_d"
            />
          </FormControl>
        </MuiPickersUtilsProvider>
        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className="my-3"
          >
            <Icon>send</Icon>
            <span className="pl-2 capitalize">Send Payment Link</span>
          </Button>
        </Box>
      </ValidatorForm>
    </div>
  );
};
export default PaymentLinkForm;
