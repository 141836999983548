import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Icon,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';

import {
  getOrderDetails,
  getOrders,
  clearOrderDetails
} from 'app/redux/actions/OrderActions';
import OrderDetails from './OrderDetails';

const OrderTable = () => {
  const { ordersList, orderDetails, ordersPagination } = useSelector(
    (state) => state.order
  );
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [sortOrder, setSortOrder] = React.useState({});
  const [data, setData] = React.useState([['Loading Data...']]);
  const columns = [
    {
      name: 'order_no',
      label: 'Order Id',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return `#${value}`;
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'customer_name',
      label: 'Customer',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'customer_mobile',
      label: 'Phone',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'ordering_service',
      label: 'Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'payment_method',
      label: 'Payment Mode	',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'amount_to_pay',
      label: 'Total',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'channel',
      label: 'Channel',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'ordered_at',
      label: 'Ordered at',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'id',
      label: 'View',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <IconButton onClick={handleClickOpen(value)}>
              <Icon color="primary">pageview</Icon>
            </IconButton>
          );
        }
      }
    }
  ];
  const [isLoading, setLoading] = React.useState(false);

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    // sortOrder: sortOrder,
    sort: true,
    print: false,
    selectableRows: 'none',
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      const filterColumns = [
        'order_no',
        'status',
        'customer_name',
        'customer_phone',
        'type',
        'payment_method',
        'total',
        'channel'
      ];
      const filters = [];

      if (['changePage', 'sort', 'filterChange', 'search'].includes(action)) {
        // console.log('UPDATING');
        tableState.filterList.map((f, i) => {
          if (f[0]) filters.push(`${filterColumns[i]}=${f[0]}`);
          return true;
        });
        changePage(tableState.page, tableState.sortOrder, filters.join('&'));
      }

      // switch (action) {
      //   case 'changePage':
      //     changePage(tableState.page, tableState.sortOrder);
      //     break;
      //   case 'sort':
      //     console.log('SORT');
      //     changePage(tableState.page, tableState.sortOrder);
      //     break;
      //   case 'filterChange':
      //     // console.log('FILTER', tableState.filterList);
      //     tableState.filterList.map((f, i) => {
      //       if (f[0]) filters.push(`${filterColumns[i]}=${f[0]}`);
      //       return true;
      //     });
      //     changePage(tableState.page, tableState.sortOrder, filters.join('&'));
      //     break;
      //   default:
      //     // changePage(tableState.page, tableState.sortOrder);
      //     console.log('action not handled.', action);
      // }
    }
  };

  // React.useEffect(() => {
  //   dispatch(getOrders());
  //   // eslint-disable-next-line
  // }, []);

  React.useEffect(() => {
    setData(ordersList);
    if (ordersPagination) {
      // setPage(ordersPagination.current_page);
      setCount(ordersPagination.total_items);
      setRowsPerPage(ordersPagination.per_page);
    }
  }, [ordersPagination, ordersList]);

  const changePage = async (page, sortOrder, filter, search) => {
    setLoading(true);
    let params = `page=${page + 1}`;
    if (sortOrder && sortOrder.name)
      params += `&sort=${sortOrder.name}&direction=${sortOrder.direction}`;
    if (filter) params += `&${filter}`;
    if (search) params += `&search=${search}`;
    await dispatch(getOrders(params));
    setLoading(false);
    // this.xhrRequest(`/myApiServer?page=${page}`, page, sortOrder).then(
    //   (res) => {
    //     this.setState({
    //       isLoading: false,
    //       page: res.page,
    //       sortOrder,
    //       data: res.data,
    //       count: res.total
    //     });
    //   }
    // );
  };

  const handleClickOpen = (id) => () => {
    // console.log('ID', id);
    dispatch(getOrderDetails(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(clearOrderDetails());
  };

  return (
    <div className="">
      <div>
        <MUIDataTable
          title={
            <Typography variant="h6">
              Orders List
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        // maxWidth={'md'}
        fullWidth
        // style={{width: "100%", maxWidth: 600}}
      >
        <DialogContent>
          <OrderDetails
            order={orderDetails}
            toggleDetails={() => handleClose()}
            hideActions={true}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderTable;
