import React from 'react';
import {
  Card,
  Icon,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@material-ui/core';
// import { Link as RouterLink } from 'react-router-dom';
// import moment from "moment";
import WebsiteOrdering from './WebsiteOrdering';

const MarketingTable = () => {

  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="card-title mb-3">Marketing</div>
      <div className="overflow-auto">
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Website Ordering</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WebsiteOrdering />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
            aria-controls="panel2a-content"
            id="panel2a-header"
            disabled
          >
            <Typography>Facebook (coming soon)</Typography>
          </AccordionSummary>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
            aria-controls="panel3a-content"
            id="panel3a-header"
            disabled
          >
            <Typography>WhatsApp (coming soon)</Typography>
          </AccordionSummary>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
            aria-controls="panel3a-content"
            id="panel3a-header"
            disabled
          >
            <Typography>Google My Business (coming soon)</Typography>
          </AccordionSummary>
        </Accordion>
      </div>
    </Card>
  );
};

export default MarketingTable;
