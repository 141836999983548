import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import { getApi } from 'app/services/api';

export const GET_DISCOUNT_LIST = 'GET_DISCOUNT_LIST';
export const GET_DISCOUNT_DETAILS = 'GET_DISCOUNT_DETAILS';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const EDIT_DISCOUNT = 'EDIT_DISCOUNT';
export const GET_DISCOUNT_DELIVERY_HOURS = 'GET_DISCOUNT_DELIVERY_HOURS';
export const GET_DISCOUNT_PICKUP_HOURS = 'GET_DISCOUNT_PICKUP_HOURS';
export const GET_DISCOUNT_PARAMETERS = 'GET_DISCOUNT_PARAMETERS';

// export const UPDATE_CART_AMOUNT = "UPDATE_CART_AMOUNT";

export const getDiscountsList = () => (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return api
    .get(`${process.env.REACT_APP_API_URL}/api/discounts`)
    .then((res) => {
      // console.log('DISCOUNT ACTION:', res.data);
      dispatch({
        type: GET_DISCOUNT_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);
      showErrorMessage(dispatch, err);
    });
};

export const getDiscountDetails = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return api
    .get(`${process.env.REACT_APP_API_URL}/api/discounts/detail/${id}`)
    .then((res) => {
      // console.log('DISCOUNT DETAILS:', res.data);
      dispatch({
        type: GET_DISCOUNT_DETAILS,
        payload: res.data.discount
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getParameters = () => async (dispatch) => {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem(
    'jwt_token'
  );
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/api/discounts/parameters`)
    .then((res) => {
      // console.log('DISCOUNT DETAILS:', res.data);
      dispatch({
        type: GET_DISCOUNT_PARAMETERS,
        payload: res.data.data
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const discountSave = (data) => (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return api
    .post(`${process.env.REACT_APP_API_URL}/api/discounts/save`, data)
    .then((res) => {
      const message = data.id
        ? 'Discount details updated'
        : 'New delivery discount added';

      // console.log('DATA', res);
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        dispatch({
          type: EDIT_DISCOUNT,
          payload: res.data.discount
        });
      } else {
        dispatch({
          type: ADD_DISCOUNT,
          payload: res.data.discount
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const linkDiscountAreas = (data) => (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return api
    .post(`${process.env.REACT_APP_API_URL}/api/discounts/area-link`, data)
    .then((res) => {
      // console.log('DISCOUNT UPDATED:', res.data);

      const message = `Discount delivery areas updated`;

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      // dispatch({
      //   type: EDIT_DISCOUNT,
      //   payload: res.data.discount,
      // });
    })
    .catch((err) => {
      // console.log('ERROR:', err, err.response);

      // dispatch(
      //   enqueueSnackbar({
      //     message: 'Something went wrong',
      //     options: {
      //       variant: 'error'
      //     }
      //   })
      // );
    });
};
