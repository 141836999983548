import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import RootReducer from "./reducers/RootReducer";

const persistConfig = {
  key: 'root',
  storage,
}

const initialState = {};
const middlewares = [thunk];
let devtools = x => x;

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

// const Store = createStore(
//   RootReducer,
//   initialState,
//   compose(applyMiddleware(...middlewares), devtools)
// );
let store = createStore(
  persistedReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export { store, persistor }

// export default () => {
//   return { store, persistor }
// }