import React from 'react';
// import moment from "moment";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Paper,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  getLiveOrders,
  changeOrderStatus,
  getOrderDetails,
  cancelOrder
} from 'app/redux/actions/OrderActions';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Remove } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { enqueueSnackbar } from 'app/redux/actions/UIActions';

const colors = {
  placed: 'bg-error',
  accepted: 'bg-secondary',
  ready: 'bg-green',
  completed: 'bg-green',
  'in-route': 'bg-green'
};

const OrderStatusButton = ({ order }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [openComplete, setOpenComplete] = React.useState(false);
  const [cancelReason, setCancelReason] = React.useState(null);
  const [preparationTime, setPreparationTime] = React.useState(15);
  const [deliveryPartners, setDeliveryPartners] = React.useState([]);
  const [selectedDeliveryPartner, setSelectedDeliveryPartner] =
    React.useState(null);

  const { user } = useSelector((state) => state.auth);

  React.useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('auth_user'));

    if (user && user.outlets && user.outlets.length) {
      const current_outlet = user.outlets.filter(
        (el) => el.id === order.outlet_id
      )[0];

      // console.log('OUT:::', current_outlet);
      setDeliveryPartners(current_outlet?.delivery_partners);
    }
  }, []);

  let buttonTexts = {};
  let statuses = {};
  let completeStatus = 9;

  if (order.ordering_service.toLowerCase() === 'pickup') {
    statuses = {
      placed: 7,
      accepted: 8,
      ready: 9
    };

    buttonTexts = {
      placed: 'Accept Order',
      accepted: 'Ready To Collect',
      ready: 'Picked Up',
      completed: 'Finished'
    };

    completeStatus = 9;
  } else {
    statuses = {
      placed: 2,
      accepted: 3,
      ready: 4,
      'in-route': 5
    };

    buttonTexts = {
      placed: 'Accept Order',
      accepted: 'Ready To Deliver',
      ready: 'Out for Delivery',
      'in-route': 'Delivered',
      completed: 'Finished'
    };

    completeStatus = 5;
  }

  const onChangeStatus = async (accepting = false, complete = false) => {
    // console.log('Change status');
    setLoading(true);
    if (order.status === 'placed' && !accepting) {
      // console.log('is placed');
      setOpen(true);
      setLoading(false);
    } else {
      // console.log('changing...');
      const statusId = complete ? completeStatus : statuses[order.status];
      if (
        order.ordering_service.toLowerCase() == 'delivery' &&
        deliveryPartners &&
        deliveryPartners.length &&
        selectedDeliveryPartner === null
      ) {
        // console.log('ERRORR');
        dispatch(
          enqueueSnackbar({
            message: 'Please select a Delivery Method',
            options: {
              variant: 'error'
            }
          })
        );
        setLoading(false);
        return false;
      }
      await dispatch(
        changeOrderStatus({
          order_id: order.id,
          status_id: statusId,
          preparation_time: preparationTime,
          delivery_partner_id: selectedDeliveryPartner
        })
      );
      // if (status.status) {
      dispatch(getOrderDetails(order.id));
      dispatch(getLiveOrders());
      setLoading(false);
      // }
      // changeStatus(order.id, statuses[order.status], preparationTime);
    }
  };

  const onCancelOrder = async () => {
    // console.log('Cancel Order');
    setLoading(true);
    await dispatch(
      cancelOrder({
        order_id: order.id,
        reason_id: cancelReason
      })
    );
    // if (status.status) {
    // dispatch(getOrderDetails(order.id));
    dispatch(getLiveOrders());
    setLoading(false);
    // }
    // changeStatus(order.id, statuses[order.status], preparationTime);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenComplete(false);
    setOpenCancel(false);
  };

  const handleReasonChange = (event, value) => {
    // console.log('INPUT:', event, value);
    if (value !== null) {
      setCancelReason(value);
    }
    // setVatType(value);
  };

  if (order.status === 'completed') {
    return (
      <Button
        disabled
        variant="contained"
        size="large"
        fullWidth
        className="btn bg-green text-uppercase f-size-13 bold p-2 btn btn-block btn-primary"
      >
        {buttonTexts[order.status]}
      </Button>
    );
  }
  return (
    <Grid container className="m-0">
      <Grid item xs={12} sm={5} className="">
        <Grid container className="">
          <Grid item xs={12} sm={6} className="p-1">
            <Button
              variant="contained"
              size="large"
              fullWidth
              className="text-uppercase bg-error f-size-13 bold p-2 btn btn-block btn-primary text-white font-bold"
              onClick={() => setOpenCancel(true)}
            >
              Cancel Order
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} className="p-1">
            {order.status !== 'placed' ? (
              <Button
                variant="contained"
                size="large"
                fullWidth
                className="text-uppercase bg-dark f-size-13 bold p-2 btn btn-block text-white font-bold"
                onClick={() => setOpenComplete(true)}
              >
                Completed
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={7} className="p-1">
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={loading}
          className={`btn bg-green text-uppercase f-size-13 bold p-2 btn btn-block btn-primary text-white font-bold ${
            colors[order.status]
          }`}
          onClick={() => onChangeStatus()}
        >
          {buttonTexts[order.status]}
        </Button>
        {/* Preparation Time Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Set Preparation Time</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change Order Preparation Time if needed
            </DialogContentText>
            <Paper component="form">
              <IconButton
                aria-label="minus"
                onClick={() =>
                  setPreparationTime(
                    preparationTime > 5 ? preparationTime - 5 : 5
                  )
                }
              >
                <Remove />
              </IconButton>
              <TextField
                id="outlined-number"
                type="number"
                onChange={(event) =>
                  setPreparationTime(Number(event.target.value))
                }
                value={preparationTime}
                variant="outlined"
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <IconButton
                aria-label="add"
                onClick={() => setPreparationTime(preparationTime + 5)}
              >
                <Add />
              </IconButton>
            </Paper>

            {order.ordering_service.toLowerCase() == 'delivery' &&
              deliveryPartners &&
              deliveryPartners.length && (
                <Paper component="form" className="my-4">
                  <Typography variant="h6" component="h6" className="mb-2">
                    Delivery Method
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      className="text-center"
                      onClick={() => setSelectedDeliveryPartner(0)}
                    >
                      <div
                        style={{
                          backgroundColor: '#efefef',
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor:
                            selectedDeliveryPartner === 0
                              ? 'var(--primary)'
                              : 'var(--bg-paper)',
                          borderRadius: 4,
                          width: 80,
                          height: 80,
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        className="bg-light rounded-sm flex "
                      >
                        SELF
                      </div>

                      <span className="block">SELF</span>
                    </Grid>
                    {deliveryPartners.map((delivery) => (
                      <Grid
                        item
                        key={delivery.id}
                        className="text-center"
                        onClick={() => setSelectedDeliveryPartner(delivery.id)}
                      >
                        <div
                          style={{
                            backgroundColor: '#efefef',
                            border: 1,
                            borderStyle: 'solid',
                            borderColor:
                              selectedDeliveryPartner === delivery.id
                                ? 'var(--primary)'
                                : 'var(--bg-paper)',
                            borderRadius: 4,
                            width: 80,
                            height: 80,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 5
                          }}
                        >
                          <img
                            alt={delivery.name}
                            src={delivery.logo}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        {/* <Avatar
                      alt={delivery.name}
                      src={delivery.logo}
                      variant="rounded"
                    >
                      {delivery.name[0]}
                    </Avatar> */}

                        <span className="block">{delivery.name}</span>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            <Button
              onClick={() => {
                onChangeStatus(true);
                handleClose();
              }}
              color="primary"
            >
              Accept Order
            </Button>
          </DialogActions>
        </Dialog>
        {/* Preparation Time Dialog End */}

        {/* Close Order Dialog */}
        <Dialog
          open={openComplete}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Complete Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about mark this order as completed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            <Button
              onClick={() => {
                onChangeStatus(false, true);
                handleClose();
              }}
              color="primary"
            >
              Complete Order
            </Button>
          </DialogActions>
        </Dialog>
        {/* Preparation Time Dialog End */}

        {/* Cancellation Dialog */}
        <Dialog
          open={openCancel}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Cancel Order #{order.order_no}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Please, select a reason</DialogContentText>
            <Paper component="form">
              <ToggleButtonGroup
                value={cancelReason}
                onChange={handleReasonChange}
                exclusive
                aria-label="Cancel Reason"
              >
                <ToggleButton
                  value={1}
                  aria-label="Out of Delivery Area"
                  size="large"
                >
                  <Typography>Out of Delivery Area</Typography>
                </ToggleButton>
                <ToggleButton value={2} aria-label="Too Busy" size="large">
                  <Typography>Too Busy</Typography>
                </ToggleButton>
                <ToggleButton value={3} aria-label="Out of Stock" size="large">
                  <Typography>Out of Stock</Typography>
                </ToggleButton>
                <ToggleButton
                  value={4}
                  aria-label="Customer Cancelled"
                  size="large"
                >
                  <Typography>Customer Cancelled</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            <Button
              onClick={() => {
                onCancelOrder();
                handleClose();
              }}
              color="primary"
            >
              Cancel Order
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancellation Dialog End */}
      </Grid>
    </Grid>
  );
};

export default OrderStatusButton;
