/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { useDispatch } from 'react-redux';

import { getCategoriesItems, getMenuList } from 'app/redux/actions/MenuActions';

import CategoriesItemsTable from '../shared/CategoriesItemsTable';
import { useParams } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import CategoryMenuLink from '../shared/CategoryMenuLink';

const CategoriesItems = (props) => {
  const [showForm, toggleForm] = React.useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(getCategoriesItems(id));

    //for linking menu with category
    dispatch(getMenuList());
  }, []);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Categories', path: '/menus/categories' },
            { name: 'Items' }
          ]}
        />
        <div className="">
          <Button
            onClick={() => toggleForm(true)}
            variant="contained"
            color="primary"
            endIcon={<Icon>link</Icon>}
          >
            Assign Menu
          </Button>
        </div>
      </div>
      {showForm ? (
        <CategoryMenuLink
          closeForm={(toggle) => toggleForm(toggle)}
          categoryId={id}
        />
      ) : null}
      <CategoriesItemsTable />
    </div>
  );
};

export default CategoriesItems;
