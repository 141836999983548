import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { DropzoneArea } from "material-ui-dropzone";
import { categorySave } from 'app/redux/actions/MenuActions';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close } from '@material-ui/icons';
import moment from 'moment';

const CategoryForm = ({ initialData, onSave, closeForm }) => {
  
  const { user } = useSelector((state) => state.auth);
  const [languages, setLanguages] = React.useState([]);
  
  const [form, setForm] = React.useState({
    name: {},
    name_en: '',
    name_ar: '',
    start: '00:00',
    end: '23:59',
    non_available_start: '00:00',
    non_available_end: '00:00',
    image_type: 'url'
  });
  const [hours, setHours] = React.useState([]);
  const dispatch = useDispatch();
  // const [uploading, setUploading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [initialImage, setInitialImage] = React.useState(null);

  // const [image, setImage] = React.useState(null);
  const formRef = React.useRef();

  React.useEffect(() => {
    if (
      user &&
      user.restaurant &&
      user.restaurant.languages &&
      user.restaurant.languages.length
    ) {
      setLanguages(user.restaurant.languages);
    }
  }, [user]);

  React.useEffect(() => {
    let items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format('HH:mm'));
      items.push(moment({ hour: index, minute: 30 }).format('HH:mm'));
    });
    items.push('23:59');

    setHours(items);
  }, []);

  React.useEffect(() => {
    if (initialData && initialData.id) {
      setForm({
        id: initialData.id,
        name: initialData.name,
        name_en: initialData.name_en,
        name_ar: initialData.name_ar,
        start: initialData.start,
        end: initialData.end,
        non_available_start: initialData.non_available_start,
        non_available_end: initialData.non_available_end,
        image_type: initialData.media_url ? 'url' : 'upload'
      });
      if (initialData.image) {
        setInitialImage(initialData.image);
      }
      else {
        setInitialImage(null);
      }
    }
  }, [initialData]);

  React.useEffect(() => {
    // effect
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, []);

  const handleSubmit = async (event) => {
    // console.log(form);
    // setUploading(true);

    const data = new FormData();
    for (let key in form) {
      if (form.hasOwnProperty(key)) {
        // console.log(key, form[key]); // value (ex. turkey)
        if (key === 'name') {
          for (let k in form[key]) {
            data.append(`${key}[${k}]`, form[key][k]);
          }
        }
        else {
          data.set([key], form[key]);
        }
      }
    }

    if (image) {
      data.append('image', image);
    }

    await dispatch(categorySave(data));
    // console.log('MM:', category);
    closeForm(false);
  };

  const handleChange = (event) => {
    event.persist();
    setForm({ ...form, [event.target.name]: event.target.value });
    if (event.target.name === 'image_type' && event.target.value === 'link')
      setImage(null);
  };

  const handleChangeWithLang = (n, lang, value) => {
    // event.persist();
    const obj = form[n];
    obj[lang] = value;
    setForm({ ...form, [n]: obj });
  };


  const handleImageSelect = (files) => {
    // event.persist();
    // console.log('FILES', files[0]);
    setImage(files[0]);
    if (files[0]) {
      setForm({
        ...form,
        remove_image: 0
      });
    }
  };

  const removeImage = () => {
    setForm({
      ...form,
      remove_image: 1
    });
    setImage(null);
    setInitialImage(null);
  };
  // const handleImageSelect = (files) => {
  //   // event.persist();
  //   console.log("FILES", files[0]);
  //   // console.log("FILES", files.target.files[0]);
  //   setImage(files[0]);
  // };

  return (
    <Card className="p-6 my-6">
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        <div className="mb-sm-30 flex items-center justify-between">
          <div className="card-title mb-3">Add Category</div>

          <div className="text-right">
            <IconButton aria-label="Save" type="submit">
              <Check fontSize="default" />
            </IconButton>
            {closeForm ? (
              <IconButton aria-label="Cancel" onClick={() => closeForm(false)}>
                <Close fontSize="default" />
              </IconButton>
            ) : null}
          </div>
        </div>
        <div>
          <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel component="legend" className="mb-4 mt-2">
                Category Name
              </FormLabel>
              </Grid>
              {languages.length ? (
                languages.map((lang) => (
                <Grid item lg={3} md={3} sm={6} xs={12}>
              <TextValidator
                className="w-full"
                variant="outlined"
                type="text"
                label={`Category Name (${lang.label})`}
                onChange={(e) =>
                  handleChangeWithLang('name', lang.lang, e.target.value)
                }
                name={`name_${lang.lang}`}
                value={form.name[lang.lang] || ''}
                // size="small"
                // validators={['required']}
                // errorMessages={['Category name is required']}
              />
            </Grid>
              ))
            ) : (
            <Grid item lg={3} md={3} sm={6} xs={12}>
              {/* <FormLabel component="legend" className="mb-4 mt-2">
                Category Name (Arabic)
              </FormLabel> */}
              <TextValidator
                className="w-full"
                label="Category Name"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('name', 'en', e.target.value)
                }
                type="text"
                name="name_en"
                // size="small"
                value={form.name.en}
              />
            </Grid>
            )}
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <FormLabel component="legend" className="mb-4 mt-2">
                Available Time (optional)
              </FormLabel>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={`w-full`}
                    style={{ minWidth: 100 }}
                  >
                    <InputLabel id="menu-start-time">Start Time</InputLabel>
                    <Select
                      labelId="menu-start-time"
                      id="menu-start-time"
                      value={form.start}
                      onChange={handleChange}
                      name="start"
                      label="Start Time"
                      size="small"
                    >
                      {hours.length ? (
                        hours.map((h) => (
                          <MenuItem key={`${h}`} value={`${h}`}>
                            {`${h}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={`w-full`}
                    style={{ minWidth: 100 }}
                  >
                    <InputLabel id="menu-end-time">End Time</InputLabel>
                    <Select
                      labelId="menu-end-time"
                      id="menu-end-time"
                      value={form.end}
                      onChange={handleChange}
                      name="end"
                      label="End Time"
                      size="small"
                    >
                      {hours.length ? (
                        hours.map((h) => (
                          <MenuItem key={`${h}`} value={`${h}`}>
                            {`${h}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <FormLabel component="legend" className="mb-4 mt-2">
                Not Available Time (optional)
              </FormLabel>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={`w-full`}
                    style={{ minWidth: 100 }}
                  >
                    <InputLabel id="menu-start-time-not">Start Time</InputLabel>
                    <Select
                      labelId="menu-start-time-not"
                      id="menu-start-time-not"
                      value={form.non_available_start}
                      onChange={handleChange}
                      name="non_available_start"
                      label="Start Time"
                      size="small"
                    >
                      {hours.length ? (
                        hours.map((h) => (
                          <MenuItem key={`${h}`} value={`${h}`}>
                            {`${h}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={`w-full`}
                    style={{ minWidth: 100 }}
                  >
                    <InputLabel id="menu-end-time-not">End Time</InputLabel>
                    <Select
                      labelId="menu-end-time-not"
                      id="menu-end-time-not"
                      value={form.non_available_end}
                      onChange={handleChange}
                      name="non_available_end"
                      label="End Time"
                      size="small"
                    >
                      {hours.length ? (
                        hours.map((h) => (
                          <MenuItem key={`${h}`} value={`${h}`}>
                            {`${h}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormLabel component="legend" className="my-4">
              Image Type
            </FormLabel>
            <div className="block my-4">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Image Type"
                  name="image_type"
                  onChange={handleChange}
                  value={`${form.image_type}`}
                  row
                >
                  <FormControlLabel
                    value={`url`}
                    control={<Radio />}
                    label="Image Link"
                  />
                  <FormControlLabel
                    value={`upload`}
                    control={<Radio />}
                    label="Upload Image"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className="pt-2">
            {form.image_type == 'url' ? (
              <div>
                <FormLabel component="legend">Image URL</FormLabel>
                <TextValidator
                  className="mb-4 w-full"
                  label="Menu Image URL"
                  variant="outlined"
                  onChange={handleChange}
                  type="text"
                  name="media_url"
                  value={form.media_url || ''}
                />
              </div>
            ) : initialImage ? (
              <div>
                <img
                  className="img rounded"
                  src={initialImage.large}
                  alt="user"
                />
                <Button onClick={() => removeImage()}>Remove Image</Button>
              </div>
            ) : (
              <div
              className="pt-2"
                // style={{height: 100}}
              >
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  filesLimit={1}
                  showAlerts={['error']}
                  dropzoneText={'Drag and drop an image here or click'}
                  onChange={(files) => handleImageSelect(files)}
                />

              </div>
            )}

            {/* </div> */}
          </Grid>
          </Grid>
        </div>
      </ValidatorForm>
    </Card>
  );
};
export default CategoryForm;
