/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Button,
  Icon,
  Grid,
  FormGroup,
  TextField,
  MenuItem,
  FormLabel,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio
} from '@material-ui/core';

import { DropzoneArea } from 'material-ui-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { getTimeZones } from 'app/redux/actions/CommonActions';
import { outletSave } from 'app/redux/actions/OutletActions';
import { Redirect } from 'react-router-dom';

const OutletFormDetails = ({ initialData }) => {
  const dispatch = useDispatch();
  const { timeZones } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.auth);
  const [languages, setLanguages] = React.useState([]);
  const formRef = React.useRef();
  const [uploading, setUploading] = React.useState(false);
  // const [vatType, setVatType] = React.useState(1);
  const [defaultTimeZone, setDefaultTimeZone] = React.useState(1);
  const [image, setImage] = React.useState(null);
  const [initialImage, setInitialImage] = React.useState(null);
  const [redirect, setRedirect] = React.useState(null);
  const [form, setForm] = React.useState({
    name: {},
    address: {},
    custom_note: {},
    phone_1: '',
    mobile: '',
    whatsapp: '',
    inclusive_tax: 1,
    tax_rate: 0,
    timezone_id: 1,
    image_type: 'url'
  });

  React.useEffect(() => {
    dispatch(getTimeZones());

    if (initialData) {
      setDefaultTimeZone(initialData.timezone_id);
      setForm({
        id: initialData.id,
        name: initialData.name || {},
        address: initialData.address || {},
        custom_note: initialData.custom_note || {},
        phone_1: initialData.phone_1 || '',
        mobile: initialData.mobile || '',
        whatsapp: initialData.whatsapp || '',
        inclusive_tax: initialData.inclusive_tax,
        tax_rate: initialData.tax_rate || 0,
        timezone_id: initialData.timezone_id || 1,
        image_type: initialData.media_url ? 'url' : 'upload'
      });
      if (initialData.image) {
        setInitialImage(initialData.image);
      }
    }
    return () => {
      // ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [initialData]);

  React.useEffect(() => {
    // console.log('FORM::', form);
  }, [form]);

  React.useEffect(() => {
    if (
      user &&
      user.restaurant &&
      user.restaurant.languages &&
      user.restaurant.languages.length
    ) {
      setLanguages(user.restaurant.languages);
    }
  }, [user]);

  const handleSubmit = async () => {
    // console.log('FORM:', form);
    setUploading(true);
    // setForm({
    //   ...form,
    //   start: moment(form.start_time).format('HH:mm'),
    //   end: moment(form.end_time).format('HH:mm')
    // });
    const data = new FormData();
    for (let key in form) {
      if (form.hasOwnProperty(key)) {
        // console.log(key, form[key]); // value (ex. turkey)
        if (key === 'name' || key === 'address' || key === 'custom_note') {
          for (let k in form[key]) {
            data.append(`${key}[${k}]`, form[key][k]);
          }
          // data.set([key], JSON.stringify(form[key]));
        }
        else {
          data.set([key], form[key]);
        }
      }
    }
    if (image) {
      data.append('image', image);
    }

    // onSave(data);

    const outlet = await dispatch(outletSave(data));
    // console.log('MM:', outlet);
    if (outlet && outlet.outlet && outlet.outlet.id) {
      if (!initialData && !initialData.id)
        setRedirect(`/outlets/edit/${outlet.outlet.id}`);
      // else setRedirect(`/outlets/list`);
      setUploading(false);
    } else {
      setUploading(false);
    }
  };

  const handleChange = (event) => {
    // event.persist();
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleChangeWithLang = (n, lang, value) => {
    // event.persist();
    const obj = form[n];
    obj[lang] = value;
    setForm({ ...form, [n]: obj });
  };

  // const handleTaxChange = (event, value) => {
  //   // console.log('INPUT:', event, value);
  //   if (value !== null) {
  //     setForm({ ...form, inclusive_tax: value });
  //   }
  //   // setVatType(value);
  // };

  const handleImageSelect = (files) => {
    // event.persist();
    // console.log('FILES', files[0]);
    setImage(files[0]);
    if (files[0]) {
      setForm({
        ...form,
        remove_image: 0
      });
    }
  };

  const removeImage = () => {
    setForm({
      ...form,
      remove_image: 1
    });
    setImage(null);
    setInitialImage(null);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h5 component="legend" className="mb-4">
              General Information
            </h5>
            {/* <TextValidator
              className="mb-4 w-full"
              label="Outlet Name"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="name_en"
              value={form.name.en || ''}
              validators={['required']}
              errorMessages={['Outlet name is required']}
            /> */}
            {languages.length ? (
              languages.map((lang) => (
                <TextValidator
                  key={lang.lang}
                  className="mb-4 w-full"
                  label={`Outlet Name (${lang.label})`}
                  variant="outlined"
                  onChange={(e) =>
                    handleChangeWithLang('name', lang.lang, e.target.value)
                  }
                  type="text"
                  name={`name_${lang.lang}`}
                  value={form.name[lang.lang] || ''}
                />
              ))
            ) : (
              <TextValidator
                className="mb-4 w-full"
                label="Outlet Name (English)"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('name', 'en', e.target.value)
                }
                type="text"
                name="name_en"
                value={form.name.en || ''}
                validators={['required']}
                errorMessages={['Outlet name is required']}
              />
            )}
            {timeZones.length ? (
              <TextField
                name="timezone_id"
                onChange={handleChange}
                select
                defaultValue={defaultTimeZone}
                label="Time Zone"
                variant="outlined"
                className="mb-4 w-full"
              >
                {timeZones.map((timeZone) => (
                  <MenuItem key={`${timeZone.id}`} value={timeZone.id}>
                    {timeZone.timezone}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
            <h5 component="legend" className="mt-4">
              Custom Note
            </h5>
            <p className="mb-4">(will be shown on menu listing page)</p>
            {languages.length ? (
              languages.map((lang) => (
                <TextValidator
                  key={lang.lang}
                  className="mb-4 w-full"
                  label={`Custom Note (${lang.label})`}
                  variant="outlined"
                  onChange={(e) =>
                    handleChangeWithLang(
                      'custom_note',
                      lang.lang,
                      e.target.value
                    )
                  }
                  type="text"
                  name={`custom_note_${lang.lang}`}
                  value={form.custom_note[lang.lang] || ''}
                />
              ))
            ) : (
              <TextValidator
                className="mb-4 w-full"
                label="Custom Note"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('custom_note', 'en', e.target.value)
                }
                type="text"
                name="custom_note_en"
                value={form.custom_note.en || ''}
              />
            )}
            <h5 component="legend" className="mb-4 mt-4">
              Contact Details
            </h5>
            {languages.length ? (
              languages.map((lang) => (
                <TextValidator
                  key={lang.lang}
                  className="mb-4 w-full"
                  label={`Address (${lang.label})`}
                  variant="outlined"
                  onChange={(e) =>
                    handleChangeWithLang('address', lang.lang, e.target.value)
                  }
                  type="text"
                  name={`address_${lang.lang}`}
                  value={form.address[lang.lang] || ''}
                />
              ))
            ) : (
              <TextValidator
                className="mb-4 w-full"
                label="Address"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('address', 'en', e.target.value)
                }
                type="text"
                name="address_en"
                validators={['required']}
                value={form.address.en || ''}
              />
            )}
            
            <TextValidator
              className="mb-4 w-full"
              label="Phone"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="phone_1"
              value={form.phone_1 || ''}
              validators={['required']}
              errorMessages={['Phone is required']}
            />

            <TextValidator
              className="mb-4 w-full"
              label="Mobile"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="mobile"
              value={form.mobile || ''}
              validators={['required']}
              errorMessages={['Mobile is required']}
            />

            <TextValidator
              className="mb-4 w-full"
              label="WhatsApp"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="whatsapp"
              value={form.whatsapp || ''}
            />

            <h5 component="legend" className="mb-4 mt-4">
              Tax Information
            </h5>

            <FormGroup>
              <div className="flex items-center justify-start mb-4">
                <TextValidator
                  className="mr-4"
                  label="Vat Rate %"
                  variant="outlined"
                  onChange={handleChange}
                  type="text"
                  name="tax_rate"
                  value={form.tax_rate || 0}
                  validators={['required']}
                  errorMessages={['Tax rate is required']}
                />
                {/* <ToggleButtonGroup
                  value={form.inclusive_tax}
                  onChange={handleTaxChange}
                  exclusive
                  aria-label="Tax Type"
                >
                  <ToggleButton
                    value={1}
                    aria-label="vat included"
                    size="large"
                  >
                    <Typography>Vat Included</Typography>
                  </ToggleButton>
                  <ToggleButton
                    value={0}
                    aria-label="vat excluded"
                    size="large"
                  >
                    <Typography>Vat Excluded</Typography>
                  </ToggleButton>
                </ToggleButtonGroup> */}
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend" className="my-2">
                    Exclude Discounted Items
                  </FormLabel> */}
                  <RadioGroup
                    aria-label="Exclude Discounted Items"
                    name="inclusive_tax"
                    value={`${form.inclusive_tax}`}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value={`1`}
                      control={<Radio />}
                      label="VAT Included"
                    />
                    <FormControlLabel
                      value={`0`}
                      control={<Radio />}
                      label="VAT Excluded"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </FormGroup>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormLabel component="legend" className="my-4">
              Image Type
            </FormLabel>
            <div className="block my-4">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Image Type"
                  name="image_type"
                  onChange={handleChange}
                  value={`${form.image_type}`}
                  row
                >
                  <FormControlLabel
                    value={`url`}
                    control={<Radio />}
                    label="Image Link"
                  />
                  <FormControlLabel
                    value={`upload`}
                    control={<Radio />}
                    label="Upload Image"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {form.image_type == 'url' ? (
              <div>
                <FormLabel component="legend">Image URL</FormLabel>
                <TextValidator
                  className="mb-4 w-full"
                  label="Menu Image URL"
                  variant="outlined"
                  onChange={handleChange}
                  type="text"
                  name="media_url"
                  value={form.media_url || ''}
                />
              </div>
            ) : initialImage ? (
              <div>
                <img
                  className="img rounded"
                  src={initialImage.large}
                  alt="user"
                />
                <Button onClick={() => removeImage()}>Remove Image</Button>
              </div>
            ) : (
              <DropzoneArea
                acceptedFiles={['image/*']}
                filesLimit={1}
                showAlerts={['error']}
                dropzoneText={'Drag and drop an image here or click'}
                onChange={(files) => handleImageSelect(files)}
              />
            )}
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="my-3"
          size="large"
          disabled={uploading}
        >
          <Icon>send</Icon>
          <span className="pl-2 capitalize">Save</span>
        </Button>
      </ValidatorForm>
    </div>
  );
};
export default OutletFormDetails;
