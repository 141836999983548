import React from 'react';
import { Icon, Button, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { enqueueSnackbar } from 'app/redux/actions/UIActions';

const WebsiteOrdering = () => {
  const dispatch = useDispatch();

  const onCopy = () => {
    dispatch(
      enqueueSnackbar({
        message: 'Code Copied',
        options: {
          variant: 'success'
        }
      })
    );
  };

  const widgetCode =
    process.env.REACT_APP_APP.toLowerCase() === 'zoyabean'
      ? 'ZoyabeanWidget'
      : 'EatRootWidget';

  const codeString = `<script type="text/javascript"> 

  (function (w, d, s, f) { 

    w['${widgetCode}'] = "${
    JSON.parse(localStorage.getItem('auth_user')).restaurant.slug
  }"; 

    var p = d.createElement(s); 

    p.type = "text/javascript"; 

    p.async = true; p.src = f; 

    var c = d.getElementsByTagName("script")[0]; 

    c.parentNode.insertBefore(p, c); 

  })(window, document, "script", "https://widget.${
    process.env.REACT_APP_DOMAIN
  }/widget.js") 

</script> `;

  return (
    <Box>
      <h3>Install your ordering widget</h3>
      <p>
        It's very easy to install{' '}
        <span style={{ textTransform: 'capitalize' }}>
          {process.env.REACT_APP_APP.toLowerCase()}
        </span>
        's widget on your website. No need to be a developer! Just follow these
        instructions.
      </p>
      <p>
        Copy the base code below and paste it before the closing body tag on
        every page of your website
      </p>
      <div className="relative">
        <SyntaxHighlighter
          language="javascript"
          style={a11yDark}
          className="code-preview"
        >
          {codeString}
        </SyntaxHighlighter>
        <div className="absolute top right mr-3 mt-3">
          <CopyToClipboard text={codeString} onCopy={() => onCopy()}>
            <Button
              variant="contained"
              color="primary"
              // className={classes.button}
              startIcon={<Icon>content_copy</Icon>}
            >
              Copy Code
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </Box>
  );
};

export default WebsiteOrdering;
