export const authRoles = {
  sa: ["sa"], // Only Super Admin has access
  admin: ["sa", "admin", "store_admin"], // Only SA & Admin has access
  user: ["sa", "admin", "store_admin", "user"], // Only SA & Admin & Editor has access
  // guest: ["sa", "admin", "user", "guest"], // Everyone has access
};

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];
