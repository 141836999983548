import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // Switch as Switch2,
  Chip
} from '@material-ui/core';
import Switch from "react-switch";
// import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { statusChange } from 'app/redux/actions/CommonActions';
import SearchBar from 'app/components/SearchBar';

const placeHolder = '/assets/images/product-placeholder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f9f9f9',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    },
    marginLeft: 0,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '10ch',
    [theme.breakpoints.up('md')]: {
      width: '18ch',
      '&:focus': {
        width: '30ch'
      }
    }
  }
}));

const MenuTable = () => {
  const { menuList } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [filteredMenuList, setFilteredMenuList] = React.useState([]);
  // const [checkedList, setCheckedList] = React.useState([]);
  // const classes = useStyles();

  React.useEffect(() => {
    setFilteredMenuList(menuList);
  }, [menuList]);

  // const changeStatus = (e) => {
  //   const data = {
  //     module: 'menu',
  //     ref_id: e.target.value,
  //     value: e.target.checked ? 1 : 0
  //   };
  //   dispatch(statusChange(data));
  // };
  
  const changeStatus2 = (checked, e) => {
    // console.log('E:', checked, e);
    const data = {
      module: 'menu',
      ref_id: e.id,
      value: checked ? 1 : 0
    };
    // console.log('DATA', data);
    setFilteredMenuList(filteredMenuList.map(el => {
      el.publish = e.id == el.id ? checked : el.publish;
      return el
    }))
    dispatch(statusChange(data));
  };

  const searchMenu = (text) => {
    // console.log('TXT', text)
    setFilteredMenuList(menuList.filter((el) => el.name.en.toLowerCase().includes(text)));
  };

  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="flex items-center justify-between py-3">
        <div className="card-title mb-3">Menu Items</div>
        <SearchBar onSearch={searchMenu} placeholder="Search menu" />
      </div>
      <div className="overflow-auto">
        {filteredMenuList.length ? (
          <Table className="menu__table">
            <TableHead>
              <TableRow>
                <TableCell className="px-1">Name</TableCell>
                <TableCell className="px-0">Price</TableCell>
                <TableCell className="px-0">Available</TableCell>
                <TableCell className="px-0 text-right" align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMenuList.map((menu, index) => (
                <TableRow key={index}>
                  <TableCell className="px-0 capitalize" align="left">
                    <div className="flex items-center">
                      {menu.image && menu.image.thumb ? (
                        <img
                          className="img"
                          src={menu.image.thumb}
                          alt="user"
                        />
                      ) : (
                        <img className="img" src={placeHolder} alt="user" />
                      )}
                      <p className="m-0 ml-8">
                        {menu.name.en}
                        <span className="block">{menu.description && menu.description.en}</span>
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className="px-0 capitalize" align="left">
                    {menu.price}
                    {Number(menu.sales_price) > 0 && (
                      <span className="block">
                        Sale Price: {menu.sales_price}
                      </span>
                    )}
                  </TableCell>

                  <TableCell className="px-0" align="left">
                    {menu.start} - {menu.end}
                    {!menu.categories.length ? (
                      <>
                        <br></br>
                        <Chip
                          label="No categories"
                          className="bg-error"
                          size="small"
                        />
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell className="px-0 text-right" align="right">
                    {/* {localStorage.getItem('auth_user') &&
                      JSON.parse(localStorage.getItem('auth_user')).role ===
                        'admin' && (
                        <IconButton
                          component={RouterLink}
                          to={`/menus/edit/${menu.id}`}
                        >
                          <Icon color="primary">edit</Icon>
                        </IconButton>
                      )} */}
                    <IconButton
                      component={RouterLink}
                      to={`/menus/details/${menu.id}`}
                      style={{marginTop: -6}}
                    >
                      <Icon color="primary">pageview</Icon>
                    </IconButton>
                    {/* <div>CH: {`${menu.publish}`}</div> */}
                    <span className="pt-3 mt-3">
                    <Switch
                      value={menu.id}
                      checked={menu.publish}
                      onChange={(checked) => changeStatus2(checked, menu)}
                      height={15}
                      width={30}
                      onColor="#e50071"
                      // color="primary"
                      // size="small"
                      // inputProps={{ 'aria-label': 'Change Status' }}
                    />
                    </span>
                    {/* <Switch2
                      value={menu.id}
                      defaultChecked={menu.publish}
                      onChange={changeStatus}
                      color="primary"
                      size="small"
                      inputProps={{ 'aria-label': 'Change Status' }}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No menu items found...</div>
        )}
      </div>
    </Card>
  );
};

export default MenuTable;
