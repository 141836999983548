// import "babel-polyfill";
import "core-js/stable";
// import cssVars from "css-vars-ponyfill";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import "./_index.scss";

import * as serviceWorker from "./serviceWorker";
import App from "./app/App";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    // release: 'dashboard@' + process.env.npm_package_version,
  });
}
// cssVars();

ReactDOM.render(<App />, document.getElementById("root"));

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
