/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Card,
  IconButton,
  Box,
  Grid,
  Divider,
  Button
  //   Checkbox,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  linkAttributeGroup,
  getMenuDetails,
  getAttributesList
} from 'app/redux/actions/MenuActions';
import AddIcon from '@material-ui/icons/Add';
import ModifierForm from './ModifierForm';
import ModifierGroupForm from './ModifierGroupForm';
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import { Autocomplete } from "@material-ui/lab";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const animatedComponents = makeAnimated();

const MenuDetailsModifiers = ({ editable }) => {
  const dispatch = useDispatch();
  const { menuDetails, attributesList } = useSelector((state) => state.menu);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedAttributes, setSelectedAttributes] = React.useState(null);
  const [editGroupForm, setEditGroupForm] = React.useState({});
  const [editAttributesForm, setEditAttributesForm] = React.useState({});
  const [showModifierForm, toggleModifierForm] = React.useState(false);

  React.useEffect(() => {
    // console.log('SELECTED:', menuDetails, selectedGroup, editGroupForm);
  }, [menuDetails, editGroupForm]);

  const openForEdit = (group) => {
    // console.log('SEL:', group.id);
    setSelectedGroup(group.id);
    setSelectedAttributes(null);
  };

  const openForAdd = () => {
    setSelectedGroup(-1);
    setSelectedAttributes(null);
    setEditGroupForm({ menu_id: menuDetails.id });
  };

  const modifierAdded = () => {
    toggleModifierForm(false);
    dispatch(getAttributesList());
  };

  const submitGroupAttributesForm = () => {
    // console.log(editAttributesForm);
    if (editAttributesForm.group_id) {
      dispatch(linkAttributeGroup(editAttributesForm));
      dispatch(getMenuDetails(menuDetails.id));
      closeEdit();
    }
  };

  const openAttributesEdit = (group) => {
    // console.log('SEL:', group.id);
    setSelectedGroup(null);
    setSelectedAttributes(group.id);
    setEditAttributesForm({});
  };

  const onAttributesChange = (values) => {
    // console.log('VAL:', values);

    if (values && values.length) {
      const selectedAttributesList = values.map((value) => value.id);
      setEditAttributesForm({
        group_id: selectedAttributes,
        attributes: selectedAttributesList
      });
    }
    else {
      setEditAttributesForm({
        group_id: selectedAttributes,
        attributes: []
      });
    }
  };

  const closeEdit = () => {
    setSelectedGroup(null);
    setSelectedAttributes(null);
    setEditGroupForm({});
  };

  if (!menuDetails.id) {
    return <CircularProgress />;
  }

  return (
    <Card className="px-6 pt-2 pb-4" style={{overflow: 'visible'}}>
      <Box className="pt-2 pb-4">
        <div className="card-title flex justify-between">
          Modifiers
          {editable && (
            <div>
              <Button aria-label="Add" onClick={() => toggleModifierForm(true)}>
                Add Modifier
                <AddIcon fontSize="default" className="ml-2" />
              </Button>
              <Button
                aria-label="Edit"
                onClick={() => openForAdd()}
                className="ml-4"
              >
                Add Modifier Group
                <AddIcon fontSize="default" className="ml-2" />
              </Button>
            </div>
          )}
        </div>
        <Divider className="mb-4" />

        {showModifierForm ? (
          <Box className="mb-6">
            <h4>Add new Modifier Item</h4>
            <ModifierForm closeForm={modifierAdded} />
            <Divider className="my-6" />
          </Box>
        ) : null}

        {editable && selectedGroup === -1 && (
          <Box className="mb-6">
            <h4>Add new Modifier Group</h4>
            <ModifierGroupForm closeForm={() => closeEdit()} />
            <Divider className="my-6" />
          </Box>
        )}

        <div className="">
          <Box>
            {menuDetails.attributes_groups &&
            menuDetails.attributes_groups.length ? (
              menuDetails.attributes_groups.map((group, index) => (
                <Box key={`${group.id}`} className="mb-4">
                  <h4>{group.group_name.en}</h4>
                  {selectedGroup !== group.id && (
                    <Grid container>
                      <Grid item sm={4} md={4} className="pt-3">
                        Selection Type:{' '}
                        <strong>
                          {group.allow_multiple ? 'Multiple' : 'Single'}
                        </strong>
                      </Grid>
                      <Grid item sm={3} md={3} className="pt-3">
                        Minimum Required:{' '}
                        <strong>{group.minimum_required}</strong>
                      </Grid>
                        <Grid item sm={3} md={3} className="pt-3">
                      {group.allow_multiple ? (<>
                          Maximum Required:{' '}
                          <strong>{group.maximum_required}</strong>
                          {group.maximum_required === 0 && ' (Unlimited)'}
                      </>) : null}
                        </Grid>
                      {editable && (
                        <Grid item sm={2} md={2} className="text-right">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => openForEdit(group)}
                          >
                            <EditIcon fontSize="default" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {editable && selectedGroup === group.id && (
                    <ModifierGroupForm
                      group={group}
                      closeForm={() => closeEdit()}
                    />
                  )}
                  <Grid container>
                    <Grid item sm={10}>
                      {attributesList && attributesList.length ? (
                        <Select
                          isDisabled={selectedAttributes !== group.id}
                          className="w-full my-4"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={group.attributes}
                          isMulti
                          options={attributesList}
                          getOptionLabel={(option) =>
                            `${option.name.en} - AED ${option.price}`
                          }
                          getOptionValue={(option) => option.id}
                          onChange={onAttributesChange}
                        />
                      ) : (
                        <div>
                          No modifier items found.{' '}
                          <Button onClick={() => toggleModifierForm(true)}>
                            Add new modifier
                          </Button>
                        </div>
                      )}
                    </Grid>
                    {editable && attributesList && attributesList.length ? (
                      <Grid item sm={2} md={2} className="text-right pt-4">
                        {selectedAttributes !== group.id ? (
                          <Box>
                            <IconButton
                              aria-label="Edit"
                              onClick={() => openAttributesEdit(group)}
                            >
                              <EditIcon fontSize="default" />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton
                              aria-label="Save"
                              onClick={() => submitGroupAttributesForm()}
                            >
                              <CheckIcon fontSize="default" />
                            </IconButton>
                            <IconButton
                              aria-label="Cancel"
                              onClick={() => closeEdit()}
                            >
                              <CloseIcon fontSize="default" />
                            </IconButton>
                          </Box>
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                  <Divider />
                </Box>
              ))
            ) : (
              <h4>
                No modifiers assigned.{' '}
                <Button onClick={() => openForAdd()}>Add new modifier</Button>
              </h4>
            )}
          </Box>
        </div>
      </Box>
    </Card>
  );
};

export default MenuDetailsModifiers;
