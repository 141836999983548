import React from 'react';

const discountRoutes = [
  {
    path: '/discounts/list',
    component: React.lazy(() => import('./Discounts'))
  },
  {
    path: '/discounts/add',
    component: React.lazy(() => import('./DiscountAdd'))
  },
  {
    path: '/discounts/edit/:id',
    component: React.lazy(() => import('./DiscountEdit'))
  }
];

export default discountRoutes;
