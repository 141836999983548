import React from "react";
import LiveOrders from "./LiveOrders";
import OrderPrint from "./OrderPrint";
import Orders from "./Orders";

const ordersRoutes = [
  {
    path: "/orders/live",
    // component: React.lazy(() => import("./LiveOrders"))
    component: LiveOrders
  },
  {
    path: "/orders/list",
    // component: React.lazy(() => import("./Orders"))
    component: Orders
  },
  {
    path: "/orders/print/:id",
    // component: React.lazy(() => import("./Orders"))
    component: OrderPrint
  },
  // {
  //   path: "/forms/editor",
  //   component: React.lazy(() => import("./EditorForm"))
  // }
];

export default ordersRoutes;
