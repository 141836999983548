export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    allow: ['admin', 'store_admin', 'user']
  },
  {
    name: 'Live Orders',
    path: '/orders/live',
    icon: 'timer',
    allow: ['admin', 'store_admin', 'user']
  },
  {
    name: 'Orders',
    path: '/orders/list',
    icon: 'assignment-turned-in',
    allow: ['admin', 'store_admin', 'user']
  },
  {
    name: 'Menus',
    icon: 'restaurant-menu',
    allow: ['admin', 'store_admin', 'user'],
    children: [
      {
        name: 'List',
        path: '/menus/list',
        iconText: 'L',
        allow: ['admin', 'store_admin', 'user']
      },
      {
        name: 'Categories',
        path: '/menus/categories',
        iconText: 'C',
        allow: ['admin', 'store_admin']
      },
      {
        name: 'Modifiers',
        path: '/menus/modifiers',
        iconText: 'M',
        allow: ['admin', 'store_admin', 'user']
      }
    ]
  },
  {
    name: 'Outlets',
    icon: 'store',
    path: '/outlets/list',
    allow: ['admin', 'store_admin']
  },
  {
    name: 'Users',
    icon: 'people',
    path: '/users/list',
    allow: ['admin', 'store_admin']
  },
  {
    name: 'Customers',
    icon: 'perm_contact_calendar',
    path: '/customers/list',
    allow: ['admin', 'store_admin']
  },
  {
    name: 'Payment Links',
    icon: 'payments',
    path: '/payment-links/list',
    allow: ['admin', 'store_admin', 'user']
  },
  // {
  //   name: 'Delivery Zones',
  //   icon: 'room',
  //   path: '/zones/list',
  //   allow: ['admin']
  // },
  {
    name: 'Marketing',
    icon: 'chat',
    path: '/marketing/list',
    allow: ['admin', 'store_admin']
  },
  {
    name: 'Discounts',
    icon: 'confirmation_number',
    path: '/discounts/list',
    allow: ['admin', 'store_admin']
  },
  {
    name: 'Loyalty',
    icon: 'redeem',
    path: '/loyalty/list',
    allow: ['admin', 'store_admin', 'user']
  }
];
