import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_CHART,
  SET_DATE_RANGE,
  GET_TIMEZONES,
} from "../actions/CommonActions";

const initialState = {
  timeZones: [],
  dashboardData: {},
  dashboardChart: {},
  dateRange: {},
};

const CommonReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TIMEZONES: {
      // console.log("TIMEZONE REDUCER: ", action);
      return {
        ...state,
        timeZones: action.payload,
      };
    }
    case GET_DASHBOARD_DATA: {
      // console.log("DASHBOARD REDUCER: ", action);
      return {
        ...state,
        dashboardData: action.payload,
      };
    }
    case GET_DASHBOARD_CHART: {
      // console.log("DASHBOARD REDUCER: ", action);
      return {
        ...state,
        dashboardChart: action.payload,
      };
    }
    case SET_DATE_RANGE: {
      // console.log("DASHBOARD REDUCER: ", action);
      return {
        ...state,
        dateRange: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default CommonReducer;
