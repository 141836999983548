import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // Switch
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ZoneTable = () => {
  const { zonesList } = useSelector((state) => state.zone);

  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="card-title mb-3">Zones</div>
      <div className="overflow-auto">
        {zonesList.length ? (
          <Table className="menu__table">
            <TableHead>
              <TableRow>
                <TableCell className="px-1">
                  Name
                </TableCell>
                <TableCell className="px-1">
                  Minimum Order
                </TableCell>
                <TableCell className="px-1">
                  Delivery Fee
                </TableCell>
                <TableCell className="px-1">
                  Delivery Time
                </TableCell>
                <TableCell className="px-0">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zonesList.map((zone, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="px-0 capitalize"
                   
                    align="left"
                  >
                    <p className="m-0 ml-8">{zone.zone_name}</p>
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {zone.minimum_order}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {zone.delivery_fee}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {zone.delivery_time}
                  </TableCell>
                  <TableCell className="px-0">
                    <IconButton
                      component={Link}
                      to={`/zones/edit/${zone.id}`}
                      // href={`/zones/edit/${zone.id}`}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    {/* <Switch
                      value={zone.id}
                      defaultChecked={zone.status === 'active'}
                      onChange={changeStatus}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No zones found...</div>
        )}
      </div>
    </Card>
  );
};

export default ZoneTable;
