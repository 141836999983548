import React from 'react';
import {
  Grid,
  IconButton,
  Box,
  TextField,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import {
  saveAttributeGroup,
  getMenuDetails
} from 'app/redux/actions/MenuActions';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const ModifierGroupForm = ({ group = {}, closeForm }) => {
  const [editGroupForm, setEditGroupForm] = React.useState({});
  const dispatch = useDispatch();
  const { menuDetails } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);
  const [languages, setLanguages] = React.useState([]);

  React.useEffect(() => {
    if (
      user &&
      user.restaurant &&
      user.restaurant.languages &&
      user.restaurant.languages.length
    ) {
      setLanguages(user.restaurant.languages);
    }
  }, [user]);

  React.useEffect(() => {
    setEditGroupForm({
      ...editGroupForm,
      menu_id: menuDetails.id,
      group_name: {}
    });

    if (group && group.id) {
      setEditGroupForm({
        menu_id: menuDetails.id,
        id: group.id,
        group_name: group.group_name,
        group_name_en: group.group_name_en,
        group_name_ar: group.group_name_ar,
        minimum_required: group.minimum_required,
        maximum_required: group.maximum_required,
        allow_multiple: group.allow_multiple
      });
    } else {
      // console.log('NOOOO');
      group.menu_id = menuDetails.id;
      group.group_name = {};
      group.allow_multiple = 0;
    }
    // eslint-disable-next-line
  }, []);

  // React.useEffect(() => {
  //   if (editGroupForm.allow_multiple == 0) {
  //     setEditGroupForm({
  //       ...editGroupForm,
  //       maximum_required: 0,
  //     });
  //   }
  // }, [editGroupForm]);

  const setGroupForm = (e) => {
    let value = e.target.value;

    const data = {};

    data[e.target.name] = value;

    if (e.target.name == 'allow_multiple' && e.target.value == 0) {
      data.maximum_required = 0;
    }
    // console.log(data);
    setEditGroupForm({
      ...editGroupForm,
      ...data
    });
    // console.log(editGroupForm);
  };

  const handleChangeWithLang = (n, lang, value) => {
    // event.persist();
    const obj = editGroupForm[n];
    obj[lang] = value;
    setEditGroupForm({ ...editGroupForm, [n]: obj });
  };

  const submitGroupForm = () => {
    // console.log(editGroupForm);
    dispatch(saveAttributeGroup(editGroupForm));
    dispatch(getMenuDetails(menuDetails.id));
    closeForm();
  };

  if (!editGroupForm || !editGroupForm.menu_id) return <CircularProgress />;

  return (
    <Box style={{ display: 'flex', flex: 1 }}>
      <Box style={{ flex: 1 }}>
        <Box style={{ flex: 1 }}>
          <Grid container>
            {languages.length ? (
              languages.map((lang) => (
                <Grid item sm={6} md={6} className="pr-3">
                  <TextField
                    name={`group_name_${lang.lang}`}
                    onChange={(e) =>
                      handleChangeWithLang('group_name', lang.lang, e.target.value)
                    }
                    defaultValue={editGroupForm.group_name[lang.lang]}
                    label={`Group Name (${lang.label})`}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    className="w-full"
                  />
                </Grid>
              ))
            ) : (
              <Grid item sm={6} md={6} className="pr-3">
                <TextField
                  name="group_name_en"
                  onChange={(e) =>
                    handleChangeWithLang('group_name', 'en', e.target.value)
                  }
                  defaultValue={editGroupForm.group_name.en}
                  label="Group Name"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  className="w-full"
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box style={{ flex: 1 }}>
          <Grid container>
            <Grid item sm={2} md={2} className="pr-3">
              <TextField
                name="allow_multiple"
                onChange={setGroupForm}
                select
                label="Selection Type"
                defaultValue={editGroupForm.allow_multiple || 0}
                //   onChange={handleChange}
                variant="outlined"
                size="small"
                margin="dense"
                className="w-full"
              >
                <MenuItem value={1}>Multiple</MenuItem>
                <MenuItem value={0}>Single</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={2} md={2} className="pr-3">
              <TextField
                name="minimum_required"
                onChange={setGroupForm}
                defaultValue={editGroupForm.minimum_required}
                label="Min Required"
                variant="outlined"
                size="small"
                margin="dense"
                className="w-full"
              />
            </Grid>
            {editGroupForm.allow_multiple == 1 && (
              <Grid item sm={2} md={2} className="">
                <TextField
                  name="maximum_required"
                  onChange={setGroupForm}
                  defaultValue={editGroupForm.maximum_required}
                  label="Max Required"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  className="w-full"
                />
              </Grid>
            )}
          <Box style={{ flex: 0, minWidth: 120, textAlign: 'right' }}>
            <IconButton aria-label="Save" onClick={() => submitGroupForm()}>
              <CheckIcon fontSize="default" />
            </IconButton>
            <IconButton aria-label="Cancel" onClick={() => closeForm()}>
              <CloseIcon fontSize="default" />
            </IconButton>
          </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default ModifierGroupForm;
