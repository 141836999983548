import {
  SET_USER_DATA,
  SET_TOKEN,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  PASSWORD_RESET_SET_MOBILE,
  SET_ACTIVE_RESTAURANT
} from '../actions/AuthActions';

const initialState = { user: {}, token: null, passwordResetMobile: '', activeRestaurant: {} };

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        user: action.data
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload
      };
    }
    case PASSWORD_RESET_SET_MOBILE: {
      return {
        ...state,
        passwordResetMobile: action.payload
      };
    }
    case SET_ACTIVE_RESTAURANT: {
      console.log('RRR::', action.payload)
      return {
        ...state,
        activeRestaurant: action.payload
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
