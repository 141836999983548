import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';

export const GET_LOYALTY_EARNED_LIST = 'GET_LOYALTY_EARNED_LIST';
export const GET_LOYALTY_REDEEMED_LIST = 'GET_LOYALTY_REDEEMED_LIST';
export const GET_LOYALTY_DETAILS = 'GET_LOYALTY_DETAILS';
export const ADD_LOYALTY = 'ADD_LOYALTY';
// export const EDIT_LOYALTY = 'EDIT_LOYALTY';

// export const UPDATE_CART_AMOUNT = "UPDATE_CART_AMOUNT";

export const getLoyaltyEarned = (filter = '') => (dispatch) => {
  // axios.defaults.headers.common['affiliate-key'] = 'ZXItdGVzdC1rZXk=';
  // axios.defaults.headers.common['partner-api-key'] =
  //   'Y2I1YjVjMjEtMzJkNi00NzM2LThkMzItOTkyZmU2NTE5MmFk';
  // axios.defaults.headers.common['partner-api-secret'] =
  //   'JDJiJDEwJHlkTC5aM0V6SkpSTDFXMVJlMklmbU9XZHpvanJ5M2hIc0ZKejFNOXRqdUpRakx6WFpDS0lp';

    axios.defaults.headers.common['Authorization'] = localStorage.getItem(
      'jwt_token'
    );
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/loyalty/transactions/earned?${filter}`
    )
    .then((res) => {
      // console.log('LOYALTY ACTION:', res.data.data);
      dispatch({
        type: GET_LOYALTY_EARNED_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);
      showErrorMessage(dispatch, err);
    });
};

export const getLoyaltyRedeemed = (filter = '') => (dispatch) => {
  // axios.defaults.headers.common['affiliate-key'] = 'ZXItdGVzdC1rZXk=';
  // axios.defaults.headers.common['partner-api-key'] =
  //   'Y2I1YjVjMjEtMzJkNi00NzM2LThkMzItOTkyZmU2NTE5MmFk';
  // axios.defaults.headers.common['partner-api-secret'] =
  //   'JDJiJDEwJHlkTC5aM0V6SkpSTDFXMVJlMklmbU9XZHpvanJ5M2hIc0ZKejFNOXRqdUpRakx6WFpDS0lp';

    axios.defaults.headers.common['Authorization'] = localStorage.getItem(
      'jwt_token'
    );
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/loyalty/transactions/redeemed?${filter}`
    )
    .then((res) => {
      // console.log('LOYALTY ACTION:', res.data.data);
      dispatch({
        type: GET_LOYALTY_REDEEMED_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);
      showErrorMessage(dispatch, err);
    });
};

// export const getLoyaltyDetails = (id) => async (dispatch) => {
//   axios.defaults.headers.common['affiliate-key'] = 'ZXItdGVzdC1rZXk=';
//   axios.defaults.headers.common['partner-api-key'] =
//     'Y2I1YjVjMjEtMzJkNi00NzM2LThkMzItOTkyZmU2NTE5MmFk';
//   axios.defaults.headers.common['partner-api-secret'] =
//     'JDJiJDEwJHlkTC5aM0V6SkpSTDFXMVJlMklmbU9XZHpvanJ5M2hIc0ZKejFNOXRqdUpRakx6WFpDS0lp';

//   return await axios
//     .get(
//       `${process.env.REACT_APP_LOYALTY_URL}/api/delivery-loyalty/detail/${id}`
//     )
//     .then((res) => {
//       // console.log('LOYALTY DETAILS:', res.data);
//       dispatch({
//         type: GET_LOYALTY_DETAILS,
//         payload: res.data.loyalty
//       });
//       return res.data.loyalty;
//     })
//     .catch((err) => {
//       // console.log('ERROR:', err.response);

//       showErrorMessage(dispatch, err);
//     });
// };

export const loyaltySave = (data) => (dispatch) => {
  // axios.defaults.headers.common['affiliate-key'] = 'ZXItdGVzdC1rZXk=';
  // axios.defaults.headers.common['partner-api-key'] =
  //   'Y2I1YjVjMjEtMzJkNi00NzM2LThkMzItOTkyZmU2NTE5MmFk';
  // axios.defaults.headers.common['partner-api-secret'] =
  //   'JDJiJDEwJHlkTC5aM0V6SkpSTDFXMVJlMklmbU9XZHpvanJ5M2hIc0ZKejFNOXRqdUpRakx6WFpDS0lp';
// console.log('DATA', data);
    axios.defaults.headers.common['Authorization'] = localStorage.getItem(
      'jwt_token'
    );

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/loyalty/walkin/push-points`,
      data
    )
    .then((res) => {
      const message = 'Loyalty points added';

      // console.log('DATA', res);
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );
      dispatch({
        type: ADD_LOYALTY,
        payload: res.data.loyalty
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);
      // dispatch(
      //   enqueueSnackbar({
      //     message: 'Something went wrong',
      //     options: {
      //       variant: 'error'
      //     }
      //   })
      // );
    });
};
