import React from 'react';
import {
  Card,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { getLoyaltyEarned } from 'app/redux/actions/LoyaltyActions';

const LoyaltyEarnedTable = () => {
  const { earnedList, earnedPagination } = useSelector(
    (state) => state.loyalty
  );
  const dispatch = useDispatch();

  const [count, setCount] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [data, setData] = React.useState([['Loading Data...']]);
  const columns = [
    {
      name: 'purchaseRef',
      label: 'Transaction Ref',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return `#${value}`;
        }
      }
    },
    {
      name: 'customer_name',
      label: 'Customer',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'customer_mobile',
      label: 'Phone',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'purchaseAmount',
      label: 'Transaction Amount',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'transactionPoint',
      label: 'Points Earned',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'order_type',
      label: 'Order Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'createdAt',
      label: 'Transaction Time',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return `${moment(value).format('DD-MM-YYYY HH:mm')}`;
        }
      }
    }
  ];
  const [isLoading, setLoading] = React.useState(false);

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    // sortOrder: sortOrder,
    sort: true,
    print: false,
    selectableRows: 'none',
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      const filterColumns = [
        'purchaseRef',
        'customer_name',
        'customer_mobile',
        'purchaseAmount',
        'transactionPoint',
        'order_type',
        'createdAt'
      ];
      const filters = [];

      if (['changePage', 'sort', 'filterChange', 'search'].includes(action)) {
        tableState.filterList.map((f, i) => {
          if (f[0]) filters.push(`${filterColumns[i]}=${f[0]}`);
          return true;
        });
        changePage(tableState.page, tableState.sortOrder, filters.join('&'));
      }
    }
  };

  React.useEffect(() => {
    // console.log('LOYALTY LIST:', earnedList);
  }, [earnedList]);

  React.useEffect(() => {
    // console.log('LIST::', earnedList);
    if (earnedList && Array.isArray(earnedList)) {
      const d = earnedList.map((l) => ({
        ...l,
        customer_name: l.customer?.name,
        customer_mobile: l.customer?.mobile,
        order_type: l.type_of_purchase?.type
      }));

      setData(d);
      if (earnedPagination) {
        // setPage(ordersPagination.current_page);
        setCount(earnedPagination.count);
        setRowsPerPage(earnedPagination.itemPerPage);
      }
    }
  }, [earnedPagination, earnedList]);

  const changePage = async (page, sortOrder, filter, search) => {
    setLoading(true);
    let params = `page=${page + 1}`;
    if (sortOrder && sortOrder.name)
      params += `&sort=${sortOrder.name}&direction=${sortOrder.direction}`;
    if (filter) params += `&${filter}`;
    if (search) params += `&search=${search}`;
    await dispatch(getLoyaltyEarned(params));
    setLoading(false);
  };

  return (
    <div className="">
      <MUIDataTable
        title={
          <Typography variant="h6">
            Recent Transactions
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: 'relative', top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
  // return (
  //   <Card className="px-6 pt-2 pb-4">
  //     <div className="card-title mb-3">Recent Transactions</div>
  //     <div className="overflow-auto">
  //       {earnedList && earnedList.rows && earnedList.rows.length ? (
  //         <Table className="menu__table">
  //           <TableHead>
  //             <TableRow>
  //               <TableCell className="px-1">Transaction Ref</TableCell>
  //               <TableCell className="px-1">Customer Name</TableCell>
  //               <TableCell className="px-1">Customer Mobile</TableCell>
  //               <TableCell className="px-1">Transaction Amount</TableCell>
  //               <TableCell className="px-1">Points Earned</TableCell>
  //               <TableCell className="px-1">Order Type</TableCell>
  //               <TableCell className="px-1">Transaction Time</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {earnedList.rows.map((transaction, index) => (
  //               <TableRow key={index}>
  //                 <TableCell className="px-0 capitalize" align="left">
  //                   <p className="m-0 ml-8">{transaction.purchaseRef}</p>
  //                 </TableCell>
  //                 <TableCell className="px-1 capitalize" align="left">
  //                   {transaction.customer.name}
  //                 </TableCell>
  //                 <TableCell className="px-1 capitalize" align="left">
  //                   {transaction.customer.mobile}
  //                 </TableCell>
  //                 <TableCell className="px-1 capitalize" align="left">
  //                   {transaction.purchaseAmount}
  //                 </TableCell>
  //                 <TableCell className="px-1 capitalize" align="left">
  //                   {transaction.transactionPoint}
  //                 </TableCell>
  //                 <TableCell className="px-1 capitalize" align="left">
  //                   {transaction.type_of_purchase?.type}
  //                 </TableCell>
  //                 <TableCell className="px-1 capitalize" align="left">
  //                   {moment(transaction.createdAt).format('DD-MM-YYYY HH:mm')}
  //                 </TableCell>
  //               </TableRow>
  //             ))}
  //           </TableBody>
  //         </Table>
  //       ) : (
  //         <div>No transactions found...</div>
  //       )}
  //     </div>
  //   </Card>
  // );
};

export default LoyaltyEarnedTable;
