import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
import { statusChange } from 'app/redux/actions/CommonActions';

const UsersTable = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const { user: u } = useSelector((state) => state.auth);

  const changeStatus = (e) => {
    const data = {
      module: 'user',
      ref_id: e.target.value,
      value: e.target.checked ? 1 : 0
    };
    dispatch(statusChange(data));
  };

  return (
    <Card className="px-6 pt-2 pb-4">
      <div className="card-title mb-3">Users</div>
      <div className="overflow-auto">
        {users.length ? (
          <Table className="menu__table">
            <TableHead>
              <TableRow>
                <TableCell className="px-1">
                  Name
                </TableCell>
                <TableCell className="px-1">
                  Email
                </TableCell>
                <TableCell className="px-1">
                  Role
                </TableCell>
                <TableCell className="px-0">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="px-0 capitalize"
                   
                    align="left"
                  >
                    <div className="flex items-center">
                      {/* {user.image && user.image.thumb ? (
                        <img
                          className="img"
                          src={user.image.thumb}
                          alt="user"
                        />
                      ) : null} */}
                      <p className="m-0 ml-8">
                        {user.name}
                        <span className="block">{user.address}</span>
                      </p>
                    </div>
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    className="px-1 capitalize"
                   
                    align="left"
                  >
                    {user.role}
                  </TableCell>
                  <TableCell className="px-0">
                    <IconButton
                      component={RouterLink}
                      to={`/users/edit/${user.id}`}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <Switch
                      value={user.id}
                      defaultChecked={user.status === 'active'}
                      onChange={changeStatus}
                      color="primary"
                      name="checkedB"
                      disabled={users.length <= 0 || user.id == u.id}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {user.id}:{ u.id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No users found...</div>
        )}
      </div>
    </Card>
  );
};

export default UsersTable;
