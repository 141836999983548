import {
  GET_LOYALTY_EARNED_LIST,
  GET_LOYALTY_REDEEMED_LIST,
  GET_LOYALTY_DETAILS,
  ADD_LOYALTY,
} from '../actions/LoyaltyActions';

const initialState = {
  earnedList: [],
  redeemedList: {},
  earnedPagination: {},
  redeemedPagination: {},
  loyaltyDetails: {},
};

const LoyaltyReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LOYALTY_EARNED_LIST: {
      // console.log('LOYALTY REDUCER: ', action);
      return {
        ...state,
        earnedList: action.payload.rows,
        earnedPagination: {
          count: action.payload.count, 
          currentPage: action.payload.currentPage, 
          itemPerPage: action.payload.itemPerPage, 
          totalPages: action.payload.totalPages, 
        }
      };
    }
    case GET_LOYALTY_REDEEMED_LIST: {
      // console.log('LOYALTY REDUCER: ', action);
      return {
        ...state,
        redeemedList: action.payload.rows,
        redeemedPagination: {
          count: action.payload.count, 
          currentPage: action.payload.currentPage, 
          itemPerPage: action.payload.itemPerPage, 
          totalPages: action.payload.totalPages, 
        }
      };
    }
    case GET_LOYALTY_DETAILS: {
      // console.log('ORDER REDUCER: ', action.payload);
      return {
        ...state,
        loyaltyDetails: action.payload
      };
    }
    case ADD_LOYALTY: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        // earnedList: [...state.earnedList, action.payload],
        // loyaltyDetails: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default LoyaltyReducer;
