import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import { getApi } from 'app/services/api';

// export const SET_USER_DATA = "USER_SET_DATA";
// export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
// export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';

export const getUsersList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/users?rows=1000`)
    .then((res) => {
      // console.log('USER ACTION:', res.data);
      dispatch({
        type: GET_USER_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/users/detail/${id}`)
    .then((res) => {
      // console.log('USER DETAILS:', res.data);
      dispatch({
        type: GET_USER_DETAILS,
        payload: res.data.user
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const userSave = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/users/save`, data)
    .then((res) => {
      const message = data.id ? 'User details updated' : 'User added';

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        dispatch({
          type: EDIT_USER,
          payload: res.data.user
        });
      } else {
        dispatch({
          type: ADD_USER,
          payload: res.data.user
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err);
      // console.log('ERROR:', err.response);
      // console.log('ERROR:', err.response.responseJSON);
      // console.log('ERROR:', err.response.data);

      showErrorMessage(dispatch, err);
    });
};
