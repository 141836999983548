import React from 'react';
import { Card, IconButton, Box, Grid, Chip, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getOutletsList } from 'app/redux/actions/OutletActions';
import { Link } from 'react-router-dom';

const MenuDetailsView = ({ editable }) => {
  const dispatch = useDispatch();
  const { menuDetails } = useSelector((state) => state.menu);

  React.useEffect(() => {
    dispatch(getOutletsList());
    // console.log('SELECTED 2 :', menuDetails);
  }, [dispatch, menuDetails]);

  if (!menuDetails.id) {
    return <CircularProgress />;
  }

  return (
    <Card className="px-6 pt-2 mb-4 pb-4">
      <Box className="pt-2">
        <div className="card-title flex justify-between">
          Item Details
          {editable && (
            <IconButton
              aria-label="Edit"
              component={Link}
              to={`/menus/edit/${menuDetails.id}`}
              //   onClick={() => openForEdit(group)}
            >
              <EditIcon fontSize="default" />
            </IconButton>
          )}
        </div>
        <Divider className="mb-6" />
        <div className="overflow-auto">
          <Box>
            <Grid container>
              <Grid item md={8}>
                <Grid container>
                  <Grid item sm={12}>
                    <p>Name:</p>
                    <h4>
                      {menuDetails.name.en}
                      {menuDetails.item_tag && menuDetails.item_tag.en ? (
                        <Chip
                        label={menuDetails.item_tag.en}
                        className={`mx-2 ${
                          menuDetails.item_tag.en ? '' : 'hidden'
                        }`}
                        size="small"
                        color="secondary"
                        />
                        ) : null}
                    </h4>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={4} className="mt-2">
                    <p>Price:</p>
                    <h5>{menuDetails.price}</h5>
                  </Grid>
                  {Number(menuDetails.sales_price) ? (
                  <Grid item xs={12} sm={12} md={12} lg={4} className="mt-2">
                    <p>Sale Price:</p>
                    <h5>{menuDetails.sales_price}</h5>
                  </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6} md={6} lg={4} className="mt-2">
                    <p>Available Time:</p>
                    <h5>
                      {moment(menuDetails.start, 'HH:mm:ss').format('HH:mm A')}
                      {' - '}
                      {moment(menuDetails.end, 'HH:mm:ss').format('HH:mm A')}
                    </h5>
                  </Grid>
                  {menuDetails.non_available_start && (
                    <Grid item xs={12} sm={6} md={6} lg={4} className="mt-2">
                      <p>Not Available Time:</p>
                      <h5>
                        {moment(
                          menuDetails.non_available_start,
                          'HH:mm:ss'
                        ).format('HH:mm A')}
                        {' - '}
                        {moment(
                          menuDetails.non_available_end,
                          'HH:mm:ss'
                        ).format('HH:mm A')}
                      </h5>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid item md={12} sm={12} className="mt-2">
                    <p>Description:</p>
                    <h5>{menuDetails.description && menuDetails.description.en ? menuDetails.description.en : ''}</h5>
                  </Grid>
                  <Grid item md={12} sm={12} className="mt-2">
                    <p>Categories:</p>

                    {menuDetails.categories && menuDetails.categories.length ? (
                      <div>
                        {menuDetails.categories.map((category) => {
                          return (
                            <Chip
                              key={category.slug}
                              label={category.name.en}
                              className="mr-2 my-2"
                              color="primary"
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <p>No categories assigned</p>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid item md={12}>
                  {menuDetails.image && menuDetails.image.thumb && (
                    <p>
                      <img
                        className="img rounded"
                        src={menuDetails.image.large}
                        alt="user"
                      />
                    </p>
                  )}
                </Grid>
              </Grid>
              <Grid item md={12}></Grid>
            </Grid>
          </Box>
        </div>
      </Box>
    </Card>
  );
};

export default MenuDetailsView;
