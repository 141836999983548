import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid, IconButton } from '@material-ui/core';
import {
  saveAttribute,
  getAttributesList
} from 'app/redux/actions/MenuActions';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close } from '@material-ui/icons';

const ModifierForm = ({ initialData, closeForm }) => {
  const { user } = useSelector((state) => state.auth);
  const [languages, setLanguages] = React.useState([]);

  const [form, setForm] = React.useState({
    name: {},
    name_en: '',
    name_ar: '',
    price: 0
  });
  const formRef = React.useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      user &&
      user.restaurant &&
      user.restaurant.languages &&
      user.restaurant.languages.length
    ) {
      setLanguages(user.restaurant.languages);
    }
  }, [user]);

  React.useEffect(() => {
    if (initialData && initialData.id) {
      setForm({
        id: initialData.id,
        name: initialData.name,
        name_en: initialData.name_en,
        name_ar: initialData.name_ar,
        price: initialData.price
      });
    }
  }, [initialData]);

  const handleSubmit = async (event) => {
    console.log(form);
    // onSave(form);
    const modifier = await dispatch(saveAttribute(form));
    if (modifier.item && modifier.item.id) {
      dispatch(getAttributesList());
      closeForm(false);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleChangeWithLang = (n, lang, value) => {
    // event.persist();
    const obj = form[n];
    obj[lang] = value;
    setForm({ ...form, [n]: obj });
  };

  return (
    <div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        <Grid container spacing={2}>
          {languages.length ? (
            languages.map((lang) => (
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <TextValidator
                  className="w-full"
                  variant="outlined"
                  type="text"
                  label={`Modifier Name (${lang.label})`}
                  onChange={(e) =>
                    handleChangeWithLang('name', lang.lang, e.target.value)
                  }
                  name={`name_${lang.lang}`}
                  value={form.name[lang.lang] || ''}
                  size="small"
                  validators={['required']}
                  errorMessages={['Modifier name is required']}
                />
              </Grid>
            ))
          ) : (
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <TextValidator
                className="w-full"
                label="Modifier Name"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('name', 'en', e.target.value)
                }
                type="text"
                name="name_en"
                size="small"
                value={form.name.en}
              />
            </Grid>
          )}
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <TextValidator
              className="w-full"
              label="Price"
              variant="outlined"
              onChange={handleChange}
              type="number"
              name="price"
              size="small"
              value={form.price}
              validators={['required']}
              errorMessages={['Price name is required']}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={6} className="text-right">
            <IconButton aria-label="Save" type="submit">
              <Check fontSize="default" />
            </IconButton>
            {closeForm ? (
              <IconButton aria-label="Cancel" onClick={() => closeForm(false)}>
                <Close fontSize="default" />
              </IconButton>
            ) : null}

            {/* <Button
              color="primary"
              variant="contained"
              type="submit"
              className="w-full h-full"
              size="small"
            >
              <Icon>send</Icon>
              <span className="pl-2 capitalize">Submit</span>
            </Button> */}
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};
export default ModifierForm;
