// import { enqueueSnackbar } from "./UIActions";
import history from 'history.js';
import axios from 'axios';
import jwtAuthService from '../../services/jwtAuthService';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';

export const SET_USER_DATA = 'USER_SET_DATA';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_USER_DATA = 'USER_REMOVE_DATA';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const PASSWORD_RESET_SET_MOBILE = 'PASSWORD_RESET_SET_MOBILE';
export const SET_ACTIVE_RESTAURANT = 'SET_ACTIVE_RESTAURANT';

export function setUserData(user) {
  // console.log('AUTH ACTION:', user);
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function setToken(token) {
  // console.log('AUTH ACTION:', user);
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN,
      payload: token
    });
  };
}

export function setActiveRestaurant(restaurant) {
  // console.log('REST ACTION:', restaurant);
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_RESTAURANT,
      payload: restaurant
    });
  };
}

export function logoutUser() {
  return (dispatch) => {
    jwtAuthService.logout();

    history.push({
      pathname: '/session/signin'
    });

    dispatch(setUserData({}));
    
    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}

export const forgotPassword = (data) => async (dispatch) => {
  // console.log('DATA', data);
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, data)
    .then((res) => {
      // console.log('CATEGORY ADDED:', res.data);
      const message = 'OTP Sent to mobile number.';
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      dispatch({
        type: PASSWORD_RESET_SET_MOBILE,
        payload: data.mobile
      });

      history.push({
        pathname: '/session/reset-password'
      });
      // return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const resetPassword = (data) => async (dispatch) => {
  // console.log('DATA', data);
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, data)
    .then((res) => {
      // console.log('CATEGORY ADDED:', res.data);
      const message = 'Password reset successfully.';
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      history.push({
        pathname: '/session/signin'
      });

      // dispatch({
      //   type: FORGOT_PASSWORD,
      //   payload: res.data.item
      // });
      // return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};
