/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { Card, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getMenuDetails } from 'app/redux/actions/MenuActions';
import MenuForm from '../shared/MenuForm';

const MenuEdit = () => {
  // const [redirect, setRedirect] = React.useState(null);
  // const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const { menuDetails } = useSelector((state) => state.menu);
  const { id } = useParams();

  React.useEffect(() => {
    async function loadDetails() {
      // console.log('ID::', id);
      const details = await dispatch(getMenuDetails(id));
      if (details && details.id) {
        // console.log('DETAILS::', details);
        // setLoading(false);
      }
    }
    loadDetails();
    // console.log('ID:', id, menuDetails);
  }, [dispatch]);

  // const submitMenu = async (data) => {
  //   // data.id = id;
  //   console.log('DATA0:', data.entries());
  //   console.log('DATA1:', ...data);
  //   const menu = await dispatch(menuSave(data));
  //   console.log('MM:', menu);
  //   if (menu.item && menu.item.id) {
  //     setRedirect('/menus/list');
  //   }
  // };

  // if (redirect) {
  //   return <Redirect to={redirect} />;
  // }
  if (!menuDetails.id) {
    return <CircularProgress />;
  }
  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Menus', path: '/menus/list' },
            { name: 'Edit Menu' }
          ]}
        />
      </div>
      <Card className="px-6 py-6">
        <MenuForm
          // onSave={(data) => submitMenu(data)}
          initialData={menuDetails}
        />
      </Card>
    </div>
  );
};
export default MenuEdit;
