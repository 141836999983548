import {
  GET_USER_LIST,
  GET_USER_DETAILS,
  ADD_USER,
  EDIT_USER
} from '../actions/UserActions';

const initialState = {
  users: [],
  userDetails: {}
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    // case SET_USER_DATA: {
    //   return {
    //     ...state,
    //     ...action.data,
    //   };
    // }
    // case REMOVE_USER_DATA: {
    //   return {
    //     ...state,
    //   };
    // }
    // case USER_LOGGED_OUT: {
    //   return state;
    // }
    case GET_USER_LIST: {
      return {
        ...state,
        users: [...action.payload]
      };
    }
    case GET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload
      };
    }
    case ADD_USER: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        users: [...state.users, action.payload]
      };
    }
    case EDIT_USER: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        users: [...state.users, action.payload]
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
