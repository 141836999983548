/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Grid, Button } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';

import { resetPassword } from 'app/redux/actions/AuthActions';

const ResetPassword = () => {
  const [form, setForm] = React.useState({
    mobile: '',
    otp: '',
    new_password: '',
    confirm_password: ''
  });
  const formRef = React.useRef();
  const dispatch = useDispatch();
  const { passwordResetMobile } = useSelector((state) => state.auth);

  React.useEffect(() => {
    setForm({ ...form, mobile: passwordResetMobile });
  }, [passwordResetMobile]);

  const handleChange = (event) => {
    event.persist();
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const handleFormSubmit = () => {
    // console.log('FORM:', form);
    dispatch(resetPassword(form));
  };

  return (
    <div className="signup flex justify-center w-full h-full-screen">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-8 flex justify-center items-center h-full">
                <img src="/assets/images/illustrations/dreamer.svg" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-9 h-full bg-light-gray position-relative">
                <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="OTP"
                    onChange={handleChange}
                    type="text"
                    name="otp"
                    value={form.otp}
                    validators={['required']}
                    errorMessages={['this field is required']}
                  />
                  <TextValidator
                    className="mb-6 mt-6 w-full"
                    variant="outlined"
                    label="New Password"
                    onChange={handleChange}
                    type="password"
                    name="new_password"
                    value={form.new_password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                  />
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="Confirm Password"
                    onChange={handleChange}
                    type="password"
                    name="confirm_password"
                    value={form.confirm_password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                  />
                  <div className="flex items-center">
                    <Button variant="contained" color="primary" type="submit">
                      Reset Password
                    </Button>
                    {/* <span className="ml-4 mr-2">or</span>
                    <Button
                      className="capitalize"
                      // onClick={() =>
                      //   this.props.history.push('/session/signin')
                      // }
                    >
                      Sign in
                    </Button> */}
                  </div>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
