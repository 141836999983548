import React from 'react';
import { List, InputAdornment, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Search } from '@material-ui/icons';
import LiveOrderListItem from './LiveOrderListItem';

const LiveOrderList = ({ toggleDetails }) => {
  const [ordersList, setOrdersList] = React.useState([]);
  const { ordersListLive } = useSelector((state) => state.order);

  React.useEffect(() => {
    // console.log('ORDER LOADED:');
    setOrdersList(ordersListLive);
  }, [ordersListLive]);

  const filterList = (text) => {
    setOrdersList(
      ordersListLive.filter(
        (item) => item.order_no.indexOf(text.target.value) !== -1
      )
    );
  };

  return (
    <div className="h-full">
      <div>
        <TextField
          className="w-full"
          id="input-with-icon-textfield"
          label=""
          size="medium"
          variant="outlined"
          placeholder="Search by order number"
          onChange={filterList}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </div>
      {ordersList.length ? (
        <List
          component="nav"
          aria-label="secondary mailbox folders"
          className=""
        >
          {' '}
          {ordersList.map((order, index) => (
            <LiveOrderListItem
              order={order}
              toggleDetails={toggleDetails}
              key={order.id}
            />
          ))}
        </List>
      ) : (
        <div>
          <h4>No orders found</h4>
        </div>
      )}
    </div>
  );
};

export default LiveOrderList;
