import {
  GET_ZONE_LIST,
  GET_ZONE_DETAILS,
  ADD_ZONE,
  EDIT_ZONE,
  GET_ZONE_PICKUP_HOURS,
  GET_ZONE_DELIVERY_HOURS,
  GET_LOCATIONS_LIST
} from '../actions/ZoneActions';

const initialState = {
  zonesList: [],
  zoneDetails: {},
  deliveryHours: [],
  pickupHours: [],
  locationsList: []
};

const ZoneReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ZONE_LIST: {
      // console.log('ZONE REDUCER: ', action);
      return {
        ...state,
        zonesList: [...action.payload]
      };
    }
    case GET_ZONE_DETAILS: {
      // console.log('ORDER REDUCER: ', action.payload);
      return {
        ...state,
        zoneDetails: action.payload
      };
    }
    case ADD_ZONE: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        zonesList: [...state.zonesList, action.payload],
        zoneDetails: action.payload
      };
    }
    case EDIT_ZONE: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        zonesList: [...state.zonesList, action.payload],
        zoneDetails: action.payload
      };
    }
    case GET_ZONE_PICKUP_HOURS: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        pickupHours: action.payload
      };
    }
    case GET_ZONE_DELIVERY_HOURS: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        deliveryHours: action.payload
      };
    }
    case GET_LOCATIONS_LIST: {
      // console.log('MENU ADD REDUCER: ', action);
      return {
        ...state,
        locationsList: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default ZoneReducer;
