import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Button,
  Icon,
  Grid,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Switch,
  RadioGroup,
  Radio,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import 'date-fns';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from "prop-types";

import { getCategoriesList, menuSave } from 'app/redux/actions/MenuActions';
import { Redirect } from 'react-router-dom';

const MenuForm = ({ onSave, initialData }) => {
  const dispatch = useDispatch();
  const { categoriesList } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);
  const [languages, setLanguages] = React.useState([]);
  const [hours, setHours] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [initialImage, setInitialImage] = React.useState(null);
  const [redirect, setRedirect] = React.useState(null);
  const formRef = React.useRef();
  const [form, setForm] = React.useState({
    name: {},
    description: {},
    price: '',
    sales_price: 0,
    item_tag: {},
    start: '00:00',
    end: '23:59',
    non_available_start: '00:00',
    non_available_end: '00:00',
    is_veg: false,
    categories: [],
    image_type: 'url'
  });

  React.useEffect(() => {
    if (
      user &&
      user.restaurant &&
      user.restaurant.languages &&
      user.restaurant.languages.length
    ) {
      setLanguages(user.restaurant.languages);
    }
  }, [user]);

  React.useEffect(() => {
    dispatch(getCategoriesList());

    let items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format('HH:mm'));
      items.push(moment({ hour: index, minute: 30 }).format('HH:mm'));
    });
    items.push('23:59');

    setHours(items);

    // console.log('FORM::', items, initialData);

    if (initialData) {
      let cats = [];
      if (initialData.categories.length) {
        cats = initialData.categories.map((cat) => cat.id);
        // console.log('CATS:', cats);
      }
      setForm({
        id: initialData.id,
        name: initialData.name || {},
        price: initialData.price || 0,
        sales_price: initialData.sales_price || 0,
        item_tag: initialData.item_tag || {},
        description: initialData.description || {},
        categories: cats,
        start: initialData.start,
        end: initialData.end,
        non_available_start: initialData.non_available_start,
        non_available_end: initialData.non_available_end,
        is_veg: initialData.is_veg,
        image_type: initialData.media_url ? 'url' : 'upload'
      });
      if (initialData.image) {
        setInitialImage(initialData.image);
      }
    }
    return () => {
      // ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [initialData, dispatch]);

  React.useEffect(() => {
    ValidatorForm.addValidationRule('isLessThanPrice', (value) => {
      // console.log('FORM: ', form);
      if (Number(value) >= Number(form.price)) {
        // console.log('NOT LESS THAN: ', value, ':', form.price);
        return false;
      }
      // console.log('IS LESS THAN: ', value, ':', form.price);
      return true;
    });
  }, [form]);

  const handleCategorySelect = (event) => {
    // console.log('CL', event.target.value, event.target.checked);
    let categories = form.categories;
    let check = event.target.checked;
    let checked = event.target.value;
    if (check) {
      // console.log('CHECKED', form.categories);
      setForm({
        ...form,
        categories: [...form.categories, Number(checked)]
      });
    } else {
      // var index = categories.indexOf(checked);
      // if (index > -1) {
      //   categories.splice(index, 1);
      setForm({
        ...form,
        categories: categories.filter((cat) => Number(cat) !== Number(checked))
      });
      // }
    }
    // console.log('CATSSS:', form.categories);
  };

  const handleSubmit = async (event) => {
    // console.log(form);
    setUploading(true);
    // setForm({
    //   ...form,
    //   start: moment(form.start_time).format('HH:mm'),
    //   end: moment(form.end_time).format('HH:mm')
    // });
    const data = new FormData();
    for (let key in form) {
      if (form.hasOwnProperty(key) && key !== 'categories') {
        // console.log(key, form[key]); // value (ex. turkey)
        if (key === 'name' || key === 'item_tag' || key === 'description') {
          for (let k in form[key]) {
            data.append(`${key}[${k}]`, form[key][k]);
          }
          // data.set([key], JSON.stringify(form[key]));
        }
        else {
          data.set([key], form[key]);
        }
        // data.set([key], form[key]);
      }
    }
    if (form.categories.length) {
      form.categories.map((cat) => data.append('categories[]', cat));
    } else {
      data.set('categories', []);
    }
    if (!form.sales_price) {
      data.set('sales_price', 0);
    }
    if (image) {
      data.append('image', image);
    }
    // console.log('FORM VALUES:', ...data)
    data.set('is_veg', form.is_veg ? 1 : 0);
    // console.log('FORM VALUES:', ...data);

    const menu = await dispatch(menuSave(data));
    // console.log('MM:', menu);
    if (menu && menu.item && menu.item.id) {
      if (initialData && initialData.id)
        setRedirect(`/menus/details/${initialData.id}`);
      else setRedirect('/menus/list');
    } else {
      setUploading(false);
    }

    // onSave(data);
  };

  const handleChange = (event) => {
    // console.log('INPUT:', event.target.name, event.target.value);
    // event.persist();
    setForm({ ...form, [event.target.name]: event.target.value });

    if (event.target.name === 'image_type' && event.target.value === 'link')
      setImage(null);
    // console.log('TEXT:', form, form.name);
  };

  const handleChangeWithLang = (n, lang, value) => {
    // event.persist();
    const obj = form[n];
    obj[lang] = value;
    setForm({ ...form, n: obj });
  };

  const handleVegChange = (event) => {
    // console.log('INPUT:', event.target.name, event.target.value);
    // event.persist();
    setForm({ ...form, is_veg: event.target.checked });
    // console.log('TEXT:', form, form.is_veg, event.target.checked);
  };

  // const handleTimeChange = (time, name = "start_time") => {
  //   setForm({ ...form, [name]: time });
  // };

  const handleImageSelect = (files) => {
    // event.persist();
    // console.log('FILES', files[0]);
    setImage(files[0]);
    if (files[0]) {
      setForm({
        ...form,
        remove_image: 0
      });
    }
  };

  const removeImage = () => {
    setForm({
      ...form,
      remove_image: 1
    });
    setImage(null);
    setInitialImage(null);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {languages.length ? (
              languages.map((lang) => (
                <TextValidator
                  className="mb-4 w-full"
                  label={`Item Name (${lang.label})`}
                  variant="outlined"
                  onChange={(e) =>
                    handleChangeWithLang('name', lang.lang, e.target.value)
                  }
                  type="text"
                  name={`name_${lang.lang}`}
                  // validators={['required']}
                  value={form.name[lang.lang] || ''}
                  // errorMessages={['Item name is required']}
                />
              ))
            ) : (
              <TextValidator
                className="mb-4 w-full"
                label="Outlet Name (English)"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('name', 'en', e.target.value)
                }
                type="text"
                name="name_en"
                value={form.name.en || ''}
                validators={['required']}
                errorMessages={['Item name is required']}
              />
            )}
            {languages.length ? (
              languages.map((lang) => (
                <TextValidator
                  className="mb-4 w-full"
                  label={`Item Description (${lang.label})`}
                  variant="outlined"
                  onChange={(e) =>
                    handleChangeWithLang(
                      'description',
                      lang.lang,
                      e.target.value
                    )
                  }
                  type="text"
                  name={`description_${lang.lang}`}
                  value={form.description[lang.lang] || ''}
                />
              ))
            ) : (
              <TextValidator
                className="mb-4 w-full"
                label="Item Description (English)"
                variant="outlined"
                onChange={(e) =>
                  handleChangeWithLang('description', 'en', e.target.value)
                }
                type="text"
                name="description_en"
                value={form.description.en || ''}
              />
            )}
            <TextValidator
              className="mb-4 w-full"
              label="Price"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="price"
              value={form.price || ''}
              validators={['required']}
              errorMessages={['Price is required']}
            />
            <TextValidator
              className="mb-4 w-full"
              label="Sale Price"
              variant="outlined"
              onChange={handleChange}
              type="text"
              name="sales_price"
              value={form.sales_price || ''}
              validators={['isLessThanPrice']}
              errorMessages={['Sale Price should be less than Price']}
            />

            <FormLabel component="legend" className="mb-4 mt-2">
              Available Time
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={`w-full`}
                  style={{ minWidth: 100 }}
                >
                  <InputLabel id="menu-start-time">Start Time</InputLabel>
                  <Select
                    labelId="menu-start-time"
                    id="demo-simple-select-outlined"
                    value={form.start}
                    onChange={handleChange}
                    name="start"
                    label="Start Time"
                    size="small"
                  >
                    {hours.length ? (
                      hours.map((h) => (
                        <MenuItem key={`${h}`} value={`${h}`}>
                          {`${h}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={`w-full`}
                  style={{ minWidth: 100 }}
                >
                  <InputLabel id="menu-end-time">End Time</InputLabel>
                  <Select
                    labelId="menu-end-time"
                    id="demo-simple-select-outlined2"
                    value={form.end}
                    onChange={handleChange}
                    name="end"
                    label="End Time"
                    size="small"
                  >
                    {hours.length ? (
                      hours.map((h) => (
                        <MenuItem key={`${h}`} value={`${h}`}>
                          {`${h}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <FormLabel component="legend" className="mt-4 mb-4 mt-2">
              Not Available Time
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={`w-full`}
                  style={{ minWidth: 100 }}
                >
                  <InputLabel id="menu-start-time-not">Start Time</InputLabel>
                  <Select
                    labelId="menu-start-time-not"
                    value={form.non_available_start}
                    onChange={handleChange}
                    name="non_available_start"
                    label="Start Time"
                    size="small"
                  >
                    {hours.length ? (
                      hours.map((h) => (
                        <MenuItem key={`${h}`} value={`${h}`}>
                          {`${h}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={`w-full`}
                  style={{ minWidth: 100 }}
                >
                  <InputLabel id="menu-end-time-not">End Time</InputLabel>
                  <Select
                    labelId="menu-end-time-not"
                    value={form.non_available_end}
                    onChange={handleChange}
                    name="non_available_end"
                    label="End Time"
                    size="small"
                  >
                    {hours.length ? (
                      hours.map((h) => (
                        <MenuItem key={`${h}`} value={`${h}`}>
                          {`${h}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div className="mt-3 mb-3">
              <FormControl component="fieldset" className="mt-2">
                <FormLabel component="legend">Is Veg</FormLabel>
                <FormGroup>
                  <Switch
                    edge="end"
                    name="is_veg"
                    onChange={handleVegChange}
                    // value={outlet.id}
                    checked={form.is_veg}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-wifi'
                    }}
                  />
                </FormGroup>
              </FormControl>
            </div>

            <FormControl component="fieldset" className="mt-2">
              <FormLabel component="legend">Categories</FormLabel>
              <FormGroup>
                <Grid container>
                  {categoriesList && categoriesList.length ? (
                    categoriesList.map((category) => (
                      <Grid key={`${category.id}`} item sm={12} md={6} lg={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={category.id}
                              checked={form.categories.includes(category.id)}
                              onChange={handleCategorySelect}
                              name="category"
                            />
                          }
                          label={category.name.en}
                        />
                      </Grid>
                    ))
                  ) : (
                    <div className="my-4">
                      <p>
                        No categories added. <br></br>Item won't be visible to
                        customers without adding to category.
                      </p>
                    </div>
                  )}
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormLabel component="legend" className="my-4">
              Image Type
            </FormLabel>
            <div className="block my-4">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Image Type"
                  name="image_type"
                  onChange={handleChange}
                  value={`${form.image_type}`}
                  row
                >
                  <FormControlLabel
                    value={`url`}
                    control={<Radio />}
                    label="Image Link"
                  />
                  <FormControlLabel
                    value={`upload`}
                    control={<Radio />}
                    label="Upload Image"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {form.image_type === 'url' ? (
              <div>
                <FormLabel component="legend">Image URL</FormLabel>
                <TextValidator
                  className="mb-4 w-full"
                  label="Menu Image URL"
                  variant="outlined"
                  onChange={handleChange}
                  type="text"
                  name="media_url"
                  value={form.media_url || ''}
                />
              </div>
            ) : initialImage ? (
              <div>
                <img
                  className="img rounded"
                  src={initialImage.large}
                  alt="user"
                />
                <Button onClick={() => removeImage()}>Remove Image</Button>
              </div>
            ) : (
              <DropzoneArea
                acceptedFiles={['image/*']}
                filesLimit={1}
                showAlerts={['error']}
                dropzoneText={'Drag and drop an image here or click'}
                onChange={(files) => handleImageSelect(files)}
              />
            )}

            <FormLabel component="legend" className="mb-4 mt-4">
              Item Tag
            </FormLabel>
            <div className="mb-4 w-full">
              <Grid container spacing={2}>
                  {languages.length ? (
                    languages.map((lang) => (
                      <Grid item xs={12} sm={6}>
                      <TextValidator
                        className="w-full"
                        label={`Item Tag (${lang.label})`}
                        variant="outlined"
                        onChange={(e) =>
                          handleChangeWithLang(
                            'item_tag',
                            lang.lang,
                            e.target.value
                          )
                        }
                        type="text"
                        name={`item_tag_${lang.lang}`}
                        value={form.item_tag[lang.lang] || ''}
                      />
                      </Grid>
                    ))
                  ) : (
                      <Grid item xs={12} sm={6}>
                    <TextValidator
                      className="w-full"
                      label="Item Tag (English)"
                      variant="outlined"
                      onChange={(e) =>
                        handleChangeWithLang('item_tag', 'en', e.target.value)
                      }
                      type="text"
                      name="item_tag_en"
                      value={form.item_tag.en || ''}
                      />
                      </Grid>
                  )}
              </Grid>

              <FormHelperText>
                You can add special tag to menu item. Eg: Chef Special, Spicy
              </FormHelperText>
            </div>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="my-3"
          disabled={uploading}
        >
          <Icon>send</Icon>
          <span className="pl-2 capitalize">Submit</span>
        </Button>
      </ValidatorForm>
    </div>
  );
};
export default MenuForm;
