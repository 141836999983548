import React from 'react';
import { Breadcrumb } from 'matx';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  outletSave,
  getOutletDetails,
  getOutletDeliveryHours,
  getOutletPickupHours
} from 'app/redux/actions/OutletActions';
import OutletForm from './shared/OutletForm';

const OutletEdit = () => {
  const [loading, setLoading] = React.useState(true);
  const { outletDetails } = useSelector((state) => state.outlet);
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    if (outletDetails && outletDetails.id) {
      setLoading(false);
    }
  }, [outletDetails]);

  React.useEffect(() => {
    dispatch(getOutletDetails(id));
    dispatch(getOutletDeliveryHours(id));
    dispatch(getOutletPickupHours(id));
  }, [dispatch, id]);

  const submitOutlet = async (data) => {
    const outlet = await dispatch(outletSave(data));
    // console.log('MM:', outlet);
    // if (outlet.outlet && outlet.outlet.id) {
    //   setRedirect(`/outlets/edit/${outlet.id}`);
    // }
  };

  if (loading) return null;

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Outlets', path: '/outlets/list' },
            { name: 'Edit' }
          ]}
        />
      </div>
      <Card className="px-6 py-6">
        <OutletForm
          // onSave={(data) => submitOutlet(data)}
          initialData={outletDetails}
        />
      </Card>
    </div>
  );
};
export default OutletEdit;
