import React from "react";
import { Breadcrumb } from "matx";

import { getOrderDetails } from "../../redux/actions/OrderActions";
import { useDispatch, useSelector } from "react-redux";
import PrintOrder from "./shared/PrintOrder";
import { useParams } from "react-router-dom";

const OrderPrint = () => {
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.order);
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(getOrderDetails(id));
  }, [dispatch]);

  return (
    <div className="">
      <PrintOrder order={orderDetails} />
    </div>
  );
  // }
};
export default OrderPrint;
