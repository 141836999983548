import { getApi } from 'app/services/api';
import axios from 'axios';
import { showErrorMessage } from 'utils';

export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST';

export const getCustomersList = () => (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return api
    .get(`${process.env.REACT_APP_API_URL}/api/customers?rows=1000`)
    .then((res) => {
      // console.log('USER ACTION:', res.data);
      dispatch({
        type: GET_CUSTOMER_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};
