import React from 'react';
import {
  Card,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { deleteAttribute } from 'app/redux/actions/MenuActions';
import { statusChange } from 'app/redux/actions/CommonActions';
import SearchBar from 'app/components/SearchBar';

const ModifiersTable = ({ onEdit }) => {
  const dispatch = useDispatch();
  const { attributesList } = useSelector((state) => state.menu);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [role, setRole] = React.useState('user');
  const [filteredModifiers, setFilteredModifiers] = React.useState([]);

  React.useEffect(() => {
    setFilteredModifiers(attributesList);
  }, [attributesList]);

  React.useEffect(() => {
    if (
      localStorage.getItem('auth_user') &&
      JSON.parse(localStorage.getItem('auth_user')).role
    ) {
      setRole(JSON.parse(localStorage.getItem('auth_user')).role);
    }
  }, []);

  const searchModifiers = (text) => {
    // console.log('TXT', text)
    setFilteredModifiers(attributesList.filter((el) => el.name.en && el.name.en.toLowerCase().includes(text)));
  };

  const changeStatus = (e) => {
    const data = {
      module: 'attribute_item',
      ref_id: e.target.value,
      value: e.target.checked ? 1 : 0
    };
    dispatch(statusChange(data));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteModifier = () => {
    dispatch(deleteAttribute(id));
  };

  const handleClick = (id) => (event) => {
    // console.log('OPENN');
    // setAnchorEl(event.currentTarget);
    setOpen(true);
    setId(id);
    // deleteModifier(modifier.id);
  };

  return (
    <Card className="px-6 pt-2 pb-4">
      {/* <Popper open={open} anchorEl={anchorEl} placement={`left`} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="p-6">
              <Typography>Are you sure you want to delete</Typography>
            </Paper>
          </Fade>
        )}
      </Popper> */}
      {/* Close Order Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Modifier</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this modifier?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteModifier();
              handleClose();
            }}
            color="primary"
          >
            Yes, Delete.
          </Button>
        </DialogActions>
      </Dialog>
      {/* Preparation Time Dialog End */}
      <div className="flex items-center justify-between py-3">

      <div className="card-title mb-3">Menu Modifiers</div>
      <SearchBar onSearch={searchModifiers} />
    </div>
      <div className="overflow-auto">
        {filteredModifiers.length ? (
          <Table className="menu__table">
            <TableHead>
              <TableRow>
                <TableCell className="px-1">
                  Name
                </TableCell>
                <TableCell className="px-1">
                  Price
                </TableCell>
                <TableCell className="px-0" align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredModifiers.map((modifier, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="px-0 capitalize"
                   
                    align="left"
                  >
                    <div className="flex items-center">
                      <p className="m-0 ml-8">{modifier.name.en}</p>
                    </div>
                  </TableCell>
                  <TableCell
                    className="px-0 capitalize"
                   
                    align="left"
                  >
                    <div className="flex items-center">
                      <p className="m-0 ml-8">{modifier.price}</p>
                    </div>
                  </TableCell>
                  <TableCell className="px-0" align="right">
                    {role === 'admin' ? (
                      <>
                        <IconButton onClick={() => onEdit(modifier)}>
                          <Icon color="primary">edit</Icon>
                        </IconButton>
                        <IconButton onClick={handleClick(modifier.id)}>
                          <Icon color="error">delete</Icon>
                        </IconButton>
                        <Switch
                          value={modifier.id}
                          defaultChecked={modifier.publish}
                          onChange={changeStatus}
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'Change Status' }}
                        />
                      </>
                    ) : (
                      <Switch
                        value={modifier.id}
                        defaultChecked={
                          modifier.publish && modifier.outlet_publish
                        }
                        disabled={!modifier.publish}
                        onChange={changeStatus}
                        color="primary"
                        size="small"
                        inputProps={{ 'aria-label': 'Change Status' }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>No modifiers found...</div>
        )}
      </div>
    </Card>
  );
};

export default ModifiersTable;
