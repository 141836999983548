import React from "react";
import Outlets from "./Outlets";
import OutletAdd from "./OutletAdd";
import OutletEdit from "./OutletEdit";

const outletRoutes = [
  {
    path: "/outlets/list",
    // component: React.lazy(() => import("./Outlets")),
    component: Outlets,
  },
  {
    path: "/outlets/add",
    // component: React.lazy(() => import("./OutletAdd")),
    component: OutletAdd,
  },
  {
    path: "/outlets/edit/:id",
    // component: React.lazy(() => import("./OutletEdit")),
    component: OutletEdit,
  },
];

export default outletRoutes;
