/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb } from 'matx';
import { useDispatch } from 'react-redux';

import UsersTable from './shared/CustomersTable';
import { getCustomersList } from 'app/redux/actions/CustomerActions';

const Customers = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCustomersList());
  }, []);

  return (
    <div className="m-sm-30 menu">
      <div className="mb-sm-30 flex items-center justify-between">
        <Breadcrumb
          routeSegments={[
            { name: 'Customers', path: '/customers/list' },
            { name: 'List' }
          ]}
        />
        <div className=""></div>
      </div>
      <UsersTable />
    </div>
  );
};

export default Customers;
