import axios from 'axios';
import { enqueueSnackbar } from './UIActions';
import { showErrorMessage } from 'utils';
import { getApi } from 'app/services/api';

export const GET_ZONE_LIST = 'GET_ZONE_LIST';
export const GET_ZONE_DETAILS = 'GET_ZONE_DETAILS';
export const ADD_ZONE = 'ADD_ZONE';
export const EDIT_ZONE = 'EDIT_ZONE';
export const GET_ZONE_DELIVERY_HOURS = 'GET_ZONE_DELIVERY_HOURS';
export const GET_ZONE_PICKUP_HOURS = 'GET_ZONE_PICKUP_HOURS';
export const GET_LOCATIONS_LIST = 'GET_LOCATIONS_LIST';

// export const UPDATE_CART_AMOUNT = "UPDATE_CART_AMOUNT";

export const getZonesList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/delivery-zones`)
    .then((res) => {
      // console.log('ZONE ACTION:', res.data);
      dispatch({
        type: GET_ZONE_LIST,
        payload: res.data.data
      });
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);
      showErrorMessage(dispatch, err);
    });
};

export const getZoneDetails = (id) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/delivery-zones/detail/${id}`)
    .then((res) => {
      // console.log('ZONE DETAILS:', res.data);
      dispatch({
        type: GET_ZONE_DETAILS,
        payload: res.data.zone
      });
      return res.data.zone;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const getLocationsList = () => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .get(`${process.env.REACT_APP_API_URL}/api/locations?connectedOnly=true&no_zones=true`)
    .then((res) => {
      // console.log('ZONE DETAILS:', res.data);
      dispatch({
        type: GET_LOCATIONS_LIST,
        payload: res.data.data
      });
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
    });
};

export const zoneSave = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/delivery-zones/save`, data)
    .then((res) => {
      const message = data.id
        ? 'Zone details updated'
        : 'New delivery zone added';

      // console.log('DATA', res);
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      if (data.id) {
        dispatch({
          type: EDIT_ZONE,
          payload: res.data.zone
        });
      } else {
        dispatch({
          type: ADD_ZONE,
          payload: res.data.zone
        });
      }
      return res.data;
    })
    .catch((err) => {
      // console.log('ERROR:', err.response);

      showErrorMessage(dispatch, err);
      // dispatch(
      //   enqueueSnackbar({
      //     message: 'Something went wrong',
      //     options: {
      //       variant: 'error'
      //     }
      //   })
      // );
    });
};

export const linkZoneAreas = (data) => async (dispatch, getState) => {
  // axios.defaults.headers.common['Authorization'] = localStorage.getItem(
  //   'jwt_token'
  // );
  const api = getApi(getState);
  return await api
    .post(`${process.env.REACT_APP_API_URL}/api/delivery-zones/area-link`, data)
    .then((res) => {
      // console.log('ZONE UPDATED:', res.data);

      const message = `Zone delivery areas updated`;

      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            variant: 'success'
          }
        })
      );

      // dispatch({
      //   type: EDIT_ZONE,
      //   payload: res.data.zone,
      // });
    })
    .catch((err) => {
      // console.log('ERROR:', err, err.response);

      // dispatch(
      //   enqueueSnackbar({
      //     message: 'Something went wrong',
      //     options: {
      //       variant: 'error'
      //     }
      //   })
      // );
    });
};
