import { combineReducers } from 'redux';
import UIReducer from './UIReducer';
import LoginReducer from './LoginReducer';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import LayoutReducer from './LayoutReducer';
import NotificationReducer from './NotificationReducer';
import OrderReducer from './OrderReducer';
import MenuReducer from './MenuReducer';
import NavigationReducer from './NavigationReducer';
import OutletReducer from './OutletReducer';
import CustomerReducer from './CustomerReducer';
import ZoneReducer from './ZoneReducer';
import DiscountReducer from './DiscountReducer';
import CommonReducer from './CommonReducer';
import LoyaltyReducer from './LoyaltyReducer';

const RootReducer = combineReducers({
  ui: UIReducer,
  login: LoginReducer,
  auth: AuthReducer,
  user: UserReducer,
  layout: LayoutReducer,
  notification: NotificationReducer,
  order: OrderReducer,
  menu: MenuReducer,
  navigations: NavigationReducer,
  outlet: OutletReducer,
  common: CommonReducer,
  customer: CustomerReducer,
  zone: ZoneReducer,
  discount: DiscountReducer,
  loyalty: LoyaltyReducer,
});

export default RootReducer;
