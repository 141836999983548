import React from 'react';
import { ListItem } from '@material-ui/core';
import { getOrderDetails, clearOrderDetails } from 'app/redux/actions/OrderActions';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

// const colors = {
//   placed: 'bg-error',
//   accepted: 'bg-secondary',
//   ready: 'bg-green',
//   delivered: 'bg-green',
//   'in-route': 'bg-yellow',
//   completed: 'bg-green'
// };

const LiveOrderListItem = ({ toggleDetails, order }) => {
  const { orderDetails } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const getDetails = async (id) => {
    dispatch(clearOrderDetails());
    toggleDetails();
    dispatch(getOrderDetails(id));
  };

  return (
    <ListItem
      key={`${order.id}`}
      button
      className={`list-item ${order.status} ${
        orderDetails.id === order.id ? 'active' : ''
      }`}
      onClick={() => getDetails(order.id)}
    >
      <div>
        <p className="mb-1">
          <strong>#{order.order_no}</strong>
        </p>
        <small className="block list-item__meta">
          {order.channel}, <span>{moment(order.order_at).fromNow()}</span>
        </small>
      </div>
      <div className={`item__status bg-${order.status}`}>
        {order.status === 'placed' ? (
          <div className="not-accepted">Not Accepted</div>
        ) : (
          <div>
            <div className="label">
              {order.status === 'accepted' ? 'Prep In' : 'Deliver In'}
            </div>
            <div
              className={`countdown ${
                moment
                  .duration(moment(order.preparation_time).diff(moment()))
                  .as('minutes') <= 5
                  ? 'blink'
                  : ''
              }`}
            >
              <span className="number">
                {order.preparation_time
                  ? parseInt(
                      moment
                        .duration(moment(order.preparation_time).diff(moment()))
                        .as('minutes')
                    )
                  : 0}
              </span>
              <span className="min">min</span>
            </div>
          </div>
        )}
      </div>
    </ListItem>
  );
};

export default LiveOrderListItem;
